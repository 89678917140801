import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconTeam = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 8.5C7.15687 8.5 8.5 7.15685 8.5 5.5C8.5 3.84315 7.15687 2.5 5.5 2.5C3.84315 2.5 2.5 3.84315 2.5 5.5C2.5 7.15685 3.84315 8.5 5.5 8.5Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.75 8.5C12.8546 8.5 13.75 7.60457 13.75 6.5C13.75 5.39543 12.8546 4.5 11.75 4.5C10.6454 4.5 9.75 5.39543 9.75 6.5C9.75 7.60457 10.6454 8.5 11.75 8.5Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 12.5C1.5 12.5 3 10.5 5.5 10.5C8 10.5 9.5 12.5 9.5 12.5'
        stroke={color}
        strokeLinecap='round'
      />
      <path d='M11.5 10.5C13.5 10.5 14.5 12 14.5 12' stroke={color} strokeLinecap='round' />
      <path
        d='M11.5 10.5C10.9142 10.5 10.4142 10.6287 10 10.8107'
        stroke={color}
        strokeLinecap='round'
      />
    </svg>
  )
}
