import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IOrganicResultsFilter } from '../interfaces/IOrganicResultsFilter'
import { RootState } from '../../../../store/Store'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

type OrganicResultsState = {
  filters: IOrganicResultsFilter
  pagination: IPagination
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
}

const dateRanges = useDateRanges()

const organicResultsSlice = createSlice({
  name: 'organic',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      show_affiliates: false,
      show_hidden: false,
      has_brand: false,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
  } as OrganicResultsState,
  reducers: {
    setOrganicResultsFilter: (
      state: OrganicResultsState,
      action: PayloadAction<IOrganicResultsFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{ ...action.payload, has_brand: state.filters.has_brand },
      }
    },
    setOrganicResultsPagination: (
      state: OrganicResultsState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pagination = action.payload
    },
    setHasBrand: (state: OrganicResultsState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, has_brand: action.payload }
    },
    setProject: (
      state: OrganicResultsState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
  },
})

export const { setOrganicResultsFilter, setOrganicResultsPagination, setHasBrand, setProject } =
  organicResultsSlice.actions

export const selectOrganicResultsFilters = (state: RootState) => state.root.organicResults.filters
export const selectOrganicResultsPagination = (state: RootState) =>
  state.root.organicResults.pagination
export const selectProject = (state: RootState) => state.root.organicResults.project

export default organicResultsSlice.reducer
