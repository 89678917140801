import { CommonMainPage } from '../../utility/CommonMainPage'
import { PpcComplianceFilters } from './PpcComplianceFilters'
import { PpcComplianceTable } from './PpcComplianceTable'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { selectProject } from '../store/PpcComplianceSlice'
import { ProjectStub } from '../../../../shared/components/utility/project-stub/ProjectStub'

export const PpcCompliancePage = () => {
  const { has_paid_links: hasPaidLinks } = useAppSelector(selectProject)

  return (
    <CommonMainPage>
      <section className='h-full grid grid-rows-[min-content_1fr]'>
        <PpcComplianceFilters />
        {hasPaidLinks ? (
          <PpcComplianceTable />
        ) : (
          <div className={'pt-[50px]'}>
            <ProjectStub />
          </div>
        )}
      </section>
    </CommonMainPage>
  )
}
