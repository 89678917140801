import { CardDrop } from '../../../../shared/components/cards/card-drop/CardDrop'
import React, { PropsWithChildren } from 'react'
import { IconProfile } from '../../../../shared/components/icons/IconProfile'

export const ProfileDrop = ({ children }: PropsWithChildren) => {
  return (
    <CardDrop
      className={'!top-[50px]'}
      trigger={
        <div className={'relative border border-aqua-300 rounded-[18px] p-[2px] flex items-center'}>
          <div className='rounded-full w-[32px] h-[32px] flex items-center justify-center hover:bg-[#6684A74D]'>
            <IconProfile className='w-[18px]' color={'#FFFFFF'} />
          </div>
        </div>
      }
    >
      <div className={'p-[16px] min-w-[150px] flex flex-col gap-[8px]'}>{children}</div>
    </CardDrop>
  )
}
