import { IconQuestion } from '../../../../shared/components/icons/IconQuestion'
import { articles } from '../../../../shared/consts/articles.const'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { selectShowGuide, setModal, setShowGuide } from '../../../../store/reducers/AppSlice'
import { FeedbackModal } from '../feedback-modal/FeedbackModal'
import { CardDrop } from '../../../../shared/components/cards/card-drop/CardDrop'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { BpPopconfirm } from '../../../../shared/components/cards/popconfirm/BpPopconfirm'
import { GuideModal } from '../guide-modal/GuideModal'
import { removeNotification, selectUser } from '../../../../store/reducers/AuthSlice'
import {
  useArticleSettingsQuery,
  useLazyHideNotificationQuery,
} from '../../../../api/site/Site.api'
import { useTranslation } from 'react-i18next'

export const GuideButton = () => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const showGuide = useAppSelector(selectShowGuide)
  const [isCardDropOpen, setIsCardDropOpen] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const dispatch = useAppDispatch()

  const { data } = useArticleSettingsQuery()
  const [hideNotification] = useLazyHideNotificationQuery()

  useEffect(() => {
    const notification = user.notifications?.find(
      (notification) => notification.type === 'onboarding_tutorial',
    )

    if (notification && !notification.is_hidden) {
      setTimeout(() => setShowConfirm(true), 1000)
    }
  }, [user])

  useEffect(() => {
    setIsCardDropOpen(false)

    if (showGuide) {
      setTimeout(() => setIsCardDropOpen(true))
    }
  }, [showGuide])

  const hideNotify = () => {
    const notification = user.notifications?.find(
      (notification) => notification.type === 'onboarding_tutorial',
    )

    if (notification) {
      hideNotification({ id: notification.id })
      dispatch(removeNotification(notification.type))
    }

    setShowConfirm(false)
  }

  return (
    <BpPopconfirm
      arrowPointAtCenter
      overlayClassName={'w-[300px]'}
      open={showConfirm}
      title={
        <p>
          {t('components.guideButton.preparedQuickGuide')}
          <ul className={'list-disc pl-[16px]'}>
            <li>{t('components.guideButton.competitorAnalysis')}</li>
            <li>{t('components.guideButton.nonCompliantAffiliates')}</li>
            <li>{t('components.guideButton.projectManagement')}</li>
            <li>{t('components.guideButton.workWithUsersTeam')}</li>
          </ul>
          {t('components.guideButton.wouldLearnMore')}
        </p>
      }
      okText={t('buttons.seeQuickGuide')}
      cancelText={t('buttons.later')}
      placement={'bottomRight'}
      onConfirm={() => {
        hideNotify()
        setIsCardDropOpen(true)
      }}
      onCancel={hideNotify}
    >
      <CardDrop
        opened={isCardDropOpen}
        onToggle={hideNotify}
        className={'!top-[50px]'}
        trigger={
          <div
            className={'relative border border-aqua-300 rounded-[18px] p-[2px] flex items-center'}
          >
            <div className='rounded-full w-[32px] h-[32px] flex items-center justify-center hover:bg-[#6684A74D]'>
              <IconQuestion className='w-[16px]' color={'#FFFFFF'} />
            </div>
            <div
              className={`absolute
                pointer-events-none
                w-[150%]
                h-[150%]
                rounded-full
                border-[2px]
                border-dashed
                border-default
                -left-[25%]
                -top-[25%]
                transition-opacity
                duration-200
                ${showConfirm ? 'opacity-1' : 'opacity-0'}
              `}
            ></div>
          </div>
        }
      >
        <div className={'min-w-[230px] py-[16px]'}>
          <div className={'px-[16px] pb-[6px] flex flex-col'}>
            {process.env.REACT_APP_PROJECT === 'bluepear' && (
              <button
                className={
                  'text-[15px] text-left !text-focus-500 hover:!text-default hover:bg-default-200 p-[8px] rounded-[8px]'
                }
                onClick={() =>
                  dispatch(
                    setModal({
                      id: 'guide-modal',
                      size: 'xl',
                      scroll: true,
                      children: <GuideModal />,
                    }),
                  )
                }
              >
                {t('components.guideButton.quickGuide')}
              </button>
            )}
            {data?.data?.items.articles?.map(
              ({ name, url }: { name: string; url: string }, index: number) => (
                <BpAnchor
                  key={index}
                  target='_blank'
                  href={url}
                  className={
                    'text-[15px] !text-focus-500 hover:!text-default hover:bg-default-200 p-[8px] rounded-[8px]'
                  }
                >
                  {name}
                </BpAnchor>
              ),
            )}
          </div>
          <div className={'bg-[#0E63D8]/[0.05] px-[24px] py-[16px] flex flex-col gap-[12px]'}>
            <p className={'text-center text-[17px] font-medium'}>
              {t('components.guideButton.askSpecialist')}
            </p>
            {data?.data?.items.buttons?.map(
              ({ name, url }: { name: string; url: string }, index: number) => (
                <BpButton key={index} size={'sm'} onClick={() => window.open(url, '_blank')}>
                  {name}
                </BpButton>
              ),
            )}
            <div className={'flex gap-[6px] justify-center'}>
              {data?.data?.items.contacts?.map(
                ({ name, url }: { name: string; url: string }, index: number) => (
                  <BpAnchor key={index} className={'text-[15px]'} target='_blank' href={url}>
                    {name}
                  </BpAnchor>
                ),
              )}
            </div>
          </div>
          {data?.data?.items.feedback && (
            <div className={'pt-[16px] flex px-[16px]'}>
              <button
                className={
                  'text-[15px] text-[#0E63D8] w-fit m-auto hover:text-[#40a9ff] hover:opacity-80 transition-opacity duration-150'
                }
                onClick={() => dispatch(setModal({ children: <FeedbackModal /> }))}
              >
                {t('components.guideButton.giveFeedback')}
              </button>
            </div>
          )}
        </div>
      </CardDrop>
    </BpPopconfirm>
  )
}
