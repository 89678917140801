import { IOption } from '../components/forms/search-input/BpSearchInput'

export const PolicyOptions: IOption[] = [
  {
    text: 'Trademark abuse',
    value: 'trademark_abuse',
  },
  {
    text: 'Lookalike domains',
    value: 'trademark_abuse_url',
  },
]
