import { CommonMainPage } from '../../utility/CommonMainPage'
import { LiveUncloakingFilters } from './LiveUncloakingFilters'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectLiveUncloakingApplyFilters,
  selectLiveUncloakingExclude,
  selectLiveUncloakingFilters,
  selectLiveUncloakingTab,
  setLiveUncloakingApplyFilters,
  setLiveUncloakingExcludeIds,
  setLiveUncloakingTab,
  TLiveUncloakingTabs,
} from '../store/LiveUncloakingSlice'
import { useLazyLiveQuery } from '../../../../api/decloak/Decloak.api'
import { useEffect } from 'react'
import { ScreenTab } from './tabs/ScreenTab'
import { LinksTab } from './tabs/LinksTab'
import { HtmlTab } from './tabs/HtmlTab'
import { useTranslation } from 'react-i18next'

export const LiveUncloakingPage = () => {
  const { t } = useTranslation()
  const apply = useAppSelector(selectLiveUncloakingApplyFilters)
  const filters = useAppSelector(selectLiveUncloakingFilters)
  const exclude = useAppSelector(selectLiveUncloakingExclude)
  const activeTab = useAppSelector(selectLiveUncloakingTab)
  const dispatch = useAppDispatch()

  const [liveUncloaking, { data, isLoading, isFetching }] = useLazyLiveQuery()

  useEffect(() => {
    if (filters.device && filters.country && filters.site && apply)
      liveUncloaking({
        deviceId: +filters.device,
        countryId: +filters.country,
        site: filters.site,
        exlude_ids: exclude.excludeProxy ? exclude.excludeIds : undefined,
      }).then(({ data }) => {
        dispatch(setLiveUncloakingApplyFilters(false))
        dispatch(setLiveUncloakingExcludeIds(data?.data?.ids))
      })
  }, [filters, exclude.excludeProxy, apply])

  return (
    <CommonMainPage>
      <LiveUncloakingFilters />
      <BpTabs
        className='mt-[19px] pl-[12px]'
        active={activeTab}
        onToggle={(e) => dispatch(setLiveUncloakingTab(e as TLiveUncloakingTabs))}
        size={'md'}
      >
        <BpTab title={t('pages.liveUncloaking.screenshots')}>
          <ScreenTab isLoading={isLoading || isFetching} screen={data?.data?.webScreen} />
        </BpTab>
        <BpTab title={t('pages.liveUncloaking.HTML')}>
          <HtmlTab isLoading={isLoading || isFetching} html={data?.data?.webHtml} />
        </BpTab>
        <BpTab title={t('pages.liveUncloaking.links')}>
          <LinksTab isLoading={isLoading || isFetching} links={data?.data?.redirects} />
        </BpTab>
      </BpTabs>
    </CommonMainPage>
  )
}
