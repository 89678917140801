import { ISignUpFormData, SignUpForm } from './form/SignUpForm'
import { CommonAuthPage } from '../utility/CommonAuthPage'
import { SubmitHandler } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { CompanySettingsForm, ICompanySettingsFormData } from './form/CompanySettingsForm'
import { useLazySignupQuery } from '../../../api/site/Site.api'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectOauthUser, selectUser } from '../../../store/reducers/AuthSlice'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

export const SignUpPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const oauthUser = useAppSelector(selectOauthUser)
  const [scenario, setScenario] = useState<'first' | 'second'>('first')
  const [signUpFormValues, setSignUpFormValues] = useState<ISignUpFormData>()
  const [cookies] = useCookies()
  const [signup] = useLazySignupQuery()

  const handleSubmitSignup: SubmitHandler<ISignUpFormData> = (formData: ISignUpFormData) => {
    const filteredCookies = Object.fromEntries(
      Object.entries(cookies).filter(([key]) =>
        ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].includes(key),
      ),
    )

    if (user) {
      setSignUpFormValues(formData)
      setScenario('second')
    } else {
      signup({
        scenario: formData.scenario ? formData.scenario : scenario,
        ...formData,
        ...filteredCookies,
      }).then((response: any) => {
        if (response.data?.success) {
          setSignUpFormValues(formData)
          setScenario('second')
        }
      })
    }
  }

  const handleSubmitCompanySettings: SubmitHandler<ICompanySettingsFormData> = (
    formData: ICompanySettingsFormData,
  ) => {
    const filteredCookies = Object.fromEntries(
      Object.entries(cookies).filter(([key]) =>
        ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].includes(key),
      ),
    )

    signup({ ...signUpFormValues, ...formData, ...filteredCookies, scenario: 'second' }).then(
      (response: any) => {
        if (response.data?.success) {
          navigate(response.data?.data?.allow_onboarding ? '/onboarding' : '/projects')
        }
      },
    )
  }

  useEffect(() => {
    if (oauthUser) {
      if (oauthUser.id && oauthUser.status === 'active') {
        navigate(oauthUser.allow_onboarding ? '/onboarding' : '/projects')
      } else if (oauthUser.id && oauthUser.status === 'uncompleted_registration') {
        setScenario('second')
      } else if (oauthUser.fullName && oauthUser.email) {
        handleSubmitSignup({
          fullName: oauthUser.fullName,
          email: oauthUser.email,
          scenario: 'first_oauth',
        })
      }
    }
  }, [oauthUser])

  return (
    <>
      <CommonAuthPage
        form={
          {
            first: <SignUpForm submitHandler={handleSubmitSignup} />,
            second: <CompanySettingsForm submitHandler={handleSubmitCompanySettings} />,
          }[scenario]
        }
      >
        {
          {
            first: (
              <div className={'flex flex-col max-w-[430px] ml-auto'}>
                <div>
                  <h1 className={'text-[24px] leading-[32px] pb-[16px]'}>
                    {t('pages.auth.affiliateCompliance')}
                  </h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    {t('pages.auth.protectYourPPC')}
                  </p>
                </div>
                <div>
                  <h1 className={'text-[24px] leading-[32px] pb-[16px]'}>
                    {t('pages.auth.competitorAnalysis')}
                  </h1>
                  <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                    {t('pages.auth.comprehensiveInsights')}
                  </p>
                </div>
              </div>
            ),
            second: {
              bluepear: (
                <div className={'flex flex-col max-w-[430px] ml-auto'}>
                  <div>
                    <h1 className={'text-[24px] leading-[32px] pb-[16px]'}>150+</h1>
                    <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                      Global brands trust us with their protection
                    </p>
                  </div>
                  <div>
                    <h1 className={'text-[24px] leading-[32px] pb-[16px]'}>12K+</h1>
                    <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                      Affiliates detected on a monthly basis
                    </p>
                  </div>
                  <div>
                    <h1 className={'text-[24px] leading-[32px] pb-[16px]'}>$1mil+</h1>
                    <p className='text-[24px] text-white leading-[36px] mb-[46px]'>
                      Dollars saved monthly
                    </p>
                  </div>
                </div>
              ),
              robuscan: null,
              default: null,
            }[process.env.REACT_APP_PROJECT ?? 'default'],
          }[scenario]
        }
      </CommonAuthPage>
    </>
  )
}
