import { useEffect, useRef, useState } from 'react'

export interface IProgressBarProps {
  percent?: number
  className?: string
}

export const ProgressBar = ({ percent = 0, className }: IProgressBarProps) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [formattedPercent, setFormattedPercent] = useState<number>()

  useEffect(() => {
    const roundedPercent = Math.round(percent)
    const value = roundedPercent > 100 ? 100 : roundedPercent
    setFormattedPercent(value)
    if (ref?.current) ref.current.style.width = `${roundedPercent}%`
  }, [percent])

  return (
    <div className={`flex items-center gap-[9px] overflow-hidden ${className}`}>
      <span className='text-focus-500 text-[15px] min-w-[30px] whitespace-nowrap'>
        {formattedPercent}%
      </span>
      <span className='rounded-[12px] bg-focus-600/[0.07] h-[6px] w-full flex'>
        <span ref={ref} className='rounded-[12px] bg-default h-full'></span>
      </span>
    </div>
  )
}
