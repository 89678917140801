import { ResearchToolsFilters } from './filters/ResearchToolsFilters'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { Competitors } from './competitors/Competitors'
import { Keywords } from './keywords/Keywords'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectProject,
  selectResearchActiveTab,
  setResearchActiveTab,
  TResearchTabs,
} from '../store/ResearchSlice'
import { useTranslation } from 'react-i18next'
import { ProjectStub } from '../../../../shared/components/utility/project-stub/ProjectStub'

export const ResearchToolsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectResearchActiveTab)
  const { has_paid_links: hasPaidLinks } = useAppSelector(selectProject)

  return (
    <>
      <ResearchToolsFilters />
      {hasPaidLinks ? (
        <>
          <BpTabs
            className='mt-[19px] mb-[16px]'
            active={activeTab}
            onToggle={(e) => dispatch(setResearchActiveTab(e as TResearchTabs))}
            size={'md'}
          >
            <BpTab title={t('pages.advertisingResearch.advertisers')}>
              <Competitors />
            </BpTab>
            <BpTab title={t('pages.advertisingResearch.keywords')}>
              <Keywords />
            </BpTab>
          </BpTabs>
        </>
      ) : (
        <div className={'pt-[50px]'}>
          <ProjectStub />
        </div>
      )}
    </>
  )
}
