import React, { Children, OptionHTMLAttributes, useEffect, useRef, useState } from 'react'
import stringReplaceJsx from 'string-replace-jsx'

export interface IResultItemProps extends OptionHTMLAttributes<HTMLOptionElement> {
  children?: string | string[]
  found?: string
}

export const ResultItem = (props: IResultItemProps) => {
  const { children, selected = false, found = '', className, ...optionProps } = props
  const [foundText, setFoundText] = useState<JSX.Element>()
  const ref = useRef<HTMLOptionElement>(null)

  useEffect(() => {
    if (!found) {
      setFoundText(undefined)
    } else if (children) {
      const regexFound = found
        .split('')
        .map((letter) => (letter.match(/[\w\s]/) ? letter : `\\${letter}`))
        .join('')
      Children.map(children, (child: string, index) => {
        const result = stringReplaceJsx(child, new RegExp(regexFound, 'gmi'), (match) => (
          <span key={index} className='text-default'>
            {match}
          </span>
        ))
        setFoundText(<>{result}</>)
      })
    }
  }, [found, children])

  return (
    <>
      <button
        type='button'
        className={`flex w-full cursor-pointer rounded-[8px] p-[8px] group hover:bg-default-200 items-center text-[15px] leading-[24px] ${
          selected ? 'text-aqua-300' : `text-focus-500 ${!found && 'hover:text-default'}`
        } ${className}`}
        onClick={() => {
          if (ref?.current) ref.current.click()
        }}
      >
        <span className={'text-start truncate'}>{foundText ? foundText : children}</span>
        {selected && (
          <span className='hidden flex-1 group-hover:inline-block ml-[5px] truncate text-end'>
            Already selected
          </span>
        )}
      </button>
      <option ref={ref} {...optionProps} hidden></option>
    </>
  )
}
