import { CommonMainPage } from '../utility/CommonMainPage'
import { SerpPresenceFilters } from './component/filters/SerpPresenceFilters'
import { SerpPresenceTable } from './component/report/SerpPresenceTable'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectProject } from './store/SerpPresenceSlice'
import { ProjectStub } from '../../../shared/components/utility/project-stub/ProjectStub'

export const SerpPresence = () => {
  const { has_paid_links: hasPaidLinks } = useAppSelector(selectProject)

  return (
    <CommonMainPage>
      <section className='h-full grid gap-[50px] grid-rows-[min-content_1fr]'>
        <SerpPresenceFilters />
        {hasPaidLinks ? <SerpPresenceTable /> : <ProjectStub />}
      </section>
    </CommonMainPage>
  )
}
