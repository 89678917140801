import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { IconCheck } from '../../../../shared/components/icons/IconCheck'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface LanguageComplianceStubProps {
  isLoading?: boolean
}

export const LanguageComplianceStub = ({ isLoading }: LanguageComplianceStubProps) => {
  const { t } = useTranslation()
  if (isLoading) {
    return <Preloader />
  }

  return (
    <div className={'flex flex-wrap py-[55px] justify-center gap-[65px] px-[24px]'}>
      <div className={'-mt-[11px]'}>
        <svg
          className={'w-[144px]'}
          viewBox='0 0 144 155'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M62.978 77.0059H47.9766M91.4795 53.0059C85.6211 53.0059 82.3365 53.0059 76.478 53.0059M119.977 65.0059V51.8059C119.977 41.7249 119.977 36.6844 118.015 32.834C116.289 29.4471 113.536 26.6935 110.149 24.9677C106.298 23.0059 101.258 23.0059 91.1766 23.0059H52.7766C42.6956 23.0059 37.6551 23.0059 33.8047 24.9677C30.4178 26.6935 27.6642 29.4471 25.9384 32.834C23.9766 36.6844 23.9766 41.7249 23.9766 51.8059V114.206C23.9766 124.287 23.9766 129.327 25.9384 133.178C27.6642 136.565 30.4178 139.318 33.8047 141.044C37.6551 143.006 42.6956 143.006 52.7766 143.006H59.9766'
            stroke='#00326D'
            strokeWidth='9'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M108 108.2V119M108 129.8H108.027M81 109.611V128.389C81 129.049 81 129.379 81.0746 129.69C81.1408 129.966 81.2498 130.229 81.3979 130.47C81.5648 130.743 81.7983 130.976 82.2653 131.443L95.5567 144.735C96.0237 145.202 96.2572 145.435 96.5296 145.602C96.7712 145.75 97.0346 145.859 97.3101 145.925C97.6208 146 97.951 146 98.6114 146H117.389C118.049 146 118.379 146 118.69 145.925C118.966 145.859 119.229 145.75 119.47 145.602C119.743 145.435 119.976 145.202 120.443 144.735L133.735 131.443C134.202 130.976 134.435 130.743 134.602 130.47C134.75 130.229 134.859 129.966 134.925 129.69C135 129.379 135 129.049 135 128.389V109.611C135 108.951 135 108.621 134.925 108.31C134.859 108.035 134.75 107.771 134.602 107.53C134.435 107.257 134.202 107.024 133.735 106.557L120.443 93.2653C119.976 92.7983 119.743 92.5648 119.47 92.3979C119.229 92.2498 118.966 92.1408 118.69 92.0746C118.379 92 118.049 92 117.389 92H98.6114C97.951 92 97.6208 92 97.3101 92.0746C97.0346 92.1408 96.7712 92.2498 96.5296 92.3979C96.2572 92.5648 96.0237 92.7983 95.5567 93.2653L82.2653 106.557C81.7983 107.024 81.5648 107.257 81.3979 107.53C81.2498 107.771 81.1408 108.035 81.0746 108.31C81 108.621 81 108.951 81 109.611Z'
            stroke='#00326D'
            strokeWidth='9'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M27.8633 1.93236C30.4851 0.707639 33.5149 0.707638 36.1367 1.93236C37.486 2.56267 38.9615 2.8763 40.4506 2.8493C43.3438 2.79685 46.1117 4.02916 48.0087 6.21439C48.985 7.33902 50.2053 8.22568 51.5766 8.80666C54.2411 9.93555 56.2684 12.1871 57.1126 14.955C57.5471 16.3795 58.3013 17.6859 59.3177 18.7744C61.2927 20.8894 62.2289 23.7709 61.8743 26.6428C61.6918 28.1209 61.8495 29.6211 62.3353 31.0289C63.2793 33.7644 62.9626 36.7775 61.4705 39.257C60.7026 40.533 60.2365 41.9676 60.1077 43.4513C59.8574 46.3343 58.3425 48.9581 55.971 50.6163C54.7505 51.4697 53.7411 52.5907 53.02 53.8937C51.6187 56.4256 49.1676 58.2065 46.3267 58.7567C44.8646 59.0399 43.4865 59.6535 42.2977 60.5505C39.9878 62.2936 37.0243 62.9235 34.2051 62.2707C32.7542 61.9347 31.2458 61.9347 29.7949 62.2707C26.9757 62.9235 24.0122 62.2936 21.7023 60.5505C20.5135 59.6535 19.1354 59.0399 17.6733 58.7567C14.8324 58.2065 12.3813 56.4256 10.98 53.8937C10.2589 52.5907 9.24953 51.4697 8.02901 50.6163C5.65746 48.9581 4.14258 46.3343 3.89232 43.4513C3.76351 41.9676 3.29737 40.533 2.52948 39.257C1.03741 36.7775 0.720713 33.7644 1.66467 31.0289C2.15048 29.6211 2.30816 28.1209 2.12567 26.6428C1.77107 23.7709 2.70732 20.8894 4.68229 18.7744C5.6987 17.6859 6.45294 16.3795 6.8874 14.955C7.7316 12.1871 9.75889 9.93555 12.4234 8.80666C13.7947 8.22568 15.015 7.33902 15.9913 6.21439C17.8883 4.02916 20.6562 2.79685 23.5494 2.8493C25.0385 2.8763 26.514 2.56267 27.8633 1.93236Z'
            fill='#85FF3B'
          />
          <path
            d='M25.3307 29.3333V26.6667C25.3307 22.9848 28.3155 20 31.9974 20C34.1785 20 36.115 21.0474 37.3313 22.6667M31.9974 34.6667V37.3333M41.3307 36C41.3307 41.1547 37.1521 45.3333 31.9974 45.3333C26.8427 45.3333 22.6641 41.1547 22.6641 36C22.6641 30.8453 26.8427 26.6667 31.9974 26.6667C37.1521 26.6667 41.3307 30.8453 41.3307 36Z'
            stroke='#001C44'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
      <div className={'max-w-[485px]'}>
        <h2 className={'text-[36px] leading-[48px] pb-[16px] hidden md:block'}>
          {t('pages.languageCompliance.languageComplianceDisabled')}
        </h2>
        <p className={'text-[15px]'}>{t('pages.languageCompliance.isCrucialForBrands')}</p>
        <ul className={'py-[24px] text-[#A059FF] text-[17px] flex flex-col gap-[8px]'}>
          <li className={'flex items-center gap-[8px]'}>
            <IconCheck color={'#A059FF'} className={'w-[16px]'} />
            {t('pages.languageCompliance.protectBrandReputation')}
          </li>
          <li className={'flex items-center gap-[8px]'}>
            <IconCheck color={'#A059FF'} className={'w-[16px]'} />
            {t('pages.languageCompliance.avoidLegalIssues')}
          </li>
          <li className={'flex items-center gap-[8px]'}>
            <IconCheck color={'#A059FF'} className={'w-[16px]'} />
            {t('pages.languageCompliance.improvedConsumerTrustAndLoyalty')}
          </li>
        </ul>
        <p className={'text-[15px]'}>{t('pages.languageCompliance.canProvideRealTime')}</p>
        {process.env.REACT_APP_PROJECT === 'bluepear' && (
          <>
            <p className={'text-[15px] mt-[24px] mb-[16px]'}>Tackle Compliance Challenges</p>
            <a
              target='_blank'
              href={
                'https://bluepear.net/blog/language-compliance-control-affiliate-content-monitoring'
              }
              className='bg-default hover:bg-default-500 !text-white px-[16px] py-[8px] text-[14px] rounded-[6px] leading-[16px]'
              rel='noreferrer'
            >
              Learn How!
            </a>
          </>
        )}
      </div>
    </div>
  )
}
