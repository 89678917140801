import React, { ComponentType, ForwardedRef, forwardRef, useState } from 'react'
import { useOutsideClick } from '../hooks/UseOutsideClick'
import { useAppSelector } from '../../store/hooks/Redux'
import { selectCanCloseDropdown } from '../../store/reducers/AppSlice'

export interface IWithDropdownProps {
  open?: boolean
  onClose?: () => void
  onToggle?: () => void
  className?: string
}

export function withDropdown<T>(WrappedComponent: ComponentType<T>) {
  return forwardRef((props: IWithDropdownProps & T, ref: ForwardedRef<IWithDropdownProps & T>) => {
    const canCloseDropDown = useAppSelector(selectCanCloseDropdown)
    const [isOpen, setIsOpen] = useState(props.open)
    const clickRef = useOutsideClick(() => {
      if (canCloseDropDown) setIsOpen(false)
    })

    return (
      <div ref={clickRef} id='with-dropdown' className={props.className}>
        <WrappedComponent
          {...props}
          ref={ref}
          open={isOpen}
          onFocus={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onToggle={() => {
            setIsOpen(!isOpen)
            props.onToggle && props.onToggle()
          }}
        ></WrappedComponent>
      </div>
    )
  })
}
