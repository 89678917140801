import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpLink } from '../../../../shared/components/text/link/BpLink'
import { FormHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'

export interface IResetPasswordFormData {
  email: string
}

export interface IResetFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: IResetPasswordFormData) => void
  isFetching?: boolean
}

export const ResetPasswordForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: IResetFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IResetPasswordFormData>({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  })

  return (
    <>
      <h2 className='mb-[24px]'>{t('pages.auth.resetPassword')}</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: IResetPasswordFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.email')}
          error={errors.email?.message}
          {...register('email', {
            required: t('fields.validate.emailRequired'),
            pattern: {
              value: EMAIL_REGEX,
              message: t('fields.validate.enterValidEmail'),
            },
          })}
        ></BpInput>
        <div className='mt-[16px] flex flex-col'>
          <BpButton type='submit' disabled={!isValid || isFetching} size='lg'>
            {t('pages.auth.buttons.sendResetLink')}
          </BpButton>
        </div>
        <div className='self-center'>
          <BpLink to='/auth'>{t('pages.auth.buttons.goBackToLogin')}</BpLink>
        </div>
      </form>
    </>
  )
}
