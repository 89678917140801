import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { ProjectModel } from './models/Project.model'
import { IUpdateInfoParams } from './interfaces/UpdateInfoParams.interface'
import { IUpdateNotificationsParams } from './interfaces/UpdateNotificationsParams.interface'
import { IGetLocationsDefaultSettingsParams } from './interfaces/GetLocationsDefaultSettingsParams.interface'

export const projectApi = createApi({
  reducerPath: 'project/api',
  tagTypes: ['Project'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/project/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getInfo: build.query<ICommonResponse<any>, { id: string }>({
      query: (params: any) => ({ url: 'info', params }),
      providesTags: ['Project'],
    }),
    getList: build.query<TTableResponse<ProjectModel>, any>({
      query: (params: any) => ({ url: 'list', params }),
      providesTags: ['Project'],
    }),
    getMainSettings: build.query<ICommonResponse<any>, { id: string }>({
      query: (params: any) => ({ url: 'main-settings', params }),
      providesTags: ['Project'],
    }),
    getLocations: build.query<ICommonResponse<any>, any>({
      query: (params: any) => ({ url: 'locations', params }),
      providesTags: ['Project'],
    }),
    getLocationsDefaultSettings: build.query<
      ICommonResponse<any>,
      IGetLocationsDefaultSettingsParams
    >({
      query: (params: IGetLocationsDefaultSettingsParams) => ({
        url: 'locations-default-settings',
        params,
      }),
      providesTags: ['Project'],
    }),
    getTerms: build.query<ICommonResponse<any>, { project_id: string }>({
      query: (params: any) => ({ url: 'terms', params }),
      providesTags: ['Project'],
    }),
    getAvailableCountries: build.query<IOption[], { project_id: string }>({
      query: (params: any) => ({ url: 'available-countries', params }),
      providesTags: ['Project'],
      transformResponse: (response: any): IOption[] => {
        if (response?.data) {
          return response.data.items.map((o: any) => ({
            text: o.name,
            value: `${o.id}`,
          }))
        }

        return []
      },
    }),
    createProject: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'create',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('step', params.step)
          formData.append('project_name', params.project_name)
          formData.append('brand_name', params.brand_name)
          formData.append('brand_variants', JSON.stringify(params.brand_variants))
          formData.append(
            'domains',
            JSON.stringify(params.domains?.filter((domain: string) => domain.length)),
          )
          formData.append('engines', JSON.stringify(params.engines))
          formData.append('devices', JSON.stringify(params.devices))
          formData.append('locations', JSON.stringify(params.locations))
          formData.append('terms', JSON.stringify(params.terms))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateBrand: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'update-main-settings',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('scenario', 'update_brand')
          formData.append('id', JSON.stringify(Number(params.id)))
          formData.append('brand_name', params.brand_name)
          formData.append('brand_variants', JSON.stringify(params.brand_variants.split('\n')))
          formData.append('domains', JSON.stringify(params.domains.split('\n')))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateDevices: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'update-main-settings',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('scenario', 'update_devices')
          formData.append('id', JSON.stringify(Number(params.id)))
          formData.append('devices', JSON.stringify(params.devices))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateNotifications: build.mutation<ICommonResponse<any>, IUpdateNotificationsParams>({
      query: (params: IUpdateNotificationsParams) => ({
        url: 'update-main-settings',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('scenario', 'update_notifications')
          formData.append('id', JSON.stringify(params.id))
          formData.append('telegram_chat_id', params.telegram_chat_id)
          formData.append('report_emails', params.report_emails)
          formData.append('notify_new_publishers', JSON.stringify(params.notify_new_publishers))
          formData.append('notify_report', JSON.stringify(params.notify_report))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateStatus: build.mutation<ICommonResponse<any>, any>({
      query: ({ id, status }: any) => ({
        url: 'update-status',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('id', `${id}`)
          formData.append('status', `${status}`)

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateInfo: build.mutation<ICommonResponse<any>, IUpdateInfoParams>({
      query: (params: IUpdateInfoParams) => ({
        url: 'update-info',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('id', JSON.stringify(params.id))
          formData.append('client_id', JSON.stringify(params.client_id))
          formData.append('name', params.name)

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateLocations: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'update-locations',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('project_id', JSON.stringify(Number(params.id)))
          formData.append('data', JSON.stringify(params.data))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    updateTerms: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'update-terms',
        method: 'POST',
        body: (() => {
          const formData = new FormData()

          formData.append('project_id', JSON.stringify(Number(params.id)))
          formData.append('data', JSON.stringify(params.data))

          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Project'],
    }),
    downloadReport: build.mutation<Blob, { project_id?: string }>({
      query(params: any) {
        return {
          url: 'download-report',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    sendWeeklyReportTelegram: build.query<ICommonResponse<any>, { project_id?: string }>({
      query: (params: any) => ({ url: 'send-weekly-report-telegram', params }),
      providesTags: ['Project'],
    }),
    sendWeeklyReportEmail: build.query<ICommonResponse<any>, { project_id?: string }>({
      query: (params: any) => ({ url: 'send-weekly-report-email', params }),
      providesTags: ['Project'],
    }),
    sendAlertTelegram: build.query<ICommonResponse<any>, { project_id?: string }>({
      query: (params: any) => ({ url: 'send-alert-telegram', params }),
      providesTags: ['Project'],
    }),
    sendAlertEmail: build.query<ICommonResponse<any>, { project_id?: string }>({
      query: (params: any) => ({ url: 'send-alert-email', params }),
      providesTags: ['Project'],
    }),
    deleteDemoProject: build.mutation<ICommonResponse<any>, any>({
      query: () => ({
        url: '/delete-demo-project',
        method: 'POST',
      }),
      invalidatesTags: ['Project'],
    }),
  }),
})

export const {
  useGetInfoQuery,
  useGetListQuery,
  useGetMainSettingsQuery,
  useGetLocationsQuery,
  useGetLocationsDefaultSettingsQuery,
  useLazyGetLocationsDefaultSettingsQuery,
  useGetTermsQuery,
  useGetAvailableCountriesQuery,
  useCreateProjectMutation,
  useUpdateBrandMutation,
  useUpdateDevicesMutation,
  useUpdateNotificationsMutation,
  useUpdateStatusMutation,
  useUpdateInfoMutation,
  useUpdateLocationsMutation,
  useUpdateTermsMutation,
  useDownloadReportMutation,
  useLazySendWeeklyReportTelegramQuery,
  useLazySendWeeklyReportEmailQuery,
  useLazySendAlertTelegramQuery,
  useLazySendAlertEmailQuery,
  useDeleteDemoProjectMutation,
} = projectApi
