import React, { useEffect } from 'react'
import { Header } from './header/Header'
import { Footer } from './footer/Footer'
import { Outlet } from 'react-router-dom'
import { BpSelect } from '../../shared/components/forms/select/BpSelect'
import { useAppDispatch, useAppSelector } from '../../store/hooks/Redux'
import { selectTariffDetails, selectUser, setTariffDetails } from '../../store/reducers/AuthSlice'
import { useGetCurrentTariffDetailsQuery } from '../../api/user/User.api'
import { BpButton } from '../../shared/components/buttons/bp-button/BpButton'
import { showAlert } from '../../store/reducers/AppSlice'
import { useGetVirtualUsersQuery, useLazyGetVirtualLoginQuery } from '../../api/admin/Admin.api'
import { useLazySendVerificationQuery } from '../../api/site/Site.api'
import { useTranslation } from 'react-i18next'
import { IconPaperAirplane } from '../../shared/components/icons/IconPaperAirplane'

export const MainLayout = () => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const tariffDetails = useAppSelector(selectTariffDetails)
  const dispatch = useAppDispatch()

  const { data: currentTariff, refetch: refetchTariff } = useGetCurrentTariffDetailsQuery({})
  const { data: virtualUsers } = useGetVirtualUsersQuery(
    {},
    { skip: user.role !== 'admin' && !user.is_virtual_mode },
  )
  const [virtualLogin] = useLazyGetVirtualLoginQuery()
  const [sendVerification] = useLazySendVerificationQuery()

  useEffect(() => {
    if (currentTariff?.data) {
      dispatch(setTariffDetails(currentTariff?.data))
    }
  }, [currentTariff])

  useEffect(() => {
    if (tariffDetails?.tariffUpdated) {
      setTimeout(() => refetchTariff(), 2500)
    }
  }, [tariffDetails.tariffUpdated])

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === 'bluepear') {
      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NKXFCZD');
    `
      document.head.appendChild(gtmScript)

      return () => {
        document.head.removeChild(gtmScript)
      }
    }
  }, [])

  return (
    <main className='relative bg-grey'>
      <div className={'relative w-full z-20'}>
        {!user.is_email_verified && !(user.role === 'admin' || user.is_virtual_mode) && (
          <div className={'w-full bg-default'}>
            <div className={'container mx-auto px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px]'}>
              <div className={'flex items-center gap-[4px] py-[6px]'}>
                <p className={'text-white'}>{t('other.confirmYourEmail')}</p>
                <BpButton
                  iconLeft={<IconPaperAirplane />}
                  onClick={() => {
                    sendVerification().then((response) => {
                      if (response.data.success) {
                        dispatch(
                          showAlert({
                            message: response.data.data?.message,
                            type: 'success',
                            timeout: 2000,
                          }),
                        )
                      }
                    })
                  }}
                  className={'!p-0 hover:opacity-[0.8]'}
                  contentClassName={'!text-white border-b-[#FFFFFF66] border-b-[1px] border-dashed'}
                  size={'sm'}
                  theme={'transparent'}
                >
                  {t('buttons.resendConfirmationLink')}
                </BpButton>
              </div>
            </div>
          </div>
        )}
        {(user.role === 'admin' || user.is_virtual_mode) && (
          <div className={'container mx-auto px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px]'}>
            <div className={'flex items-center gap-[4px] py-[6px]'}>
              <p className={'text-white'}>Impersonated as</p>
              <BpSelect
                className={'max-w-[200px]'}
                placeholder={'Select user'}
                options={virtualUsers}
                fieldSize={'sm'}
                theme={'text'}
                showSearch
                defaultValue={String(user.id)}
                loading={!virtualUsers}
                onChange={(e) => {
                  const userId = Number(e.target.value)

                  userId !== user.id &&
                    virtualLogin({ user_id: userId }).then(() => {
                      window.location.href = '/projects'
                    })
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className='bg-focus-500 absolute left-0 top-0 h-[540px] md:h-[364px] lg:h-[260px] xl:h-[208px] w-full animate-appear-hand'></div>
      <div className='relative flex flex-col p-[16px] pb-0 xl:pt-0 justify-between min-h-screen relative overflow-hidden container xl:max-w-[1440px] xxl:max-w-[1920px] mx-auto z-10'>
        <header className='relative z-50'>
          <Header />
        </header>
        <section className='flex flex-1 mt-[22px] xl:mt-0'>
          <Outlet />
        </section>
        <Footer />
      </div>
      <div className='bg-focus-500 absolute left-0 bottom-0 h-[360px] md:h-[256px] lg:h-[201px] w-full'></div>
    </main>
  )
}
