import { CommonMainPage } from '../../utility/CommonMainPage'
import { LanguageComplianceFilters } from './LanguageComplianceFilters'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { LanguageComplianceAllChecks } from './LanguageComplianceAllChecks'
import { LanguageComplianceLastCheck } from './LanguageComplianceLastCheck'
import { LanguageComplianceInProgress } from './LanguageComplianceInProgress'
import {
  useGetCounterQuery,
  useGetEnabledQuery,
} from '../../../../api/language-compliance/LanguageCompliance.api'
import { LanguageComplianceStub } from './LanguageComplianceStub'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { selectLanguageComplianceFilter } from '../store/LanguageComplianceSlice'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import { LanguageCompiliancePanel } from './LanguageCompiliancePanel'

export const LanguageCompliancePage = () => {
  const filters = useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const { project, country, url, affiliate, dateRange, restrictedWords, hidden } = filters

  const { data: enabled, isLoading, isFetching } = useGetEnabledQuery()
  const { data: counter } = useGetCounterQuery(
    {
      date: dateRange,
      project_id: Number(project),
      country_id: Number(country),
      url: url,
      affiliate: affiliate,
      word_id: restrictedWords ? Number(restrictedWords) : undefined,
      show_hidden: hidden,
    },
    { skip: !project || !country },
  )

  return (
    <CommonMainPage>
      {enabled ? (
        <section className='h-full grid grid-rows-[min-content_min-content_1fr]'>
          <LanguageComplianceFilters />
          <BpTabs className='mt-[19px]'>
            <BpTab
              title={'All Checks'}
              counter={counter?.data.history ? String(counter?.data.history) : undefined}
              panel={<LanguageCompiliancePanel />}
            >
              <LanguageComplianceAllChecks />
            </BpTab>
            <BpTab
              title={'Infringements'}
              counter={counter?.data.last_check ? String(counter?.data.last_check) : undefined}
              panel={<LanguageCompiliancePanel />}
            >
              <LanguageComplianceLastCheck />
            </BpTab>
            <BpTab
              title={'In Progress'}
              counter={counter?.data.in_progress ? String(counter?.data.in_progress) : undefined}
              panel={<LanguageCompiliancePanel />}
            >
              <LanguageComplianceInProgress />
            </BpTab>
          </BpTabs>
        </section>
      ) : (
        <LanguageComplianceStub isLoading={isLoading || isFetching} />
      )}
    </CommonMainPage>
  )
}
