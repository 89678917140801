import { ExportDrop } from '../../../../shared/components/cards/export-drop/ExportDrop'
import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import { selectPpcComplianceFilter, setPpcComplianceFilter } from '../store/PpcComplianceSlice'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { INonCompliantAffiliatesParams } from '../../../../api/compliance/interfaces/NonCompliantAffiliatesParams.interface'
import { useExportNonCompliantAffiliatesMutation } from '../../../../api/export-reports/ExportReports.api'
import { useTranslation } from 'react-i18next'
import {GoogleButton} from '../../../auth/utility/GoogleButton';

export const PpcCompliancePanel = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const filters = useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const {
    project,
    country,
    affiliate,
    network,
    dateRange,
    site,
    engine,
    browser,
    city,
    hidden,
    adHijackers,
    status,
  } = filters

  const [queryParams, setQueryParams] = useState<INonCompliantAffiliatesParams>()

  const [exportNonCompliantAffiliates, { isLoading: exportLoading }] =
    useExportNonCompliantAffiliatesMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      project_id: project ? Number(project) : undefined,
      country_id: country ? Number(country) : undefined,
      affiliate,
      network: network,
      date: dateRange,
      site: site,
      engine_id: engine ? Number(engine) : undefined,
      browser_id: browser ? Number(browser) : undefined,
      city_id: city ? Number(city) : undefined,
      is_hidden: hidden,
      is_hijack: adHijackers,
      status_id: status ? Number(status) : undefined,
    })
  }, [filters])

  return (
    <div className='flex gap-[16px] items-center min-w-max'>
      {
        {
          bluepear: (
              <a className={'text-default'} target={'_blank'} href='https://bluepear.net/blog/tackling-affiliate-policy-violations' rel="noreferrer">How
                report works</a>
          ),
          robuscan: null,
          default: null,
        }[process.env.REACT_APP_PROJECT ?? 'default']
      }
      <span className='flex items-center gap-[9px]'>
        <Switch
          defaultChecked={adHijackers}
          onClick={(e) => {
            dispatch(setPpcComplianceFilter({ ...filters, adHijackers: e }))
          }}
        ></Switch>
        {t('fields.adHijackers')}
        <BpTooltip title={t('pages.PPCCompliance.adHijackersTooltip')} />
      </span>
      <ExportDrop
        onExport={(exportParams) =>
          exportNonCompliantAffiliates({ ...queryParams, ...exportParams })
        }
        loading={exportLoading}
        columns={[
          {
            label: t('table.columns.affiliates'),
            bold: true,
            child: [
              { keys: ['affiliate'], label: t('table.columns.affiliateId') },
              { keys: ['ads_count'], label: t('table.columns.adsAnalyzed') },
              {
                keys: ['network_name'],
                label: t('table.columns.network'),
                tooltip: t('pages.PPCCompliance.networkTooltip'),
              },
              { keys: ['status_id'], label: t('table.columns.status') },
              { keys: ['first_found'], label: t('table.columns.firstFound') },
              { keys: ['last_found'], label: t('table.columns.lastFound') },
            ],
          },
          {
            keys: ['latest_examples'],
            label: t('table.columns.latestExamples'),
            tooltip: t('pages.PPCCompliance.latestExamplesTooltip'),
            bold: true,
          },
        ]}
      />
    </div>
  )
}
