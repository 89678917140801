import { CommonMainPage } from '../utility/CommonMainPage'
import { OrganicResultsFilters } from './components/filters/OrganicResultsFilters'
import { OrganicResultsTable } from './components/report/OrganicResultsTable'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectProject } from './store/OrganicResultsSlice'
import { ProjectStub } from '../../../shared/components/utility/project-stub/ProjectStub'

export const OrganicResultsPage = () => {
  const { has_seo_links: hasSeoLinks } = useAppSelector(selectProject)

  return (
    <CommonMainPage>
      <section className='h-full grid gap-[50px] grid-rows-[min-content_1fr]'>
        <OrganicResultsFilters />
        {hasSeoLinks ? <OrganicResultsTable /> : <ProjectStub />}
      </section>
    </CommonMainPage>
  )
}
