import { Square } from '../../utility/square/Square'
import { IconAlert } from '../../icons/IconAlert'
import { IconCheck } from '../../icons/IconCheck'
import { IconBell } from '../../icons/IconBell'
import { IconHorn } from '../../icons/IconHorn'
import { IconButton } from '../../buttons/icon-button/IconButton'
import { IconCrossBold } from '../../icons/IconCrossBold'
import { useEffect, useState } from 'react'

export type BpAlertType = 'success' | 'error' | 'warning' | 'info'

export interface IBpAlertProps {
  type?: BpAlertType
  onAfterClose?: () => void
  message: string
  className?: string
  show?: boolean
  timeout?: number
}

export const BpAlert = (props: IBpAlertProps) => {
  const { type = 'info', message, onAfterClose, className, show = true, timeout = 5000 } = props
  const [isShow, setIsShow] = useState<boolean>(show)

  const iconElement = (() => {
    switch (type) {
      case 'success':
        return <IconCheck color='#309700' />
      case 'error':
        return <IconAlert color='#DC2F02' />
      case 'warning':
        return <IconBell color='#F48C06' />
      case 'info':
        return <IconHorn color='#00326D' />
    }
  })()

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setIsShow(false)
      }, timeout)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    setIsShow(show)
  }, [show])

  return (
    <Square
      className={`${className} flex flex-col ${
        isShow ? 'animate-appear-hand' : 'animate-hide-hand'
      }`}
      onAnimationEnd={() => {
        if (!isShow && onAfterClose) onAfterClose()
      }}
    >
      <div className='flex gap-[16px] px-[16px] py-[18px]'>
        <span className='w-[20px]'>{iconElement}</span>{' '}
        <span className='flex-1 text-[13px] text-focus-500 leading-[24px] overflow-hidden break-words'>
          {message}
        </span>{' '}
        <IconButton onClick={() => setIsShow(false)}>
          <IconCrossBold color='#6684A7' />
        </IconButton>
      </div>
    </Square>
  )
}
