import { Popconfirm } from 'antd'
import { PopconfirmProps } from 'antd/lib/popconfirm'
import { BpButton, IBpButtonProps } from '../../buttons/bp-button/BpButton'
import { useState } from 'react'

interface BpPopconfirmProps extends Omit<PopconfirmProps, 'okButtonProps' | 'cancelButtonProps'> {
  okButtonProps?: IBpButtonProps
  cancelButtonProps?: IBpButtonProps
  theme?: 'dark' | 'light'
}

export const BpPopconfirm = ({ theme = 'dark', ...props }: BpPopconfirmProps) => {
  const [visible, setVisible] = useState(false)

  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
    setVisible(false)
  }

  const handleConfirm = () => {
    if (props.onConfirm) props.onConfirm()
    setVisible(false)
  }

  return (
    <Popconfirm
      {...props}
      visible={visible}
      onVisibleChange={setVisible}
      overlayClassName={`${theme} ${props.overlayClassName}`}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      icon={false}
      arrowPointAtCenter={true}
      title={
        <div className={'flex flex-col gap-[16px]'}>
          <div>{props.title as string}</div>
          <div className={'flex gap-[8px]'}>
            <BpButton {...props.okButtonProps} onClick={handleConfirm} size={'sm'}>
              {props.okText}
            </BpButton>
            <BpButton
              {...props.cancelButtonProps}
              onClick={handleCancel}
              size={'sm'}
              theme={'outline'}
            >
              {props.cancelText || 'Cancel'}
            </BpButton>
          </div>
        </div>
      }
    />
  )
}
