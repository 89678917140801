import React, { useEffect } from 'react'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpTextEditor } from '../../../../shared/components/forms/text-editor/TextEditor'
import {
  useGetEmailListQuery,
  useGetLastSendEmailQuery,
  useSendAbuseMailMutation,
} from '../../../../api/compliance/Compliance.api'
import { ISendAbuseMailParams } from '../../../../api/compliance/interfaces/SendAbuseMailParams.interface'
import { ObjectToArray } from '../../../../shared/helpers/ObjectToArray'
import { IconUser } from '../../../../shared/components/icons/IconUser'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { hideModal, showAlert } from '../../../../store/reducers/AppSlice'
import { useDispatch } from 'react-redux'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import { selectPpcComplianceFilter } from '../store/PpcComplianceSlice'
import { selectUser } from '../../../../store/reducers/AuthSlice'
import { IUserModel } from '../../../../api/site/models/User.model'
import { useTranslation } from 'react-i18next'

export interface PpcComplianceReportAdsProps {
  id: number
  adsCount: number
  affiliate: string
  projectId: number
  networkId: number
  networkName?: string
}

export const PpcComplianceReportAds = ({
  id,
  adsCount,
  affiliate,
  projectId,
  networkId,
  networkName,
}: PpcComplianceReportAdsProps) => {
  const { t } = useTranslation()
  const { country, dateRange, site, engine, browser, city, adHijackers } =
    useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const { email } = useAppSelector<IUserModel>(selectUser)
  const {
    control,
    resetField,
    handleSubmit,
    reset,
    register,
    formState: { isValid, errors },
  } = useForm<ISendAbuseMailParams>({
    mode: 'onChange',
    defaultValues: {
      rememberForNetwork: !!networkName,
      rememberForAffiliate: true,
      markAffiliate: false,
      subject: t('fields.other.ceaseAndDesist'),
    },
  })
  const dispatch = useDispatch()

  const {
    data: lastSendEmail,
    isLoading,
    isFetching,
  } = useGetLastSendEmailQuery(
    {
      affiliate_id: id,
      project_id: projectId,
      network_id: networkId,
    },
    {
      selectFromResult: (state) => {
        return { ...state, data: state.data?.data?.items[0] }
      },
    },
  )
  const [sendAbuseMail, { isLoading: sendAbuseMailIsLoading }] = useSendAbuseMailMutation()
  const { data: emails } = useGetEmailListQuery({})

  useEffect(() => {
    lastSendEmail &&
      reset({
        to: lastSendEmail.send_to.join(','),
        carbonCopy: ObjectToArray(lastSendEmail.carbonCopy).join(',') || email,
        subject: lastSendEmail.subject || t('fields.other.ceaseAndDesist'),
        body: lastSendEmail.body,
      })
  }, [lastSendEmail])

  if (isLoading || isFetching) {
    return (
      <div className='relative min-h-[200px]'>
        <Preloader />
      </div>
    )
  }

  return (
    <div className={'flex flex-col overflow-hidden'}>
      <h3
        className={
          'text-[20px] flex min-h-[55px] items-center px-[32px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        <span className={'truncate max-w-[90%] font-medium'}>
          {t('pages.PPCCompliance.editEmailForAffiliate')} {affiliate}
        </span>
      </h3>
      <div className={'overflow-y-auto'}>
        <form className={'flex flex-col gap-[16px] pt-[18px] pb-[16px] px-[24px]'}>
          <Controller
            name={'from'}
            control={control}
            render={({ field }) => (
              <BpSelect
                {...field}
                required
                fieldSize={'lg'}
                Icon={IconUser}
                options={emails}
                label={t('fields.from')}
                defaultValue={emails && emails[0].value}
              ></BpSelect>
            )}
          />
          <Controller
            name='to'
            control={control}
            render={({ field }) => (
              <BpInput
                {...field}
                required
                fieldSize={'lg'}
                label={t('fields.to')}
                placeholder={t('fields.receivers')}
                error={errors.to?.message}
                onClear={() => resetField('to', { defaultValue: '' })}
                {...register('to', {
                  required: 'Required',
                  validate: (value) => {
                    const emails = value.split(/\s*,\s*/)

                    return value
                      ? emails.find((email) => !EMAIL_REGEX.test(email)) && 'Invalid email address'
                      : true
                  },
                })}
              />
            )}
          />
          {networkName && (
            <Controller
              name='rememberForNetwork'
              control={control}
              render={({ field: { value, ...fieldProps } }) => (
                <BpCheckbox
                  {...fieldProps}
                  defaultChecked={value}
                  label={t('fields.rememberNetwork', { name: networkName })}
                />
              )}
            />
          )}
          <Controller
            name='rememberForAffiliate'
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <BpCheckbox
                {...fieldProps}
                defaultChecked={value}
                label={t('fields.rememberForThisAffiliate')}
              />
            )}
          />
          <Controller
            name='carbonCopy'
            control={control}
            render={({ field }) => (
              <BpInput
                {...field}
                required
                fieldSize={'lg'}
                label={t('fields.carbonCopy')}
                placeholder={t('fields.receivers')}
                error={errors.carbonCopy?.message}
                onClear={() => resetField('carbonCopy', { defaultValue: '' })}
                {...register('carbonCopy', {
                  required: 'Required',
                  validate: (value) => {
                    const emails = value.split(/\s*,\s*/)

                    return value
                      ? emails.find((email) => !EMAIL_REGEX.test(email)) && 'Invalid email address'
                      : true
                  },
                })}
              />
            )}
          />
          <Controller
            name='subject'
            control={control}
            render={({ field }) => (
              <BpInput
                {...field}
                fieldSize={'lg'}
                label={t('fields.subject')}
                onClear={() => resetField('subject', { defaultValue: '' })}
                error={errors.subject?.message}
                {...register('subject', {
                  maxLength: {
                    value: 512,
                    message: 'Please keep text under 512 characters',
                  },
                })}
              />
            )}
          />
          <div>
            <div className={'text-[14px] font-medium mb[2px] leading-[24px] text-focus-500'}>
              {t('fields.attachments')}
            </div>
            <div className={'flex gap-[12px]'}>
              <div className={'bp-btn outline sm pointer-events-none'}>
                {t('pages.PPCCompliance.latestAdReportPDF')}
              </div>
              <div className={'bp-btn outline sm pointer-events-none'}>
                {t('pages.PPCCompliance.reportCountAdsXLS', { count: adsCount })}
              </div>
            </div>
          </div>
          <Controller
            name='body'
            control={control}
            render={({ field }) => (
              <BpTextEditor
                {...field}
                label={t('fields.messageText')}
                placeholder={t('fields.messageTextExample')}
                max={4096}
              />
            )}
          />
          <Controller
            name='markAffiliate'
            control={control}
            render={({ field: { value, ...fieldProps } }) => (
              <BpCheckbox
                {...fieldProps}
                defaultChecked={value}
                label={t('fields.markAffiliateAsInProgress')}
              />
            )}
          />
        </form>
      </div>
      <div
        className={
          'w-full flex gap-[12px] px-[32px] py-[24px] border-t-[3px] border-t-[#00326D]/[.02]'
        }
      >
        <BpButton
          className={'uppercase'}
          disabled={!isValid}
          loading={sendAbuseMailIsLoading}
          onClick={handleSubmit((data) =>
            sendAbuseMail({
              ...data,
              filterData: {
                date: dateRange,
                project_id: projectId,
                affiliate_id: id,
                affiliate: affiliate,
                network_id: networkId,
                country_id: country ? Number(country) : undefined,
                site: site,
                browser_id: browser ? Number(browser) : undefined,
                engine_id: engine ? Number(engine) : undefined,
                city_id: city ? Number(city) : undefined,
                is_hijack: adHijackers,
              },
            }).then((response) => {
              if ('error' in response || !response.data.success) {
                return
              } else {
                dispatch(hideModal())
                dispatch(
                  showAlert({
                    message: 'Your email has been sent successfully',
                    type: 'success',
                  }),
                )
              }
            }),
          )}
        >
          {t('buttons.sendMail')}
        </BpButton>
      </div>
    </div>
  )
}
