import { ByAdvertisersGraph } from './by-advertisers/ByAdvertisersGraph'
import { KeywordsReportTable } from './report/KeywordsReportTable'

export const Keywords = () => {
  return (
    <section className='h-full grid gap-[50px] grid-rows-[min-content_1fr]'>
      <ByAdvertisersGraph />
      <KeywordsReportTable />
    </section>
  )
}
