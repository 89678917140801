import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectProjectsFilters,
  selectProjectsPagination,
  setProjectsPagination,
} from '../store/ProjectsSlice'
import { IProjectFilters } from '../interfaces/ProjectFilters.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Space, Tooltip } from 'antd'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { setModal } from '../../../../store/reducers/AppSlice'
import { AddProject } from './AddProject'
import {
  useDeleteDemoProjectMutation,
  useGetListQuery,
  useUpdateStatusMutation,
} from '../../../../api/project/Project.api'
import { cropRow } from '../../../../shared/helpers/crop-row'
import { DropOptions } from '../../../../shared/components/forms/drop-options/DropOptions'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconSettings } from '../../../../shared/components/icons/IconSettings'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IconPlus } from '../../../../shared/components/icons/IconPlus'
import { ProjectModel } from '../../../../api/project/models/Project.model'
import { IGetProjectsList } from '../../../../api/project/interfaces/GetProjectsList.interface'
import {
  removeNotification,
  selectTariffDetails,
  selectUser,
  setTariffUpdated,
} from '../../../../store/reducers/AuthSlice'
import { TariffButton } from '../../../../shared/components/buttons/tariff-button/TariffButton'
import { IconCrossBold } from '../../../../shared/components/icons/IconCrossBold'
import { IconTrash } from '../../../../shared/components/icons/IconTrash'
import { BpPopconfirm } from '../../../../shared/components/cards/popconfirm/BpPopconfirm'
import { useLazyHideNotificationQuery } from '../../../../api/site/Site.api'
import { IconArrowRight } from '../../../../shared/components/icons/IconArrowRight'
import { IconEdit2 } from '../../../../shared/components/icons/IconEdit2'
import { IBrandPresenceModel } from '../../../../api/research/models/BrandPresence.model'
import { SerpPresenceExpandRow } from '../../serp-presence/component/report/SerpPresenceExpandRow'

export const ProjectsTable = () => {
  const { t } = useTranslation()
  const user = useAppSelector(selectUser)
  const [showExploreDemo, setShowExploreDemo] = useState(false)
  const tariffDetails = useAppSelector(selectTariffDetails)
  const navigate = useNavigate()
  const filters = useAppSelector<IProjectFilters>(selectProjectsFilters)
  const { project, country, status } = filters
  const { current, pageSize, total } = useAppSelector<IPagination>(selectProjectsPagination)
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const dispatch = useAppDispatch()

  const [queryParams, setQueryParams] = useState<IGetProjectsList>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching } = useGetListQuery(queryParams, {
    selectFromResult: (result) => ({
      ...result,
      data: {
        ...result.data,
        data: {
          ...result.data?.data,
          items: result.data?.data?.items
            ? [
                ...result.data.data.items.filter((item) => !item.is_demo),
                ...result.data.data.items.filter((item) => item.is_demo),
              ]
            : [],
        },
      },
    }),
  })
  const [updateStatus] = useUpdateStatusMutation()
  const [hideNotification] = useLazyHideNotificationQuery()
  const [deleteDemoProject] = useDeleteDemoProjectMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      project_id: project ? project : undefined,
      country_id: country ? country : undefined,
      status: status ? status : undefined,
    })
  }, [filters])

  useEffect(() => {
    const notification = user.notifications?.find(
      (notification) => notification.type === 'explore_demo_reports_block',
    )

    if (notification && !notification.is_hidden) {
      setShowExploreDemo(true)
    }
  }, [user])

  useEffect(() => {
    if (showExploreDemo) {
      setExpandedRowKeys(
        data?.data?.items?.filter((value) => value.is_demo).map((value) => value.id) || [],
      )
    }
  }, [data, showExploreDemo])

  const hideNotify = () => {
    const notification = user.notifications?.find(
      (notification) => notification.type === 'explore_demo_reports_block',
    )

    if (notification) {
      hideNotification({ id: notification.id })
      dispatch(removeNotification(notification.type))
    }

    setShowExploreDemo(false)
  }

  const columns: ColumnsType<ProjectModel> = [
    {
      title: t('table.columns.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (name: string) => <div className={'truncate w-[185px]'}>{name}</div>,
    },
    {
      title: t('table.columns.countries'),
      key: 'countries',
      render: (_: string, { id, countries, is_demo }) => {
        const { visible, hidden } = cropRow(countries, 25)

        return (
          <div className={'flex gap-[8px] items-center w-fit'}>
            <span
              onClick={() => !is_demo && navigate(`/projects/${id}/locations`)}
              className={`border-dotted border-b text-focus
                ${is_demo ? 'cursor-default' : 'cursor-pointer'}
              `}
            >
              {visible}
            </span>
            {!!hidden.length && (
              <Tooltip title={hidden.join(', ')}>
                <span
                  className={
                    'text-aqua-300 cursor-default bg-focus-50 p-[4px] rounded-[4px] text-[13px] leading-[13px] ml-auto'
                  }
                >
                  +{hidden.length}
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.status'),
      key: 'status',
      render: (_: string, { id, status, is_demo }) => {
        const options = [
          {
            text: t('pages.projects.activate'),
            value: 'active',
            hidden: ['Active', 'Archived'].includes(status),
          },
          {
            text: t('pages.projects.pause'),
            value: 'pause',
            hidden: ['On Pause', 'Archived'].includes(status),
          },
          {
            text: t('pages.projects.archive'),
            value: 'archive',
            hidden: status === 'Archived',
          },
          {
            text: t('pages.projects.restore'),
            value: 'active',
            hidden: status !== 'Archived',
          },
        ]

        return is_demo ? (
          <span
            className={
              'text-[10px] font-semibold uppercase py-[2px] px-[8px] rounded-[6px] text-success-400 bg-success-300'
            }
          >
            {t('pages.projects.statuses.Demo project')}
          </span>
        ) : (
          <DropOptions
            options={options}
            onChange={(value) => {
              updateStatus({ id, status: value }).then(() => dispatch(setTariffUpdated()))
            }}
          >
            <span
              className={`text-[10px] font-semibold uppercase py-[2px] px-[8px] rounded-[6px] cursor-pointer
              ${
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                {
                  Active: 'text-success-400 bg-success-300',
                  'On Pause': 'text-[#DA5533] bg-danger/[.07]',
                  Archived: 'text-aqua-300 bg-aqua-300/[.07]',
                }[status]
              }`}
            >
              {t(`pages.projects.statuses.${status}`)}
            </span>
          </DropOptions>
        )
      },
    },
    {
      title: t('table.columns.periodicity'),
      key: 'periodicity',
      render: (_: string, { periodicity }) => {
        return <div>{periodicity ? periodicity : '—'}</div>
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.keywords')}
          <BpTooltip title={t('pages.projects.keywordsTooltip')} />
        </span>
      ),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
      render: (_: string, { id, keywords_count, is_demo }) => {
        return (
          <div
            onClick={() => !is_demo && navigate(`/projects/${id}/keywords`)}
            className={`w-fit ${
              is_demo ? 'cursor-default' : 'cursor-pointer text-focus border-dotted border-b'
            }`}
          >
            {keywords_count > 0 ? keywords_count : '—'}
          </div>
        )
      },
    },
    {
      title: t('table.columns.created'),
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: t('table.columns.updated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      key: 'actions',
      render: (_, { id, is_demo }) => {
        return is_demo ? (
          <Space>
            <BpPopconfirm
              theme={'light'}
              arrowPointAtCenter
              overlayClassName={'w-[260px]'}
              title={t('pages.projects.deleteDemoProject')}
              okText={t('buttons.delete')}
              cancelText={t('buttons.cancel')}
              placement={'bottomRight'}
              onConfirm={deleteDemoProject}
              okButtonProps={{
                iconLeft: <IconTrash className={'stroke-0'} color={'#FFF'} />,
                theme: 'danger',
              }}
            >
              <IconButton size='lg'>
                <IconTrash color='#00326D' />
              </IconButton>
            </BpPopconfirm>
          </Space>
        ) : (
          <Space>
            <IconButton size='lg' onClick={() => navigate(`/projects/${id}`)}>
              <IconEdit2 color='#00326D' />
            </IconButton>
          </Space>
        )
      },
      width: 45,
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<ProjectModel> | SorterResult<ProjectModel>[],
  ) => {
    if (queryParams['per-page'] === pageSize) {
      return
    }

    dispatch(
      setProjectsPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section className={'h-full grid grid-rows-[min-content_1fr]'}>
      <div className='flex items-center px-[16px] pb-[21px] xl:px-[32px] mb-[21px] -mx-[16px] xl:-mx-[32px] border-b-[3px] border-focus-600/[.03]'>
        <BpButton
          contentClassName={'font-normal'}
          iconLeft={<IconPlus />}
          size={'sm'}
          disabled={
            !(
              tariffDetails.tariff &&
              tariffDetails.tariff.max_projects >= tariffDetails.tariff.used_projects_count
            ) || tariffDetails.tariff.is_subscription_expired
          }
          onClick={() => dispatch(setModal({ children: <AddProject /> }))}
        >
          {t('pages.projects.addProject')}
        </BpButton>
        <TariffButton />
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ProjectModel>}
        pagination={{
          current,
          pageSize,
          total,
        }}
        expandable={{
          expandedRowRender: (record: any) =>
            showExploreDemo && (
              <div className={'relative bg-grey p-[24px] rounded-[12px]'}>
                <IconButton className='absolute top-[24px] right-[24px]' onClick={hideNotify}>
                  <IconCrossBold color='#6684A7' />
                </IconButton>
                <div className={'max-w-[720px] mx-auto'}>
                  <h2 className={'text-center text-[32px] pt-[12px]'}>
                    {t('pages.projects.exploreDemo')}
                  </h2>
                </div>
                <div className={'flex gap-[16px] pt-[16px]'}>
                  <div
                    className={
                      'grid grid-rows-[1fr_min-content] bg-white px-[24px] py-[22px] rounded-[12px] w-full'
                    }
                  >
                    <div className={'min-h-[103px]'}>
                      <p className={'text-aqua-300 text-[13px] uppercase leading-[18px]'}>
                        {t('pages.projects.SERPMonitoring')}
                      </p>
                      <h2 className={'leading-[32px]'}>{t('pages.projects.paidResults')}</h2>
                      <p className={'text-focus-500 text-[15px] mt-[8px]'}>
                        {t('pages.projects.trackPaidAds')}
                      </p>
                    </div>
                    <div className={'pt-[32px]'}>
                      <BpButton
                        className={'uppercase'}
                        iconRight={<IconArrowRight />}
                        onClick={() => navigate(`/ad-reports?project=${record.id}`)}
                      >
                        {t('buttons.goToReport')}
                      </BpButton>
                    </div>
                  </div>
                  <div
                    className={
                      'grid grid-rows-[1fr_min-content] bg-white px-[24px] py-[22px] rounded-[12px] w-full'
                    }
                  >
                    <div className={'min-h-[103px]'}>
                      <p className={'text-aqua-300 text-[13px] uppercase leading-[18px]'}>
                        {t('pages.projects.nonCompliantAffiliates')}
                      </p>
                      <h2 className={'leading-[32px]'}>{t('pages.projects.PPCCompliance')}</h2>
                      <p className={'text-focus-500 text-[15px] mt-[8px]'}>
                        {t('pages.projects.ensureAffiliatesFollow')}
                      </p>
                    </div>
                    <div className={'pt-[32px]'}>
                      <BpButton
                        className={'uppercase'}
                        iconRight={<IconArrowRight />}
                        onClick={() => navigate(`/ppc-compliance?project=${record.id}`)}
                      >
                        {t('buttons.goToReport')}
                      </BpButton>
                    </div>
                  </div>
                  <div
                    className={
                      'grid grid-rows-[1fr_min-content] bg-white px-[24px] py-[22px] rounded-[12px] w-full'
                    }
                  >
                    <div className={'min-h-[103px]'}>
                      <p className={'text-aqua-300 text-[13px] uppercase leading-[18px]'}>
                        {t('pages.projects.SERPMonitoring')}
                      </p>
                      <h2 className={'leading-[32px]'}>{t('pages.projects.organicResults')}</h2>
                      <p className={'text-focus-500 text-[15px] mt-[8px]'}>
                        {t('pages.projects.monitorOrganicRankings')}
                      </p>
                    </div>
                    <div className={'pt-[32px]'}>
                      <BpButton
                        className={'uppercase'}
                        iconRight={<IconArrowRight />}
                        onClick={() => navigate(`/organic-results?project=${record.id}`)}
                      >
                        {t('buttons.goToReport')}
                      </BpButton>
                    </div>
                  </div>
                </div>
              </div>
            ),
          expandedRowKeys: expandedRowKeys,
          showExpandColumn: false,
        }}
      />
    </section>
  )
}
