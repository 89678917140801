import { BpList } from '../../../../../../shared/components/cards/list/BpList'
import { BpListItem } from '../../../../../../shared/components/cards/list/bp-list-item/BpListItem'
import {
  useEditPublisherMutation,
  useGetPublisherQuery,
} from '../../../../../../api/ad-overview/AdOverview.api'
import { Preloader } from '../../../../../../shared/components/cards/preloader/Preloader'
import { BpAnchor } from '../../../../../../shared/components/text/anchor/BpAnchor'
import { EditAffiliateForm, IEditAffiliateFormData } from './EditAffiliateForm'
import { useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useAppDispatch } from '../../../../../../store/hooks/Redux'
import { hideModal } from '../../../../../../store/reducers/AppSlice'
import { BpCard } from '../../../../../../shared/components/cards/card/BpCard'
import { FormControls } from '../../../../../../shared/components/forms/form-controls/FormControls'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'

export interface IEditAffiliateModalParams {
  publisherId: number
}

export const EditAffiliateModal = ({ publisherId: id }: IEditAffiliateModalParams) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data, isLoading, isFetching } = useGetPublisherQuery({ id })
  const [editPublisher] = useEditPublisherMutation()
  const isShowPreloader = isFetching || isLoading
  const ref = useRef<HTMLFormElement>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const onSubmit: SubmitHandler<IEditAffiliateFormData> = (data: IEditAffiliateFormData) =>
    editPublisher({ id, ...data })

  if (data?.data) {
    const { name, updated_at, site, created_at, comment, email, project } = data.data

    const title = (
      <Tooltip title={name}>
        <div className={'overflow-x-hidden truncate cursor-default'}>
          {t('pages.adOverview.publisher')} {name}
        </div>
      </Tooltip>
    )

    const footer = (
      <FormControls
        disabled={!isDirty}
        onCancel={() => dispatch(hideModal())}
        onSubmit={() => {
          if (ref.current) ref.current.dispatchEvent(new Event('submit', { bubbles: true }))
        }}
      />
    )

    return (
      <>
        {isShowPreloader && <Preloader />}
        {!isShowPreloader && (
          <BpCard title={title} footer={footer}>
            <BpList>
              <>
                {created_at && (
                  <BpListItem title={t('pages.adOverview.createdAt')}>{created_at}</BpListItem>
                )}
                {updated_at && (
                  <BpListItem title={t('pages.adOverview.updatedAt')}>{updated_at}</BpListItem>
                )}
                {project && (
                  <BpListItem title={t('pages.adOverview.project')}>{project}</BpListItem>
                )}
                {site && (
                  <BpListItem title={t('pages.adOverview.site')}>
                    <BpAnchor href={`https://${site}`} target='_blank'>
                      {site}
                    </BpAnchor>
                  </BpListItem>
                )}
              </>
            </BpList>
            <EditAffiliateForm
              ref={ref}
              name={name}
              comment={comment}
              email={email}
              onSubmit={onSubmit}
              onDirty={setIsDirty}
            />
          </BpCard>
        )}
      </>
    )
  }

  return <Preloader />
}
