import { setModal } from '../../../../store/reducers/AppSlice'
import { AddProject } from '../../../../pages/main/projects/components/AddProject'
import { IconArrowRight } from '../../icons/IconArrowRight'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { useTranslation } from 'react-i18next'
import { selectTariffDetails } from '../../../../store/reducers/AuthSlice'
import { ViewGuard } from '../../../../guards/ViewGuard'

export const AddProjectButton = () => {
  const { t } = useTranslation()
  const tariffDetails = useAppSelector(selectTariffDetails)
  const dispatch = useAppDispatch()

  return (
    <ViewGuard roles={['admin', 'editor']}>
      <button
        className={`text-default px-[16px] items-center justify-between py-[8px] w-full flex
        ${tariffDetails.tariff?.is_subscription_expired && 'opacity-30 cursor-not-allowed'}
      `}
        type={'button'}
        disabled={tariffDetails.tariff?.is_subscription_expired}
        onClick={() => dispatch(setModal({ children: <AddProject /> }))}
      >
        {t('buttons.createNewProject')}
        <div className={'w-[14px]'}>
          <IconArrowRight color={'#A059FF'} />
        </div>
      </button>
    </ViewGuard>
  )
}
