import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPaperAirplane = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.93313 4.21344L10.5931 2.32677C13.1331 1.4801 14.5131 2.86677 13.6731 5.40677L11.7865 11.0667C10.5198 14.8734 8.4398 14.8734 7.17313 11.0667L6.61312 9.38675L4.93313 8.82675C1.12646 7.56008 1.12646 5.48677 4.93313 4.21344Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.74023 9.10036L9.1269 6.70703'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
