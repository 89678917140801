import { useAppSelector } from '../../../../store/hooks/Redux'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import {
  selectLanguageComplianceFilter,
  selectLanguageCompliancePagination,
  setLanguageCompliancePagination,
} from '../store/LanguageComplianceSlice'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import React, { useEffect, useState } from 'react'
import {
  useGetHistoryQuery,
  useUpdateStateMutation,
} from '../../../../api/language-compliance/LanguageCompliance.api'
import { ColumnsType } from 'antd/lib/table'
import { IconButton } from '../../../../shared/components/buttons/icon-button/IconButton'
import { IconEye } from '../../../../shared/components/icons/IconEye'
import { IconEyeOff } from '../../../../shared/components/icons/IconEyeOff'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { Tooltip } from 'antd'
import { LanguageCompilianceExpandRow } from './LanguageCompilianceExpandRow'
import { IWithExpandedAllProps, withExpanded } from '../../../../shared/hoc/WithExpanded'
import { TriangleButton } from '../../../../shared/components/buttons/triangle/TriangleButton'
import { useDispatch } from 'react-redux'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'
import { IconInfringement } from '../../../../shared/components/icons/IconInfringement'

export const Table = ({ onToggleExpand, onExpandRow, expandedRowKeys }: IWithExpandedAllProps) => {
  const dispatch = useDispatch()
  const filters = useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const { current, pageSize, total } = useAppSelector<IPagination>(
    selectLanguageCompliancePagination,
  )
  const { project, country, url, affiliate, dateRange, restrictedWords, hidden } = filters

  const [queryParams, setQueryParams] = useState<any>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching, refetch } = useGetHistoryQuery(queryParams, {
    skip: !queryParams.project_id || !queryParams.country_id,
    refetchOnMountOrArgChange: true,
  })
  const [updateState] = useUpdateStateMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      date: dateRange,
      project_id: Number(project),
      country_id: Number(country),
      url: url,
      affiliate: affiliate,
      word_id: restrictedWords ? Number(restrictedWords) : undefined,
      show_hidden: hidden,
    })
  }, [filters])

  const columns: ColumnsType<any> = [
    {
      title: 'Page',
      dataIndex: 'url',
      key: 'url',
      render: (_: string, { url }) => {
        return (
          <div className='truncate w-[300px]'>
            <Tooltip title={url}>
              <BpAnchor href={url} target={'_blank'}>
                {url.replace(/^(http:\/\/|https:\/\/)?(www\.)?/, '')}
              </BpAnchor>
            </Tooltip>
          </div>
        )
      },
    },
    {
      title: 'Affiliate',
      dataIndex: 'affiliate',
      key: 'affiliate',
      render: (affiliate: string) => (
        <Tooltip title={affiliate}>
          <div className='truncate max-w-[250px]'>
            {affiliate || <div className='text-[#6684A7]'>Unspecified</div>}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Checks',
      dataIndex: 'check_count',
      key: 'check_count',
    },
    {
      title: 'Last Check',
      dataIndex: 'last_check',
      key: 'last_check',
      width: 200,
      sorter: true,
      render: (lastCheck: string, { is_last_infringement }) => {
        return (
          <div className={'flex gap-[6px] items-center'}>
            <Tooltip
              arrowPointAtCenter
              placement='topLeft'
              title='Last check detected potential policy infringements'
            >
              <div
                className={`w-[16px] h-[16px] -ml-[22px] border-[1px] border-[#FFADC5] rounded-full ${
                  !is_last_infringement && 'invisible'
                }`}
              >
                <IconInfringement color={'#FFADC5'} />
              </div>
            </Tooltip>
            {lastCheck}
          </div>
        )
      },
    },
    {
      dataIndex: 'state',
      key: 'state',
      render: (_: string, { state }) => {
        return (
          state === 'in_progress' && (
            <span
              className={`inline-block
                  font-semibold
                  uppercase
                  text-[10px]
                  leading-5
                  px-2
                  rounded-md
                  bg-[#DC2F02]/[.07]
                  ml-2
                  text-[#DC2F02]/[.75]
                  cursor-default`}
            >
              In progress
            </span>
          )
        )
      },
    },
    {
      key: 'hidden_action',
      render: (_: string, { is_hidden, url }) => {
        return (
          <IconButton
            size='lg'
            onClick={() => {
              if (!project) return
              updateState({
                project_id: Number(project),
                country_id: Number(country),
                urls: [url],
                is_hidden: Number(!is_hidden),
              }).then(() => {
                refetch()
              })
            }}
          >
            {is_hidden ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
          </IconButton>
        )
      },
      className: 'action',
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    dispatch(
      setLanguageCompliancePagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <div className='h-full min-h-[400px]'>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => record.url}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<any>}
        rowClassName={(e) => (e.is_hidden ? 'disabled' : '')}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <IconButton onClick={(e) => onExpand(record, e)}>
              <TriangleButton color='#A059FF' position={expanded ? 'top' : 'down'} />
            </IconButton>
          ),
          expandedRowRender: (record: any) => <LanguageCompilianceExpandRow record={record} />,
          onExpand: (expanded, { url }) => {
            if (onToggleExpand) onToggleExpand(expanded, url)
          },
          expandedRowKeys: expandedRowKeys,
        }}
      />
    </div>
  )
}

export const LanguageComplianceAllChecks = withExpanded(Table)
