import { IconArrowRight } from '../../icons/IconArrowRight'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ViewGuard } from '../../../../guards/ViewGuard'

export const EditCountriesButton = ({
  project,
}: {
  project: { value?: string | number; isDemo?: boolean }
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!String(project.value)?.length || project.isDemo) {
    return null
  }

  return (
    <ViewGuard roles={['admin', 'editor']}>
      <button
        className={'text-default px-[16px] items-center justify-between py-[8px] w-full flex'}
        type={'button'}
        onClick={() => navigate(`/projects/${project.value}/locations`)}
      >
        {t('buttons.editCountries')}
        <div className={'w-[14px]'}>
          <IconArrowRight color={'#A059FF'} />
        </div>
      </button>
    </ViewGuard>
  )
}
