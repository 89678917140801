import { useAppSelector } from '../../store/hooks/Redux'
import { useEffect } from 'react'
import { useLazyLogoutQuery } from '../../api/site/Site.api'

export const useAuth = () => {
  const [triggerLogout] = useLazyLogoutQuery()

  const authenticated = useAppSelector((state) => state.root.auth.authenticated)

  useEffect(() => {
    if (authenticated !== null && !authenticated) {
      window.location.reload()
    }
  }, [authenticated])

  const logout = () => {
    triggerLogout().then(() => {
      window.location.reload()
    })
  }

  return { logout }
}
