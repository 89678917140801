import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import { RootState } from '../../../../store/Store'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

type PpcComplianceState = {
  filters: IPpcComplianceFilter
  pagination: IPagination
  counter?: { [key: number]: string }
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
}

const dateRanges = useDateRanges()

const ppcComplianceSlice = createSlice({
  name: 'ppcCompliance',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      adHijackers: false,
      hidden: false,
      site: '',
      status: 1,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
  } as PpcComplianceState,
  reducers: {
    setPpcComplianceFilter: (
      state: PpcComplianceState,
      { payload }: PayloadAction<IPpcComplianceFilter>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setPpcComplianceStatus: (state: PpcComplianceState, { payload }: PayloadAction<number>) => {
      state.filters = { ...state.filters, status: payload }
    },
    setPpcCompliancePagination: (state: PpcComplianceState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
    setPpcComplianceCounter: (
      state: PpcComplianceState,
      action: PayloadAction<{ [key: number]: string } | undefined>,
    ) => {
      state.counter = action.payload
    },
    setProject: (
      state: PpcComplianceState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
  },
})

export const {
  setPpcComplianceFilter,
  setPpcCompliancePagination,
  setPpcComplianceStatus,
  setPpcComplianceCounter,
  setProject,
} = ppcComplianceSlice.actions

export const selectPpcComplianceFilter = (state: RootState) => state.root.ppcCompliance.filters
export const selectPpcCompliancePagination = (state: RootState) =>
  state.root.ppcCompliance.pagination

export const selectPpcComplianceCounter = (state: RootState) => state.root.ppcCompliance.counter
export const selectProject = (state: RootState) => state.root.ppcCompliance.project

export default ppcComplianceSlice.reducer
