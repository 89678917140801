import { CommonMainPage } from '../../../utility/CommonMainPage'
import { BpTabs } from '../../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../../shared/components/utility/tabs/tab/BpTab'
import {
  useGetInfoQuery,
  useGetLocationsQuery,
  useGetTermsQuery,
  useUpdateLocationsMutation,
  useUpdateTermsMutation,
} from '../../../../../api/project/Project.api'
import { unstable_useBlocker as useBlocker, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { ProjectMainSettings } from '../ProjectMainSettings'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { setModal } from '../../../../../store/reducers/AppSlice'
import { ProjectLocations } from '../ProjectLocations'
import { ProjectEditName } from '../ProjectEditName'
import { ProjectTerms } from '../ProjectTerms'
import { useTranslation } from 'react-i18next'
import { IconArrowBack } from '../../../../../shared/components/icons/IconArrowBack'
import { IconPencil } from '../../../../../shared/components/icons/IconPencil'
import { TariffButton } from '../../../../../shared/components/buttons/tariff-button/TariffButton'
import {
  selectProjectLocations,
  selectProjectLocationsChanged,
  selectProjectTerms,
  selectProjectTermsChanged,
  setProjectLocations,
  setProjectTerms,
} from '../../store/ProjectsSlice'
import { UnsavedChangesModal } from '../UnsavedChangesModal'
import { ITerm } from '../../interfaces/Term.interface'
import { MissingKeywordsModal } from '../MissingKeywordsModal'
import { setTariffUpdated } from '../../../../../store/reducers/AuthSlice'

export const EditProjectsPage = () => {
  const { t } = useTranslation()
  const { id, tab } = useParams()
  const navigate = useNavigate()
  const locationsChanged = useAppSelector(selectProjectLocationsChanged)
  const locationsList = useAppSelector(selectProjectLocations)
  const termsChanged = useAppSelector(selectProjectTermsChanged)
  const termsList = useAppSelector(selectProjectTerms)
  const [temsInit, setTermsInit] = useState(false)
  const dispatch = useAppDispatch()
  const blocker = useBlocker(termsChanged || locationsChanged || false)

  const { data, isError } = useGetInfoQuery(
    { id: id || '' },
    {
      selectFromResult: (result) => {
        const item = result.data?.data?.items[0]

        return {
          ...result,
          data: item,
          isError: result.data && 'errors' in result.data,
        }
      },
      skip: !id,
    },
  )

  const [updateLocations, { isLoading: updateLocationsLoading }] = useUpdateLocationsMutation()
  const [updateTerms, { isLoading: updateTermsLoading }] = useUpdateTermsMutation()

  const {
    data: locations,
    isSuccess: locationsIsSuccess,
    isFetching: locationsIsFetching,
  } = useGetLocationsQuery({ project_id: id || '' }, { skip: !id })

  const {
    data: terms,
    isSuccess: termsIsSuccess,
    isFetching: termsIsFetching,
  } = useGetTermsQuery({ project_id: id || '' }, { skip: !id })

  const saveLocations = async (checkTerms = false) => {
    updateLocations({ id, data: locationsList }).then((response) => {
      if ('error' in response || !response.data.success) {
        return
      } else {
        dispatch(setProjectLocations(response.data.data.items))
        setTermsInit(checkTerms)
        dispatch(setTariffUpdated())

        if (!checkTerms) {
          navigate(`/projects/${id}/keywords`)
        }
      }
    })
  }

  const saveTerms = () => {
    const data = termsList?.map((term: { terms: any[] }) => ({
      ...term,
      terms: term.terms.filter((item: string) => item !== ''),
    }))

    updateTerms({ id, data }).then(() => {
      dispatch(setTariffUpdated())
    })
  }

  useEffect(() => {
    if (locationsIsSuccess && locations?.data?.items) {
      dispatch(setProjectLocations(locations?.data.items))
    }
  }, [locationsIsFetching, navigate])

  useEffect(() => {
    if (termsIsSuccess && terms?.data?.items) {
      dispatch(setProjectTerms(terms?.data.items))
    }
  }, [termsIsFetching, navigate])

  useEffect(() => {
    if (termsIsFetching) return

    if (
      temsInit &&
      tab === 'locations' &&
      terms?.data.items?.some((item: ITerm) => !item.terms?.length)
    ) {
      dispatch(
        setModal({
          children: <MissingKeywordsModal add={() => navigate(`/projects/${id}/keywords`)} />,
          size: 'md',
        }),
      )
    }

    setTermsInit(true)
  }, [termsIsFetching])

  useEffect(() => {
    if (termsChanged || locationsChanged) {
      setTimeout(() =>
        dispatch(
          setModal({
            children: (
              <UnsavedChangesModal
                save={() => {
                  if (tab === 'keywords') saveTerms()
                  if (tab === 'locations') saveLocations(true)
                }}
                leave={() => {
                  if (tab === 'keywords') dispatch(setProjectTerms(terms?.data.items))
                  if (tab === 'locations') dispatch(setProjectLocations(locations?.data.items))

                  setTimeout(() => navigate(blocker.location || '', { replace: true }))
                }}
              />
            ),
            size: 'md',
          }),
        ),
      )
    }
  }, [blocker])

  useEffect(() => {
    if (isError) {
      navigate('/404')
    }
  }, [isError])

  return (
    <CommonMainPage>
      <div className={'flex mb-[12px] min-h-[36px] gap-[16px] items-center'}>
        <IconButton size='lg' onClick={() => navigate(-1)}>
          <IconArrowBack color='#00326D' />
        </IconButton>
        <h2>{data?.name}</h2>
        <IconButton
          className={data?.name ? 'visible' : 'hidden'}
          size={'lg'}
          onClick={() =>
            dispatch(setModal({ children: <ProjectEditName id={id} name={data?.name} /> }))
          }
        >
          <IconPencil color='#00326D' />
        </IconButton>
        <TariffButton />
      </div>
      <BpTabs
        size={'md'}
        variant={'row'}
        className={'px-[16px] xl:px-[32px] -mx-[16px] xl:-mx-[32px]'}
        active={tab}
        onChange={(activeTab) => {
          navigate(`/projects/${id}/${activeTab}`, { replace: true })
        }}
      >
        <BpTab activeKey={'general'} title={t('pages.project.general')}>
          <ProjectMainSettings />
        </BpTab>
        <BpTab activeKey={'locations'} title={t('pages.project.locations')}>
          <ProjectLocations
            data={locations}
            submit={saveLocations}
            loading={updateLocationsLoading}
          />
        </BpTab>
        <BpTab activeKey={'keywords'} title={t('pages.project.searchTerms')}>
          <ProjectTerms data={terms} loading={updateTermsLoading} />
        </BpTab>
      </BpTabs>
    </CommonMainPage>
  )
}
