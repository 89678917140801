import { PropsWithChildren } from 'react'
import { useAppSelector } from '../store/hooks/Redux'
import { selectSubRole } from '../store/reducers/AuthSlice'
import { Navigate } from 'react-router-dom'

interface ViewGuardProps extends PropsWithChildren {
  roles: ('admin' | 'editor' | 'user')[]
}

export const PageGuard = ({ children, roles }: ViewGuardProps) => {
  const role = useAppSelector(selectSubRole)

  return roles.includes(role) ? <>{children}</> : <Navigate to={'/ad-reports'} />
}
