import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { URL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { FormHTMLAttributes } from 'react'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import {
  useGetAssistanceRequestQuery,
  useGetCompanyRoleQuery,
} from '../../../../api/filter/Filter.api'
import { useTranslation } from 'react-i18next'
import { BpTextArea } from '../../../../shared/components/forms/textarea/BpTextArea'

export interface ICompanySettingsFormData {
  companyName: string
  companyWebsite: string
  companyRole: string
  assistanceRequest: string[]
  other: string
}

export interface ICompanySettingsFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ICompanySettingsFormData) => void
  isFetching?: boolean
}

export const CompanySettingsForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ICompanySettingsFormProps) => {
  const { t } = useTranslation()
  const { data: companyRole } = useGetCompanyRoleQuery()
  const { data: assistanceRequest } = useGetAssistanceRequestQuery()

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<ICompanySettingsFormData>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      companyWebsite: '',
      companyRole: '',
      assistanceRequest: [],
      other: '',
    },
  })

  const formValues = watch()

  return (
    <>
      <h2 className='mb-[24px] leading-[32px]'>{t('pages.auth.createYourAccount')}</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ICompanySettingsFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.companyName')}
          required
          error={errors.companyName?.message}
          {...register('companyName', {
            required: t('fields.validate.companyNameRequired'),
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.companyWebsite')}
          required
          error={errors.companyWebsite?.message}
          {...register('companyWebsite', {
            required: t('fields.validate.companyWebsiteRequired'),
            pattern: {
              value: URL_REGEX,
              message: t('fields.validate.invalidWebsiteAddress'),
            },
          })}
        ></BpInput>
        <BpSelect
          label={t('pages.auth.companyRole')}
          options={companyRole}
          {...register('companyRole', {
            required: t('fields.validate.required'),
          })}
          required
        />
        <BpSelect
          label={t('pages.auth.whatCanHelp')}
          options={assistanceRequest}
          height={150}
          multiple
          {...register('assistanceRequest', {
            required: t('fields.validate.required'),
          })}
          required
        />
        {formValues.assistanceRequest.includes('other') && (
          <BpTextArea
            label={t('pages.auth.tellYourInterest')}
            error={errors.other?.message}
            {...register('other', {
              onChange: (e) => {
                setValue('other', e.target.value.trimStart())
              },
              required: t('fields.validate.required'),
              minLength: {
                value: 3,
                message: t('fields.validate.leastCountCharacters', {
                  count: 3,
                }),
              },
              maxLength: {
                value: 200,
                message: t('fields.validate.underCountCharacters', {
                  count: 200,
                }),
              },
            })}
            required
          />
        )}
        <div className={'pt-[24px] pb-[64px]'}>
          <BpButton className={'uppercase w-full'} type='submit' disabled={!isValid} size='lg'>
            {t('pages.auth.buttons.startTrial')}
          </BpButton>
        </div>
      </form>
    </>
  )
}
