export function objectToQuery(obj: { [key: string]: string | boolean | undefined }): string {
  const result = []

  for (const key in obj) {
    const param = obj[key]

    result.push(`${key}=${encodeURIComponent(param || '')}`)
  }

  return result.join('&')
}
