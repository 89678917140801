import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResearchFilter } from '../interfaces/ResearchFilter.interface'
import { RootState } from '../../../../store/Store'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

export type TResearchTabs = 'Competitors' | 'Keywords'

type ResearchState = {
  filters: IResearchFilter
  activeTab: TResearchTabs
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
}

const researchSlice = createSlice({
  name: 'research',
  initialState: {
    filters: {
      country: [],
      domain: '',
    },
    activeTab: 'Competitors',
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
  } as unknown as ResearchState,
  reducers: {
    setResearchFilter: (state: ResearchState, action: PayloadAction<IResearchFilter>) => {
      state.filters = action.payload
    },
    setResearchActiveTab: (state: ResearchState, action: PayloadAction<TResearchTabs>) => {
      state.activeTab = action.payload
    },
    setProject: (
      state: ResearchState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
  },
})

export const { setResearchFilter, setResearchActiveTab, setProject } = researchSlice.actions

export const selectResearchFilters = (state: RootState) => state.root.research.filters
export const selectResearchActiveTab = (state: RootState) => state.root.research.activeTab
export const selectProject = (state: RootState) => state.root.research.project

export default researchSlice.reducer
