import { ByVisibilityGraph } from './by-visibility/ByVisibilityGraph'
import { CompetitorsReportTable } from './report/CompetitorsReportTable'

export const Competitors = () => {
  return (
    <section className='h-full grid gap-[50px] grid-rows-[min-content_1fr]'>
      <ByVisibilityGraph />
      <CompetitorsReportTable />
    </section>
  )
}
