import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../Store'
import { IBpAlertProps } from '../../shared/components/cards/alert/BpAlert'
import { ReactNode } from 'react'
import { Location } from 'react-router-dom'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'

export type TSizeModal = 'md' | 'lg' | 'xl'

type AppState = {
  alerts: IBpAlertProps[]
  canCloseDropdown: boolean
  modal?: {
    id?: string
    size?: TSizeModal
    width?: number
    scroll?: boolean
    children: ReactNode
  }
  location: {
    current?: Location
    previous?: Location
  }
  demoProject?: IOption
  showGuide?: Date
}

const appSlice = createSlice({
  name: 'app',
  initialState: { alerts: [], canCloseDropdown: true, location: {} } as AppState,
  reducers: {
    showAlert: (state: AppState, { payload }: PayloadAction<IBpAlertProps>) => {
      if (state.alerts.find((alert) => alert.message == payload.message)) return
      state.alerts?.push(payload)
    },
    hideAlert: (state: AppState, { payload }: PayloadAction<string>) => {
      state.alerts = []
    },
    setCanCloseDropdown: (state: AppState, action: PayloadAction<boolean>) => {
      state.canCloseDropdown = action.payload
    },
    setModal: (
      state: AppState,
      action: PayloadAction<{
        id?: string
        size?: TSizeModal
        width?: number
        scroll?: boolean
        children: ReactNode
      }>,
    ) => {
      state.modal = action.payload
    },
    hideModal: (state: AppState) => {
      state.modal = undefined
    },
    setLocation: (state: AppState, action: PayloadAction<Location>) => {
      if (state.location.current?.pathname !== action.payload.pathname) {
        state.location.previous = state.location.current
      }

      state.location.current = action.payload
    },
    setDemoProject: (state: AppState, action: PayloadAction<IOption | undefined>) => {
      state.demoProject = action.payload
    },
    setShowGuide: (state: AppState) => {
      state.showGuide = new Date()
    },
  },
})

export const {
  showAlert,
  hideAlert,
  setCanCloseDropdown,
  setModal,
  hideModal,
  setLocation,
  setDemoProject,
  setShowGuide,
} = appSlice.actions

export const selectAlerts = (state: RootState) => state.root.app.alerts
export const selectModal = (state: RootState) => state.root.app.modal
export const selectCanCloseDropdown = (state: RootState) => state.root.app.canCloseDropdown
export const selectPreviousLocation = (state: RootState) => state.root.app.location.previous
export const selectDemoProject = (state: RootState) => state.root.app.demoProject
export const selectShowGuide = (state: RootState) => state.root.app.showGuide

export default appSlice.reducer
