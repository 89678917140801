import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { IPositionTrackerFilter } from '../../interfaces/IPositionTrackerFilter'
import {
  changeChartIds,
  selectPositionTrackerChart,
  selectPositionTrackerFilters,
  setSerpPresence,
} from '../../store/PositionTrackerSlice'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { Switch, Tooltip } from 'antd'
import {
  useExportSeoPositionMutation,
  useGetSeoPositionQuery,
} from '../../../../../api/research/Research.api'
import { ISeoPositionParams } from '../../../../../api/research/interfaces/SeoPositionParams.interface'
import { ISeoPositionModel } from '../../../../../api/research/models/SeoPosition.model'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { IPositionTrackerChart } from '../../interfaces/IPositionTrackerChart'
import { PositionCell } from '../../../../../shared/components/cells/PositionCell'
import { useTranslation } from 'react-i18next'

const MAX_CHECKED_ROWS = 5

export const PositionTrackerTable = () => {
  const { t } = useTranslation()
  const [countRows, setCountRows] = useState<number>(0)
  const positionTrackerFilter = useAppSelector<IPositionTrackerFilter>(selectPositionTrackerFilters)
  const { chartIds } = useAppSelector<IPositionTrackerChart>(selectPositionTrackerChart)
  const { date, project, engine_id, country, site, keyword, no_serp_presence, landing } =
    positionTrackerFilter

  const [queryParams, setQueryParams] = useState<ISeoPositionParams>()
  const { data, isLoading, isFetching } = useGetSeoPositionQuery(
    queryParams as ISeoPositionParams,
    {
      skip: !project || queryParams == undefined,
    },
  )
  const [exportPositionTracker, { isLoading: exportLoading }] = useExportSeoPositionMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      date,
      project_id: +project,
      engine_id: +engine_id,
      country_id: country ? +country : undefined,
      own_domain: site,
      keyword,
      no_serp_presence,
      landing,
    })
  }, [positionTrackerFilter])

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)

    dispatch(changeChartIds(data.data.items.slice(0, 5).map((item) => item.keyword_id)))
  }, [data])

  const columns: ColumnsType<ISeoPositionModel> = [
    {
      title: t('table.columns.keyword'),
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: t('table.columns.page'),
      dataIndex: 'page',
      key: 'page',
      render: (_: string, { page }: ISeoPositionModel) => {
        if (!_)
          return (
            <span
              className={`table
                uppercase
                text-[10px]
                font-semibold
                text-danger
                bg-danger-300
                leading-5
                px-2
                rounded-md`}
            >
              not found
            </span>
          )

        return (
          <div className='w-96 overflow-hidden text-ellipsis'>
            <BpAnchor
              className='flex-1 leading-5 whitespace-nowrap text-ellipsis'
              target='_blank'
              href={page}
            >
              <Tooltip title={page.split('?')[0]}>{page.split('?')[0]}</Tooltip>
            </BpAnchor>
          </div>
        )
      },
    },
    {
      title: t('table.columns.lastPosition'),
      dataIndex: 'position',
      key: 'position',
      render: (_: string, { position, previous_position }: ISeoPositionModel) => {
        if (!_ && !previous_position) {
          return (
            <div className='flex h-6 items-center'>
              <span className='block w-2 h-2 bg-[#D9D9D9] rounded-full'></span>
            </div>
          )
        }

        if (!_ && previous_position) {
          return <span className={'text-danger'}>Lost</span>
        }

        const current = +position.toFixed(1)
        const previous = previous_position !== null && +previous_position.toFixed(1)

        return <PositionCell current={current} previous={previous} />
      },
      sorter: true,
    },
    {
      title: t('table.columns.SERP'),
      dataIndex: 'search_screen_url',
      key: 'search_screen_url',
      render: (_: string, { search_screen_url }: ISeoPositionModel) => (
        <BpAnchor href={search_screen_url} target='_blank'>
          {t('buttons.viewScreenshot')}
        </BpAnchor>
      ),
    },
    {
      title: t('table.columns.lastUpdated'),
      dataIndex: 'last_updated',
      key: 'last_updated',
      width: '150px',
      sorter: true,
    },
  ]

  const rowSelection: TableRowSelection<ISeoPositionModel> = {
    selectedRowKeys: chartIds,
    hideSelectAll: true,
    onSelect: (record, selected) => {
      if (selected) {
        !chartIds?.includes(record.keyword_id) &&
          dispatch(
            changeChartIds(chartIds ? [...chartIds, record.keyword_id] : [record.keyword_id]),
          )
      } else {
        dispatch(changeChartIds(chartIds?.filter((row) => row !== record.keyword_id)))
      }
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          (chartIds &&
            !chartIds?.includes(record.keyword_id) &&
            chartIds?.length >= MAX_CHECKED_ROWS) ||
          (chartIds?.length === 1 && chartIds?.includes(record.keyword_id)),
      }
    },
  }

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<ISeoPositionModel> | SorterResult<ISeoPositionModel>[],
  ) => {
    if (queryParams) {
      setQueryParams({
        ...queryParams,
        page: current,
        'per-page': pageSize,
        sort: useSorter(sorter),
      })
    }
  }

  return (
    <section className={'h-full grid grid-rows-[min-content_1fr]'}>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='flex gap-3 items-center font-medium'>
          {t('pages.positionTracker.allKeywords')}: {countRows}
        </h3>
        <span className='flex items-center gap-[9px] ml-auto mr-6 text-focus-500 text-[15px] leading-[24px]'>
          {t('fields.noSERPPresence')}
          <Switch
            checked={queryParams?.no_serp_presence}
            onClick={() => dispatch(setSerpPresence(!queryParams?.no_serp_presence))}
          ></Switch>
        </span>
        <span className='flex gap-[9px] items-center'>
          <ExportDrop
            onExport={(exportParams) => exportPositionTracker({ ...queryParams, ...exportParams })}
            loading={exportLoading}
            columns={[
              { keys: ['keyword'], label: t('table.columns.keyword') },
              { keys: ['page'], label: t('table.columns.page') },
              { keys: ['position'], label: t('table.columns.lastPosition') },
              { keys: ['previous_position'], label: t('table.columns.previousPosition') },
              { keys: ['search_screen_url'], label: t('table.columns.SERP') },
              { keys: ['last_updated'], label: t('table.columns.lastUpdated') },
            ]}
          />
        </span>
      </div>
      <BpTable
        rowSelection={rowSelection}
        dataSource={data?.data?.items}
        rowKey={(record) => record.keyword_id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ISeoPositionModel>}
      />
    </section>
  )
}
