import { BpCardCarousel } from '../../../../../shared/components/cards/carousel/BpCardCarousel'
import { SwiperSlide } from 'swiper/react'
import { BpArticle } from '../../../../../shared/components/cards/article/BpArticle'
import { SimpleList } from '../../../../../shared/components/cards/simple-list/SimpleList'
import { SimpleListItem } from '../../../../../shared/components/cards/simple-list/simple-list-item/SimpleListItem'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { IconLink } from '../../../../../shared/components/icons/IconLink'
import React from 'react'
import { IAdvertiserModel } from '../../../../../api/advertiser-report/models/Advertiser.model'
import {
  initialAdOverviewFilters,
  setAdOverviewFilter,
} from '../../../ad-overview/store/AdOverviewSlice'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IAdvertiserReportFilter } from '../../interfaces/IAdvertiserReportFilter'
import { selectAdvertiserReportFilters } from '../../store/AdvertiserReportSlice'
import { useTranslation } from 'react-i18next'

export const AdvertiserExpandRow = ({
  ads,
  keywords,
  affiliates,
  advertiser: adDomain,
}: IAdvertiserModel) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const advertiserFilter = useAppSelector<IAdvertiserReportFilter>(selectAdvertiserReportFilters)

  const mapToArrayArrays = (arr: string[] | object, partSize = 10): string[][] => {
    const mapArr: string[] = !Array.isArray(arr) ? Object.values(arr) : arr
    const mappedArray: string[][] = []
    let i = 0
    while (i < mapArr.length) {
      mappedArray.push([...mapArr.slice(i, i + partSize)])
      i = i + partSize
    }
    return mappedArray
  }

  const handleLinkToOverview = () => {
    navigate(`/adv-overview${search}&adDomain=${adDomain}`)
    dispatch(
      setAdOverviewFilter({
        ...initialAdOverviewFilters,
        ...advertiserFilter,
      }),
    )
  }
  return (
    <div className='flex flex-col items-center gap-[16px]'>
      <div className='flex justify-between gap-[20px] w-full'>
        <BpCardCarousel className='max-w-[408px] w-full' title={t('pages.paidResults.lastAds')}>
          {ads.map(({ title, text, url }, index) => (
            <SwiperSlide key={title + index}>
              <div className='rounded-[2px] p-[16px] bg-focus-600/[0.01] border border-focus-600/[0.15] border-dashed'>
                <BpArticle title={title} text={text} link={url} />
              </div>
            </SwiperSlide>
          ))}
        </BpCardCarousel>
        <BpCardCarousel className='max-w-[408px] w-full' title={t('pages.paidResults.allKeywords')}>
          {mapToArrayArrays(keywords).map((arrKeywords, index) => (
            <SwiperSlide key={index}>
              <SimpleList>
                {arrKeywords.map((keyword) => (
                  <SimpleListItem key={keyword}>{keyword}</SimpleListItem>
                ))}
              </SimpleList>
            </SwiperSlide>
          ))}
        </BpCardCarousel>
        <BpCardCarousel
          className='max-w-[408px] w-full'
          title={t('pages.paidResults.allAffiliates')}
        >
          {mapToArrayArrays(affiliates).map((arrAffiliates, index) => (
            <SwiperSlide key={index}>
              <SimpleList>
                {arrAffiliates.map((affiliate) => (
                  <SimpleListItem key={affiliate}>{affiliate}</SimpleListItem>
                ))}
              </SimpleList>
            </SwiperSlide>
          ))}
        </BpCardCarousel>
      </div>
      <Link
        className={'select-none'}
        to={`/adv-overview${search}&adDomain=${adDomain}`}
        onClick={(e) => e.preventDefault()}
      >
        <BpButton className={'uppercase'} onClick={handleLinkToOverview} icon={<IconLink />}>
          {t('buttons.adOverview')}
        </BpButton>
      </Link>
    </div>
  )
}
