import { CommonMainPage } from '../utility/CommonMainPage'
import { PositionTrackerFilters } from './components/filter/PositionTrackerFilters'
import { PositionTrackerChart } from './components/report/PositionTrackerChart'
import { PositionTrackerTable } from './components/report/PositionTrackerTable'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectProject } from './store/PositionTrackerSlice'
import { ProjectStub } from '../../../shared/components/utility/project-stub/ProjectStub'

export const PositionTrackerPage = () => {
  const { has_seo_links: hasSeoLinks } = useAppSelector(selectProject)

  return (
    <CommonMainPage>
      <section className='h-full grid gap-[50px] grid-rows-[min-content_min-content_1fr]'>
        <PositionTrackerFilters />
        {hasSeoLinks ? (
          <>
            <PositionTrackerChart />
            <PositionTrackerTable />
          </>
        ) : (
          <ProjectStub />
        )}
      </section>
    </CommonMainPage>
  )
}
