import React, { HTMLAttributes, ReactElement, ReactNode, useEffect, useState } from 'react'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { Square } from '../../utility/square/Square'
import { BpButton } from '../../buttons/bp-button/BpButton'

export interface ICardDropParams extends IWithDropdownProps {
  title?: string
  trigger?: ReactElement
  offsetTop?: number
  position?: 'right' | 'left'
  children?: ReactNode | undefined
  className?: string
  opened?: boolean
}

const Drop = ({
  title,
  trigger,
  position = 'right',
  children,
  className,
  opened,
  open,
  onToggle,
}: ICardDropParams & HTMLAttributes<HTMLElement>) => {
  const [isHidden, setIsHidden] = useState(!open)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  useEffect(() => {
    opened && onToggle && onToggle()
  }, [opened])

  return (
    <div className='relative table select-none w-full'>
      {trigger ? (
        <button onClick={onToggle}>{trigger}</button>
      ) : (
        <BpButton
          onClick={onToggle}
          theme={'transparent'}
          size={'sm'}
          type={'button'}
          active={open}
          contentClassName={'!text-white !font-normal'}
        >
          {title}
        </BpButton>
      )}
      <div
        className={`transition-opacity
            duration-150
            absolute
            top-[35px]
            ${position === 'right' ? 'right-0' : 'left-0'}
            ${open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
            ${isHidden && 'invisible'} z-50
            ${className}
        `}
      >
        <Square>{children}</Square>
      </div>
    </div>
  )
}

export const CardDrop = withDropdown(Drop)
