import { AdvertiserReportFilters } from './components/filters/AdvertiserReportFilters'
import { CommonMainPage } from '../utility/CommonMainPage'
import { AdvertiserReportTable } from './components/report/AdvertiserReportTable'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectProject } from './store/AdvertiserReportSlice'
import { ProjectStub } from '../../../shared/components/utility/project-stub/ProjectStub'

export const AdvertiserReportPage = () => {
  const { has_paid_links: hasPaidLinks } = useAppSelector(selectProject)

  return (
    <CommonMainPage>
      <section className='h-full min-h-[950px] lg:min-h-[750px] grid grid-rows-[min-content_1fr]'>
        <AdvertiserReportFilters />
        {hasPaidLinks ? (
          <AdvertiserReportTable />
        ) : (
          <div className={'pt-[50px]'}>
            <ProjectStub />
          </div>
        )}
      </section>
    </CommonMainPage>
  )
}
