import React, { Fragment, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { BpButton } from '../../buttons/bp-button/BpButton'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { IconUpload } from '../../icons/IconUpload'
import { Square } from '../../utility/square/Square'
import { BpCard } from '../card/BpCard'
import { Switch } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { ICommonExport } from '../../../interfaces/CommonExport.interface'
import { EExportType } from '../../../../api/research/enums/ExportType.enum'
import { BpTooltip } from '../tooltip/BpTooltip'
import { useTranslation } from 'react-i18next'

interface IExportFormData {
  [k: string]: boolean
}

export interface IColumnExport {
  keys?: string[]
  label: string
  tooltip?: string
  bold?: boolean
  child?: { keys?: string[]; label: string; tooltip?: string; bold?: boolean }[]
}

export interface IFilterDropParams extends IWithDropdownProps {
  loading?: boolean
  className?: string
  onExport?: (e: ICommonExport) => void
  columns?: IColumnExport[]
  onToggle?: () => void
}

const Drop = ({
  open,
  onToggle,
  loading,
  className,
  columns,
  onExport,
}: IFilterDropParams & HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropRef = useRef<HTMLDivElement>(null)
  const [isHidden, setIsHidden] = useState(!open)
  const { handleSubmit, control } = useForm<IExportFormData>({
    mode: 'onChange',
  })

  useEffect(() => {
    if (!buttonRef.current || !dropRef.current) return
    setDropPosition(buttonRef.current, dropRef.current)

    const handleEvent = () => {
      if (!buttonRef.current || !dropRef.current) return
      setDropPosition(buttonRef.current, dropRef.current)
    }

    window.addEventListener('resize', handleEvent)
    window.addEventListener('scroll', handleEvent)
    return () => {
      window.removeEventListener('resize', handleEvent)
      window.removeEventListener('scroll', handleEvent)
    }
  }, [])

  const [exportType, setExportType] = useState<EExportType>(EExportType.XLSX)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  const onSubmit = (data: IExportFormData) => {
    let columns: string[] = []

    for (const i in data) {
      if (data[i]) {
        columns = [...columns, ...i.split(',').map((i) => i.trim())]
      }
    }

    if (onExport)
      onExport({
        export: exportType,
        'exportColumns[]': columns,
      })
  }

  const setDropPosition = useCallback((btn: HTMLButtonElement, drop: HTMLDivElement) => {
    const defaultWidth = 639
    const isSticky = window.innerWidth > defaultWidth
    const width = isSticky ? '320px' : 'calc(100vw - 30px)'
    const { top, left } = btn.getBoundingClientRect()
    const { style } = drop
    style.width = width
    style.left = isSticky ? left - 230 + 'px' : '15px'
    style.top = 35 + top + 'px'
  }, [])

  const footer = (
    <div className='flex w-full justify-between gap-[16px]'>
      <BpButton
        disabled={loading}
        loading={loading && exportType === EExportType.XLSX}
        theme='outline'
        onClick={() => setExportType(EExportType.XLSX)}
      >
        {t('buttons.exportToXLSX')}
      </BpButton>
      <BpButton
        disabled={loading}
        loading={loading && exportType === EExportType.CSV}
        theme='outline'
        onClick={() => setExportType(EExportType.CSV)}
      >
        {t('buttons.exportToCSV')}
      </BpButton>
    </div>
  )

  return (
    <div className={`select-none ${className}`}>
      <BpButton
        ref={buttonRef}
        size='sm'
        theme='outline'
        iconLeft={<IconUpload />}
        disabled={loading}
        onClick={onToggle}
        active={open}
        type='button'
        className='w-[90px]'
      >
        {t('buttons.export')}
      </BpButton>
      <div
        ref={dropRef}
        className={`
          transition-opacity 
          duration-150 
          fixed 
          z-50 
          max-w-[320px]
          ${open ? 'opacity-100' : 'opacity-0'} 
          ${isHidden && 'invisible'} 
        `}
      >
        <Square>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BpCard footer={footer}>
              {columns?.map(({ keys, label, tooltip, bold, child }) =>
                keys ? (
                  <Controller
                    key={label}
                    name={keys.join(', ')}
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <div className='flex justify-between gap-[15px]'>
                        <span
                          className={`flex items-center gap-2 text-focus-600 text-[15px] leading-[24px] ${
                            bold && 'font-medium'
                          }`}
                        >
                          {label} {tooltip && <BpTooltip title={tooltip} />}
                        </span>
                        <Switch {...field} checked={field.value}></Switch>
                      </div>
                    )}
                  />
                ) : (
                  <Fragment key={label}>
                    {label && (
                      <span
                        className={`flex items-center gap-2 text-focus-600 text-[15px] leading-[24px] ${
                          bold && 'font-medium'
                        }`}
                      >
                        {label} {tooltip && <BpTooltip title={tooltip} />}
                      </span>
                    )}
                    {child?.map(
                      ({ keys, label, tooltip, bold }) =>
                        keys && (
                          <Controller
                            key={label}
                            name={keys.join(', ')}
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                              <div className='flex justify-between gap-[15px]'>
                                <span
                                  className={`flex items-center gap-2 text-focus-600 text-[15px] leading-[24px] ${
                                    bold && 'font-medium'
                                  }`}
                                >
                                  {label} {tooltip && <BpTooltip title={tooltip} />}
                                </span>
                                <Switch {...field} checked={field.value}></Switch>
                              </div>
                            )}
                          />
                        ),
                    )}
                  </Fragment>
                ),
              )}
            </BpCard>
          </form>
        </Square>
      </div>
    </div>
  )
}

export const ExportDrop = withDropdown(Drop)
