import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPositionTrackerFilter } from '../interfaces/IPositionTrackerFilter'
import { RootState } from '../../../../store/Store'
import { IDateRange } from '../../../../shared/interfaces/DateRange.interface'
import { IPositionTrackerChart } from '../interfaces/IPositionTrackerChart'
import { useDateRange } from '../../../../shared/hooks/UseDateRange'
import { EDateRangeType } from '../../research-tools/enums/DateRangeType'
import { EDateGroup } from '../../../../api/research/enums/DateGroup.enum'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

type PositionTrackerState = {
  filters: IPositionTrackerFilter
  chart: IPositionTrackerChart
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
}

const dateRange = useDateRange(EDateRangeType.MONTH)

const positionTrackerSlice = createSlice({
  name: 'organic',
  initialState: {
    filters: {
      keyword: '',
      no_serp_presence: false,
    },
    chart: { dateRange: { date: dateRange.date, group: EDateGroup.DAY }, chartIds: [] },
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
  } as unknown as PositionTrackerState,
  reducers: {
    setPositionTrackerFilter: (
      state: PositionTrackerState,
      action: PayloadAction<IPositionTrackerFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{ ...action.payload, no_serp_presence: state.filters.no_serp_presence },
      }
    },
    setSerpPresence: (state: PositionTrackerState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, no_serp_presence: action.payload }
    },
    changeChartDataRange: (state: PositionTrackerState, action: PayloadAction<IDateRange>) => {
      state.chart.dateRange = action.payload
      state.filters.date = action.payload.date
    },
    changeChartIds: (state: PositionTrackerState, action: PayloadAction<number[] | undefined>) => {
      state.chart.chartIds = action.payload
    },
    setProject: (
      state: PositionTrackerState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
  },
})

export const {
  setPositionTrackerFilter,
  setSerpPresence,
  changeChartDataRange,
  changeChartIds,
  setProject,
} = positionTrackerSlice.actions

export const selectPositionTrackerFilters = (state: RootState) => state.root.positionTracker.filters

export const selectPositionTrackerChart = (state: RootState) => state.root.positionTracker.chart
export const selectProject = (state: RootState) => state.root.positionTracker.project

export default positionTrackerSlice.reducer
