import React, {
  ForwardedRef,
  forwardRef,
  SelectHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ResultItem } from './result-item/ResultItem'
import { IOption } from '../search-input/BpSearchInput'
import { usePreviousValue } from '../../../hooks/UsePreviousValue'
import { Preloader } from '../../cards/preloader/Preloader'
import { useTranslation } from 'react-i18next'

export type TResultValue = string | ReadonlyArray<string> | number | undefined

const DEFAULT_OPTION: IOption = { text: '', value: '' }

export interface IResultDropProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: IOption[]
  search?: string
  onSelected?: (value: IOption | IOption[] | undefined) => void
  noShadow?: boolean
  pinTop?: boolean
  asList?: boolean
  loading?: boolean
  height?: number
  hasTranslate?: boolean
  required?: boolean
}

export const ResultDrop = forwardRef(
  (props: IResultDropProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const { t } = useTranslation()
    const { t: translateOption } = useTranslation('translation', { keyPrefix: 'fields.options' })
    const {
      options = [],
      search = '',
      noShadow = false,
      pinTop = false,
      asList = false,
      onSelected,
      value,
      loading = false,
      multiple,
      defaultValue,
      height = 320,
      hasTranslate = false,
      required,
      ...selectAttributes
    } = props

    const [currentValue, setCurrentValue] = useState<TResultValue>()
    const [selectedOption, setSelectedOption] = useState<IOption>()
    const [selectedOptions, setSelectedOptions] = useState<IOption[]>([])
    const [results, setResults] = useState<IOption[]>([])
    const previousValue = usePreviousValue(value)
    const previousOptions = usePreviousValue(options)
    const selectRef = useRef<any>(null)
    useImperativeHandle(ref, () => selectRef.current)

    useEffect(() => {
      selectCurrentValues(defaultValue)
    }, [])

    useEffect(() => {
      if (JSON.stringify(options) === JSON.stringify(previousOptions)) return
      setResults(options)
    }, [options])

    useEffect(() => {
      if (currentValue === undefined) return
      if (onSelected) {
        Array.isArray(currentValue) ? onSelected(selectedOptions) : onSelected(selectedOption)
      }
      if (pinTop) pinTopSelected()

      selectRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    }, [currentValue])

    useEffect(() => {
      if (JSON.stringify(value) === JSON.stringify(previousValue)) return
      selectCurrentValues(value)
    }, [value])

    useEffect(() => {
      if (search) {
        setResults(
          options.filter((option) => option.text.toLowerCase().includes(search?.toLowerCase())),
        )
      } else {
        setResults(options)
      }
    }, [search])

    useEffect(() => {
      setCurrentValue(Array.from(selectedOptions.map((o) => o.value)))
    }, [selectedOptions])

    useEffect(() => {
      setCurrentValue(selectedOption?.value)
    }, [selectedOption])

    useEffect(() => {
      if (pinTop) pinTopSelected()
    }, [pinTop])

    const setMultipleValues = (option: IOption) => {
      if (selectedOptions.find((o) => o.value == option.value)) {
        setSelectedOptions(selectedOptions.filter((o) => o.value !== option.value))
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }

    const setSingleValue = (option: IOption) => {
      if (selectedOption === option) {
        setSelectedOption(undefined)
      } else {
        setSelectedOption(option)
      }
    }

    const handleOnClick = (option: IOption, isSelected: boolean) => {
      if (asList) {
        if (onSelected) onSelected(option)
        return
      }

      if (defaultValue && isSelected && !multiple) {
        return
      }

      if (multiple) {
        setMultipleValues(option)
      } else {
        if (isSelected) option = { text: '', value: '' }
        setSingleValue(option)
      }
    }

    const isSelected = (option: IOption): boolean => {
      if (asList) return false
      if (multiple) {
        return !!selectedOptions.find((o) => o.value == option.value)
      } else {
        return option.value === selectedOption?.value
      }
    }

    const pinTopSelected = () => {
      const res = [...options].sort((a: IOption, b: IOption) => {
        if (
          multiple
            ? selectedOptions.find((o) => o.value === a.value)
            : selectedOption?.value === a.value
        ) {
          return -1
        } else if (
          multiple
            ? selectedOptions.find((o) => o.value === b.value)
            : selectedOption?.value === b.value
        ) {
          return 1
        }

        return 0
      })

      setResults(res)
    }

    const selectCurrentValues = (value: string | ReadonlyArray<string> | number | undefined) => {
      if (Array.isArray(value)) {
        setSelectedOptions(options?.filter((o: IOption) => value.includes(o.value)))
      }

      if (multiple) return

      if (value) {
        setSelectedOption(options?.find((o: IOption) => o.value == value))
      } else if (defaultValue && defaultValue !== '') {
        setSelectedOption(options?.find((o: IOption) => o.value == defaultValue))
      } else if (!asList) {
        setSelectedOption(DEFAULT_OPTION)
      }
    }

    return (
      <>
        <div
          className={`        
            p-[8px]
            bg-white
            flex
            flex-col
            overflow-auto
            relative
            ${
              (!results || (!!results && results.length <= 0)) &&
              'items-center justify-center min-h-[88px]'
            }
            ${loading && 'h-[320px]'}
            ${!noShadow && 'shadow-outline shadow-default'}
          `}
          style={{ maxHeight: height }}
        >
          {loading && <Preloader />}
          {!loading &&
            results.length > 0 &&
            results
              .filter((option) => !option.hidden)
              .map((option: IOption) => (
                <ResultItem
                  onClick={() => handleOnClick(option, isSelected(option))}
                  key={option.value}
                  selected={isSelected(option)}
                  found={search}
                  multiple={multiple}
                  required={required}
                >
                  {hasTranslate ? translateOption(option.text) : option.text}
                </ResultItem>
              ))}
          {!loading && !results.length && (
            <span className='text-aqua-300 text-[15px]'>{t('other.noResultsFound')}</span>
          )}
        </div>
        <select
          {...selectAttributes}
          multiple={multiple}
          value={currentValue}
          ref={selectRef}
          hidden
        >
          <option value=''></option>
          {results?.map((o) => (
            <option key={o.value} value={o.value}>
              {o.text}
            </option>
          ))}
        </select>
      </>
    )
  },
)
