import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconEdit2 = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.33594 2.66665H4.53594C3.41583 2.66665 2.85578 2.66665 2.42796 2.88464C2.05163 3.07639 1.74567 3.38235 1.55392 3.75867C1.33594 4.1865 1.33594 4.74655 1.33594 5.86665V11.4667C1.33594 12.5867 1.33594 13.1468 1.55392 13.5747C1.74567 13.9509 2.05163 14.2569 2.42796 14.4487C2.85578 14.6667 3.41583 14.6667 4.53594 14.6667H10.1359C11.2561 14.6667 11.8161 14.6667 12.2439 14.4487C12.6203 14.2569 12.9262 13.9509 13.1179 13.5747C13.3359 13.1468 13.3359 12.5867 13.3359 11.4667V8.66667M5.33592 10.6667H6.45228C6.7784 10.6667 6.94147 10.6667 7.09494 10.6298C7.23094 10.5971 7.361 10.5433 7.48034 10.4702C7.61487 10.3877 7.7302 10.2724 7.9608 10.0418L14.3359 3.66665C14.8882 3.11437 14.8882 2.21894 14.3359 1.66665C13.7837 1.11437 12.8882 1.11437 12.3359 1.66665L5.96076 8.0418C5.73016 8.2724 5.61486 8.38773 5.5324 8.52227C5.4593 8.6416 5.40542 8.7716 5.37276 8.90767C5.33592 9.06113 5.33592 9.2242 5.33592 9.55033V10.6667Z'
        stroke={color}
        strokeOpacity='0.85'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
