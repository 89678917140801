import { BpInput } from '../../../shared/components/forms/input/BpInput'
import { BpButton } from '../../../shared/components/buttons/bp-button/BpButton'
import React, { useEffect, useState } from 'react'
import { BpTextArea } from '../../../shared/components/forms/textarea/BpTextArea'
import { useTranslation } from 'react-i18next'
import { BpCheckbox } from '../../../shared/components/forms/checkbox/BpCheckbox'
import {
  useGetBrowserLanguageQuery,
  useGetCitiesQuery,
  useGetCountryQuery,
  useGetDeviceTypesQuery,
  useGetEnginesQuery,
} from '../../../api/filter/Filter.api'
import { BpSelect } from '../../../shared/components/forms/select/BpSelect'
import { useForm } from 'react-hook-form'
import {
  useCreateProjectMutation,
  useGetLocationsDefaultSettingsQuery,
} from '../../../api/project/Project.api'
import { IOption } from '../../../shared/components/forms/search-input/BpSearchInput'
import { Tooltip } from 'antd'
import { IconArrowBack } from '../../../shared/components/icons/IconArrowBack'
import { IconLogout } from '../../../shared/components/icons/IconLogout'
import { useAuth } from '../../../shared/hooks/UseAuth'
import { useNavigate } from 'react-router-dom'
import { selectUser, setTariffUpdated } from '../../../store/reducers/AuthSlice'
import { hideModal, setModal } from '../../../store/reducers/AppSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/Redux'
import { IconSuccess } from '../../../shared/components/icons/IconSuccess'
import { ImageStepFirst } from './components/ImageStepFirst'
import { ImageStepSecond } from './components/ImageStepSecond'
import { ImageStepThird } from './components/ImageStepThird'
import { ImageStepFour } from './components/ImageStepFour'
import { ImageStepFive } from './components/ImageStepFive'

export const OnboardingPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const [step, setStep] = useState<number>(1)
  const [disabled, setDisabled] = useState(true)
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user?.allow_onboarding) {
      navigate('/projects')
    }
  }, [user])

  const [createProject] = useCreateProjectMutation()

  const {
    register,
    setValue,
    setError,
    watch,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      project_name: '',
      brand_name: '',
      brand_variants: '',
      domains: '',
      engines: [false, true, false],
      devices: [true, true],
      country: '',
      cities: [],
      browser_language: '',
      terms: '',
    },
  })

  const formValues = watch()

  const { data: engines } = useGetEnginesQuery()
  const { data: devices } = useGetDeviceTypesQuery()
  const { data: countries } = useGetCountryQuery()
  const { data: cities } = useGetCitiesQuery(
    {
      all: true,
      country_id: Number(formValues?.country),
    },
    { skip: !formValues?.country },
  )
  const {
    data: languages,
    isLoading: languagesIsLoading,
    isFetching: languagesIsFetching,
  } = useGetBrowserLanguageQuery(
    {
      country_id: Number(formValues?.country),
    },
    { skip: !formValues?.country },
  )
  const {
    data: locationsDefaultSettings,
    isLoading: locationsDefaultSettingsLoading,
    isFetching: locationsDefaultSettingsFetching,
  } = useGetLocationsDefaultSettingsQuery(
    {
      'country_ids[]': [formValues?.country],
    },
    { skip: !formValues?.country },
  )

  const domainsValidate = (list: string): string[] => {
    const domainRegex = /^(?!-)(?:[A-Za-zА-Яа-яЁё0-9-]{1,63}\.)+[A-Za-zА-Яа-яЁё]{2,}$/

    return list
      .split('\n')
      .map((line: string) => line.trim())
      .filter((line: string) => line && !domainRegex.test(line))
  }

  const handleSubmit = () => {
    createProject({
      step: {
        1: 'onboarding_first',
        2: 'onboarding_second',
        3: 'onboarding_third',
        4: 'onboarding_fourth',
        5: 'onboarding_fifth',
      }[step],
      project_name: step > 0 ? formValues.project_name : undefined,
      brand_name: step > 0 ? formValues.brand_name : undefined,
      brand_variants: step > 1 ? formValues?.brand_variants?.split('\n') : undefined,
      domains: step > 2 ? formValues?.domains?.split('\n') : undefined,
      engines:
        step > 2
          ? engines
              ?.filter((_, index) => formValues?.engines[index])
              .map((engine) => ({ id: Number(engine.value) }))
          : undefined,
      devices:
        step > 2
          ? devices
              ?.filter((_, index) => formValues?.devices[index])
              .map((device) => ({ id: Number(device.value) }))
          : undefined,
      locations:
        step > 3
          ? [
              {
                country_id: Number(formValues?.country),
                languages: [{ id: formValues?.browser_language }],
                cities: formValues?.cities?.map((city: string) => ({ id: Number(city) })),
                engines: engines
                  ?.filter((_, index) => formValues?.engines[index])
                  .map((engine) => ({ id: Number(engine.value) })),
              },
            ]
          : undefined,
      terms:
        step > 4
          ? {
              country_id: Number(formValues?.country),
              terms: formValues?.terms?.split('\n'),
              language_code: formValues?.browser_language,
            }
          : undefined,
    }).then((response: any) => {
      if (step < 5) {
        if (response.data.success) {
          setStep(step + 1)
        }
      } else {
        if (response.data.success) {
          dispatch(setTariffUpdated())
          navigate('/projects')
          dispatch(
            setModal({
              size: 'md',
              children: (
                <div className={'flex flex-col items-center py-[40px] px-[24px]'}>
                  <div className={'w-[54px]'}>
                    <IconSuccess color={'#4DB11E'} />
                  </div>
                  <div className={'pt-[16px] pb-[32px]'}>
                    <h2 className={'text-center pb-[8px]'}>{t('pages.onboarding.projectSetUp')}</h2>
                    <p className={'text-center text-[15px] text-focus-500'}>
                      {t('pages.onboarding.appearWithin2Hours')}
                    </p>
                  </div>
                  <BpButton onClick={() => dispatch(hideModal())} className={'uppercase'}>
                    {t('buttons.seeDemoProject')}
                  </BpButton>
                </div>
              ),
            }),
          )
        }
      }
    })
  }

  useEffect(() => {
    const first = !formValues.project_name.length || !formValues.brand_name
    const second = !formValues?.brand_variants.length
    const third =
      !formValues?.domains.length ||
      !!domainsValidate(formValues?.domains).length ||
      !formValues?.engines.filter((engine: boolean) => engine).length ||
      !formValues?.devices.filter((device: boolean) => device).length
    const fourth =
      !formValues?.country?.length ||
      !formValues?.cities?.length ||
      !formValues?.browser_language?.length
    const fifth =
      !formValues.terms.length ||
      formValues.terms
        .replace(',', '\n')
        .split('\n')
        .filter((value: string) => value.length).length > 20

    setDisabled(
      {
        1: first,
        2: second,
        3: third,
        4: fourth,
        5: fifth,
      }[step] || false,
    )
  }, [formValues])

  useEffect(() => {
    setValue('browser_language', '')

    if (locationsDefaultSettings?.data) {
      setTimeout(
        () => setValue('browser_language', locationsDefaultSettings.data?.items[0].languages[0].id),
        100,
      )
    }
  }, [locationsDefaultSettings])

  useEffect(() => {
    if (cities?.length) {
      setValue(
        'cities',
        [].slice.call(cities).map((o: IOption) => o.value),
      )
    } else {
      setValue('cities', '')
    }
  }, [cities])

  return (
    <div className={'grid grid-rows-[min-content_1fr] min-h-screen w-full bg-[#F7F9FB]'}>
      <div className={'flex px-[16px] pt-[16px] items-center'}>
        {step > 1 && (
          <BpButton
            onClick={() => setStep(step - 1)}
            size='sm'
            theme='outline'
            iconLeft={<IconArrowBack />}
          >
            {t('buttons.back')}
          </BpButton>
        )}
        <BpButton
          onClick={() => logout()}
          className={'ml-auto'}
          size='sm'
          theme='outline'
          iconLeft={<IconLogout />}
        >
          {t('buttons.logout')}
        </BpButton>
      </div>
      <div className={'h-full'}>
        {
          {
            1: (
              <section
                className={
                  'md:container lg:max-w-full xl:container mx-auto flex px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px] items-center h-full'
                }
              >
                <div className={'min-w-full lg:min-w-[596px] lg:max-w-[596px] lg:px-[48px]'}>
                  <p className={'text-[16px] text-default font-medium'}>
                    {t('pages.onboarding.stepOfSteps', { step, steps: 5 })}
                  </p>
                  <h2 className={'text-[36px] leading-[40px] pb-[32px]'}>
                    {t('pages.onboarding.createProject')}
                  </h2>
                  <div className={'flex flex-col gap-[16px]'}>
                    <BpInput
                      label={t('fields.projectName')}
                      info={t('pages.onboarding.placeholders.projectName')}
                      error={errors.project_name?.message as string}
                      onClear={() => setValue('project_name', '', { shouldValidate: true })}
                      value={formValues.project_name}
                      required
                      {...register(
                        'project_name',
                        step === 1
                          ? {
                              onBlur: (e) => setValue('project_name', e.target.value.trim()),
                              required: t('fields.validate.required'),
                              maxLength: {
                                value: 100,
                                message: t('pages.onboarding.validate.projectName'),
                              },
                              pattern: /.*\S+.*/,
                            }
                          : undefined,
                      )}
                    />
                    <BpInput
                      label={t('fields.brandName')}
                      info={t('pages.onboarding.placeholders.brandName')}
                      error={errors.brand_name?.message as string}
                      onClear={() => setValue('brand_name', '', { shouldValidate: true })}
                      value={formValues.brand_name}
                      required
                      {...register(
                        'brand_name',
                        step === 1
                          ? {
                              onBlur: (e) => setValue('brand_name', e.target.value.trim()),
                              required: t('fields.validate.required'),
                              maxLength: {
                                value: 100,
                                message: t('pages.onboarding.validate.brandName'),
                              },
                              pattern: /.*\S+.*/,
                            }
                          : undefined,
                      )}
                    />
                    <div className={'flex pt-[16px]'}>
                      <Tooltip
                        title={disabled && t('pages.onboarding.completeAllFields')}
                        placement={'topLeft'}
                      >
                        <div className={'w-fit'}>
                          <BpButton
                            className={'uppercase w-fit'}
                            contentClassName={'px-[45px]'}
                            size={'lg'}
                            type={'button'}
                            disabled={disabled}
                            onClick={handleSubmit}
                          >
                            {t('buttons.next')}
                          </BpButton>
                        </div>
                      </Tooltip>
                      <BpButton
                        onClick={() => navigate('/projects')}
                        theme={'transparent'}
                        size={'lg'}
                        contentClassName={'text-aqua-300 font-normal'}
                      >
                        {t('buttons.skipForNow')}
                      </BpButton>
                    </div>
                  </div>
                </div>
                <div className={'justify-center w-full hidden lg:flex'}>
                  <ImageStepFirst />
                </div>
              </section>
            ),
            2: (
              <section
                className={
                  'md:container lg:max-w-full xl:container mx-auto flex px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px] items-center h-full'
                }
              >
                <div className={'min-w-full lg:min-w-[596px] lg:max-w-[596px] lg:px-[48px]'}>
                  <p className={'text-[16px] text-default font-medium'}>
                    {t('pages.onboarding.stepOfSteps', { step, steps: 5 })}
                  </p>
                  <h2 className={'text-[36px] leading-[40px]'}>
                    {t('pages.onboarding.addMisspellings')}
                  </h2>
                  <p className={'pt-[16px] pb-[32px] text-[15px] text-focus-500'}>
                    {t('pages.onboarding.detectInstances')}
                  </p>
                  <div className={'flex flex-col gap-[16px]'}>
                    <BpTextArea
                      label={t('fields.brandsMisspellingsVariations')}
                      info={t('pages.onboarding.placeholders.brandsMisspellingsVariations')}
                      value={formValues.brand_variants}
                      required
                      {...register(
                        'brand_variants',
                        step === 2
                          ? {
                              required: t('fields.validate.required'),
                              pattern: /.*\S+.*/,
                              onChange: (e) => {
                                setValue('brand_variants', e.target.value.replace(',', '\n'))
                              },
                              onBlur: (e) => {
                                setValue(
                                  'brand_variants',
                                  e.target.value
                                    .split('\n')
                                    .map((value: string) => value.trim())
                                    .join('\n'),
                                )
                              },
                            }
                          : undefined,
                      )}
                    />
                    <div className={'flex pt-[16px]'}>
                      <Tooltip
                        title={disabled && t('pages.onboarding.completeAllFields')}
                        placement={'topLeft'}
                      >
                        <div className={'w-fit'}>
                          <BpButton
                            className={'uppercase w-fit'}
                            contentClassName={'px-[45px]'}
                            size={'lg'}
                            type={'button'}
                            disabled={disabled}
                            onClick={handleSubmit}
                          >
                            {t('buttons.next')}
                          </BpButton>
                        </div>
                      </Tooltip>
                      <BpButton
                        onClick={() => navigate('/projects')}
                        theme={'transparent'}
                        size={'lg'}
                        contentClassName={'text-aqua-300 font-normal'}
                      >
                        {t('buttons.skipForNow')}
                      </BpButton>
                    </div>
                  </div>
                </div>
                <div className={'justify-center w-full hidden lg:flex'}>
                  <ImageStepSecond />
                </div>
              </section>
            ),
            3: (
              <section
                className={
                  'md:container lg:max-w-full xl:container mx-auto flex px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px] items-center h-full'
                }
              >
                <div className={'min-w-full lg:min-w-[596px] lg:max-w-[596px] lg:px-[48px]'}>
                  <p className={'text-[16px] text-default font-medium'}>
                    {t('pages.onboarding.stepOfSteps', { step, steps: 5 })}
                  </p>
                  <h2 className={'text-[36px] leading-[40px]'}>
                    {t('pages.onboarding.yourDomains')}
                  </h2>
                  <p className={'pt-[16px] pb-[32px] text-[15px] text-focus-500'}>
                    {t('pages.onboarding.listYourBrands')}
                  </p>
                  <div className={'flex flex-col gap-[16px]'}>
                    <BpTextArea
                      label={t('fields.domains')}
                      info={t('pages.onboarding.placeholders.domains')}
                      error={errors.domains?.message as string}
                      value={formValues.domains}
                      required
                      {...register(
                        'domains',
                        step === 3
                          ? {
                              required: t('fields.validate.required'),
                              pattern: /.*\S+.*/,
                              onChange: (e) => {
                                setValue(
                                  'domains',
                                  e.target.value
                                    .replace(',', '\n')
                                    .replace(/[^a-zA-Zа-яА-Я0-9\s.,-:/]/g, ''),
                                )
                              },
                              onBlur: (e) => {
                                setValue(
                                  'domains',
                                  e.target.value
                                    .split('\n')
                                    .map((value: string) => value.trim())
                                    .join('\n'),
                                )

                                if (domainsValidate(e.target.value).length > 0) {
                                  setError('domains', { message: t('fields.validate.domain') })
                                }
                              },
                            }
                          : undefined,
                      )}
                    />
                    <div>
                      <div
                        className={'text-[14px] font-medium mb-[4px] leading-[24px] text-focus-500'}
                      >
                        {t('fields.searchEngines')}
                        <span className={'text-default'}> *</span>
                      </div>
                      <div className={'flex gap-[16px]'}>
                        {engines?.map((engine, index) => {
                          const fieldName = `engines.${index}` as const

                          return (
                            <BpCheckbox
                              key={index}
                              defaultChecked={false}
                              label={engine.text}
                              {...register(fieldName)}
                            />
                          )
                        })}
                      </div>
                    </div>
                    <div>
                      <div
                        className={'text-[14px] font-medium mb-[4px] leading-[24px] text-focus-500'}
                      >
                        {t('fields.deviceTypes')}
                        <span className={'text-default'}> *</span>
                      </div>
                      <div className={'flex gap-[16px]'}>
                        {devices?.map((device, index) => {
                          const fieldName = `devices.${index}` as const

                          return (
                            <BpCheckbox
                              key={index}
                              defaultChecked={false}
                              label={t('fields.options.' + device.text)}
                              {...register(fieldName)}
                            />
                          )
                        })}
                      </div>
                    </div>
                    <div className={'flex pt-[16px]'}>
                      <Tooltip
                        title={disabled && t('pages.onboarding.completeAllFields')}
                        placement={'topLeft'}
                      >
                        <div className={'w-fit'}>
                          <BpButton
                            className={'uppercase w-fit'}
                            contentClassName={'px-[45px]'}
                            size={'lg'}
                            type={'button'}
                            disabled={disabled}
                            onClick={handleSubmit}
                          >
                            {t('buttons.next')}
                          </BpButton>
                        </div>
                      </Tooltip>
                      <BpButton
                        onClick={() => navigate('/projects')}
                        theme={'transparent'}
                        size={'lg'}
                        contentClassName={'text-aqua-300 font-normal'}
                      >
                        {t('buttons.skipForNow')}
                      </BpButton>
                    </div>
                  </div>
                </div>
                <div className={'justify-center w-full hidden lg:flex'}>
                  <ImageStepThird />
                </div>
              </section>
            ),
            4: (
              <section
                className={
                  'md:container lg:max-w-full xl:container mx-auto flex px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px] items-center h-full'
                }
              >
                <div className={'min-w-full lg:min-w-[596px] lg:max-w-[596px] lg:px-[48px]'}>
                  <p className={'text-[16px] text-default font-medium'}>
                    {t('pages.onboarding.stepOfSteps', { step, steps: 5 })}
                  </p>
                  <h2 className={'text-[36px] leading-[40px]'}>
                    {t('pages.onboarding.addLocation')}
                  </h2>
                  <p className={'pt-[16px] pb-[32px] text-[15px] text-focus-500'}>
                    {t('pages.onboarding.selectOneCountry')}
                  </p>
                  <div className={'flex flex-col gap-[16px]'}>
                    <BpSelect
                      height={150}
                      options={countries}
                      showSearch
                      label={t('fields.country')}
                      placeholder={t('fields.selectCountry')}
                      value={formValues.country}
                      required
                      {...register(
                        'country',
                        step === 4
                          ? {
                              required: t('fields.validate.required'),
                              onChange: (e) => {
                                setValue('country', e.target.value)

                                if (!e.target.value) {
                                  setValue('cities', [])
                                  setValue('browser_language', '')
                                }
                              },
                            }
                          : undefined,
                      )}
                    />
                    <BpSelect
                      height={150}
                      options={cities}
                      multiple
                      showSearch
                      label={t('fields.cities')}
                      placeholder={t('fields.selectCity')}
                      value={formValues.cities}
                      disabled={!formValues.country}
                      required
                      {...register(
                        'cities',
                        step === 4
                          ? {
                              required: t('fields.validate.required'),
                            }
                          : undefined,
                      )}
                    />
                    <BpSelect
                      height={150}
                      showSearch
                      options={languages}
                      label={t('fields.browserLanguage')}
                      placeholder={t('fields.selectLanguage')}
                      value={formValues.browser_language}
                      disabled={!formValues.country}
                      loading={languagesIsLoading || languagesIsFetching}
                      required
                      {...register(
                        'browser_language',
                        step === 4
                          ? {
                              required: t('fields.validate.required'),
                            }
                          : undefined,
                      )}
                    />
                    <div className={'flex pt-[16px]'}>
                      <Tooltip
                        title={disabled && t('pages.onboarding.completeAllFields')}
                        placement={'topLeft'}
                      >
                        <div className={'w-fit'}>
                          <BpButton
                            className={'uppercase w-fit'}
                            contentClassName={'px-[45px]'}
                            size={'lg'}
                            type={'button'}
                            disabled={disabled}
                            onClick={handleSubmit}
                          >
                            {t('buttons.next')}
                          </BpButton>
                        </div>
                      </Tooltip>
                      <BpButton
                        onClick={() => navigate('/projects')}
                        theme={'transparent'}
                        size={'lg'}
                        contentClassName={'text-aqua-300 font-normal'}
                      >
                        {t('buttons.skipForNow')}
                      </BpButton>
                    </div>
                  </div>
                </div>
                <div className={'justify-center w-full hidden lg:flex'}>
                  <ImageStepFour />
                </div>
              </section>
            ),
            5: (
              <section
                className={
                  'md:container lg:max-w-full xl:container mx-auto flex px-[16px] xl:max-w-[1440px] xxl:max-w-[1920px] items-center h-full'
                }
              >
                <div className={'min-w-full lg:min-w-[596px] lg:max-w-[596px] lg:px-[48px]'}>
                  <p className={'text-[16px] text-default font-medium'}>
                    {t('pages.onboarding.stepOfSteps', { step, steps: 5 })}
                  </p>
                  <h2 className={'text-[36px] leading-[40px]'}>
                    {t('pages.onboarding.searchTerms')}
                  </h2>
                  <p className={'pt-[16px] pb-[32px] text-[15px] text-focus-500'}>
                    {t('pages.onboarding.limitedSearchTerms')}
                  </p>
                  <div className={'flex flex-col gap-[16px]'}>
                    <BpTextArea
                      height='lg'
                      info={t('pages.onboarding.placeholders.terms')}
                      required
                      error={errors.terms?.message as string}
                      {...register(
                        'terms',
                        step === 5
                          ? {
                              required: t('fields.validate.required'),
                              onChange: (e) => {
                                const value = e.target.value.replace(',', '\n').split('\n')

                                if (value.find((string: string) => string.length > 50)) {
                                  setValue('terms', formValues.terms)
                                } else {
                                  setValue('terms', value.join('\n'))
                                }
                              },
                              onBlur: (e) => {
                                setValue(
                                  'terms',
                                  e.target.value
                                    .split('\n')
                                    .filter((value: string) => !!value.length)
                                    .map((value: string) => value.trim())
                                    .join('\n'),
                                )

                                if (
                                  e.target.value
                                    .replace(',', '\n')
                                    .split('\n')
                                    .filter((value: string) => value.length).length > 20
                                ) {
                                  setError('terms', {
                                    message: t('pages.onboarding.validate.limitedSearchTerms'),
                                  })
                                }
                              },
                            }
                          : undefined,
                      )}
                    />
                    <div className={'flex pt-[16px]'}>
                      <Tooltip
                        title={disabled && t('pages.onboarding.completeAllFields')}
                        placement={'topLeft'}
                      >
                        <div className={'w-fit'}>
                          <BpButton
                            className={'uppercase w-fit'}
                            contentClassName={'px-[45px]'}
                            size={'lg'}
                            type={'button'}
                            disabled={disabled}
                            onClick={handleSubmit}
                          >
                            {t('buttons.create')}
                          </BpButton>
                        </div>
                      </Tooltip>
                      <BpButton
                        onClick={() => navigate('/projects')}
                        theme={'transparent'}
                        size={'lg'}
                        contentClassName={'text-aqua-300 font-normal'}
                      >
                        {t('buttons.skipForNow')}
                      </BpButton>
                    </div>
                  </div>
                </div>
                <div className={'justify-center w-full hidden lg:flex'}>
                  <ImageStepFive />
                </div>
              </section>
            ),
          }[step]
        }
      </div>
    </div>
  )
}
