import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal, setShowGuide } from '../../../../store/reducers/AppSlice'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { IconArrowUp } from '../../../../shared/components/icons/IconArrowUp'
import { Link } from 'react-router-dom'

export const GuideModal = () => {
  const dispatch = useAppDispatch()

  return (
    <div className={'py-[40px] overflow-y-auto max-h-full'}>
      <h2 className={'text-[36px] text-center'}>Quick tour</h2>
      <div className={'px-[60px]'}>
        <BpTabs className='mt-[19px]' size={'xs'} align={'center'}>
          <BpTab title={'Create a project'}>
            <div className={'py-[24px] flex flex-col gap-[60px]'}>
              <div className={'flex flex-col gap-[16px]'}>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Below you will find a detailed guide on how to create and edit projects in the
                  system. This guide will help you set up monitoring on your own, configure the
                  necessary parameters, and receive useful analytics about your brand, competitors,
                  and partners—without the help of an account manager.
                </p>
              </div>

              <div className={'flex flex-col gap-[24px]'}>
                <h2 className={'text-[36px]'}>
                  1. Creating a{' '}
                  <Link
                    className={'font-medium text-default'}
                    to={'/projects'}
                    onClick={() => dispatch(hideModal())}
                  >
                    New Project
                  </Link>
                </h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  To start using the system and launch monitoring, you first need to create a new
                  project. To do this, go to the page{' '}
                  <Link
                    className={'text-default'}
                    to={'/projects'}
                    onClick={() => dispatch(hideModal())}
                  >
                    Projects
                  </Link>{' '}
                  and click <strong>Add Project</strong>.
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>Step 1. Filling Out General Project Information</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>Project Name:</strong> Fill in this field to make it easier to find the
                  project in lists and filters on reporting pages.
                  <br />
                  Example: “Bluepear UK, US”
                  <br />
                  <strong>Brand Name:</strong> Specify your brand name so that the system can
                  correctly track mentions by partners, competitors, and in search engine results.
                  <br />
                  Example: “Bluepear”.
                  <br />
                  <strong>Brand’s Misspellings/Variations:</strong> Include all possible typos,
                  alternative or shortened names of the brand. This helps the system find any
                  inaccurate mentions.
                  <br />
                  <strong>Domains:</strong> Enter the domains belonging to your brand for correct
                  monitoring of violations.
                  <br />
                  <strong>Search Engines:</strong> Select the search engines in which the checks
                  will be performed.
                  <br />
                  <strong>Devices:</strong> Indicate on which types of devices (desktop, mobile)
                  searches will be conducted.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  After completing all fields, click <strong>Next</strong>. If any required fields
                  are missing, the button will be inactive.
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>Step 2. Setting Up Location</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>Country:</strong> Specify the country where the search and monitoring of
                  results will be conducted. You can add more countries later in the project
                  settings.
                  <br />
                  <strong>Cities:</strong> If regional targeting is important to you, list the
                  specific cities in the chosen country.
                  <br />
                  <strong>Browser Language:</strong> Specify the language to be emulated in search
                  results (for example, English).
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Click <strong>Next</strong> to proceed to the next step.
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>Step 3. Keywords</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Add the keywords for which the system will scan search results. When all the
                  necessary queries have been entered, click <strong>Create Project</strong>.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  If you encounter any errors or have questions when creating the project, please{' '}
                  <span
                    className={'text-default cursor-pointer hover:text-[#40a9ff]'}
                    onClick={() => {
                      dispatch(hideModal())
                      dispatch(setShowGuide())
                    }}
                  >
                    contact support
                  </span>
                </p>
              </div>

              <div className={'flex flex-col gap-[24px]'}>
                <h2 className={'text-[36px]'}>2. Editing an Existing Project</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  After creating a project, you can always go back to its settings and make changes.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  To edit an existing project:
                  <ul className={'pl-[36px] list-decimal'}>
                    <li>
                      Go to{' '}
                      <Link
                        className={'text-default'}
                        to={'/projects'}
                        onClick={() => dispatch(hideModal())}
                      >
                        <strong>Projects</strong>
                      </Link>{' '}
                      and find the project you need.
                    </li>
                    <li>
                      Click <strong>Edit</strong>.
                    </li>
                  </ul>
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Below is a brief overview of the main sections.
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>2.1. General Settings</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  In this section, you can modify:
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>Project Name</strong> — if you need to give the project a new name or
                  clarify the existing one.
                  <br />
                  <strong>Brand Name</strong> — if you need to rename the brand or correct its
                  spelling.
                  <br />
                  <strong>Devices</strong> — choose which device types (desktop or mobile) should be
                  monitored for mentions.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  You can also configure Notifications and Reports here:
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>Notify about new affiliates</strong> Enable this option to receive alerts
                  whenever new affiliate violators appear.
                  <br />
                  <strong>Send full weekly report</strong> Enable this to automatically receive
                  reports about detected ads (competitors, affiliates).
                  <br />
                  <strong>Telegram Chat ID</strong> Enter the chat ID to receive notifications in
                  Telegram.
                  <br />
                  <strong>Recipients’ Email Addresses</strong> List addresses separated by commas to
                  automatically send reports by email.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  If necessary, you can immediately send a 7-day report by email or Telegram using
                  the respective buttons.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Click <strong>SAVE CHANGES</strong> to apply any modifications.
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>2.2. Countries</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  This section displays the selected countries along with all details (cities,
                  language, search engines).
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <ul className={'pl-[36px] list-disc'}>
                    <li>
                      Click <strong>Add location</strong> to add a new country (or multiple
                      countries) with additional settings.
                    </li>
                    <li>
                      Remove any locations that are no longer relevant.
                      <br />
                      <strong>Important</strong>: You cannot delete the last remaining location, but
                      you can remove all others if needed.
                    </li>
                    <li>
                      Click <strong>SAVE CHANGES</strong> after making all necessary updates.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>2.3. Keywords</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Here, you can modify or expand the list of search queries.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <ul className={'pl-[36px] list-disc'}>
                    <li>Add new keywords if needed, or remove outdated ones.</li>
                    <li>
                      Click <strong>SAVE CHANGES</strong> to apply the changes.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[24px]'}>
                <h2 className={'text-[36px]'}>3. Useful Tips</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <ul className={'pl-[36px] list-disc'}>
                    <li>
                      If the <strong>Next</strong>, <strong>Create Project</strong>, or{' '}
                      <strong>SAVE CHANGES</strong> button is inactive, check whether all required
                      fields are filled in.
                    </li>
                    <li>Use the hints and placeholders — they indicate the correct data format.</li>
                    <li>
                      Notifications in Telegram and via email will only work if you have specified a{' '}
                      <strong>Telegram Chat ID</strong> and/or{' '}
                      <strong>Recipients’ Email Addresses</strong>.
                    </li>
                    <li>
                      You can always add more locations and search queries after creating a project
                      in the <strong>Countries</strong> and <strong>Search Terms</strong> sections.
                    </li>
                    <li>
                      If you run into any problems or something is unclear,{' '}
                      <span
                        className={'text-default cursor-pointer hover:text-[#40a9ff]'}
                        onClick={() => {
                          dispatch(hideModal())
                          dispatch(setShowGuide())
                        }}
                      >
                        contact support
                      </span>
                      .
                    </li>
                  </ul>
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  By following this guide, you can quickly configure monitoring, stay informed about
                  any detected violations, and receive all the analytics you need in a timely
                  manner.
                </p>
              </div>
            </div>
          </BpTab>
          <BpTab title={'Invite Users'}>
            <div className={'py-[24px] flex flex-col gap-[60px]'}>
              <div className={'flex flex-col gap-[24px]'}>
                <h2 className={'text-[36px]'}>
                  User Management in the{' '}
                  <Link
                    className={'font-medium text-default'}
                    to={'/team'}
                    onClick={() => dispatch(hideModal())}
                  >
                    “Team”
                  </Link>{' '}
                  Section
                </h2>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>1. User Roles</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  Bluepear provides four user roles, each with different access levels:
                  <ul className={'pl-[36px] list-disc'}>
                    <li>
                      <strong>Owner</strong> – Full control over the platform, including all
                      settings, users, and projects.
                    </li>
                    <li>
                      <strong>Administrator</strong> – Manages users, projects, and pricing plans.
                    </li>
                    <li>
                      <strong>Editor</strong> – Can create, edit, and delete projects but cannot
                      manage users or pricing plans.
                    </li>
                    <li>
                      <strong>User</strong> – Has access only to assigned projects, cannot see the
                      user list, and cannot change pricing plans.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>2. Viewing the User List</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  This section is available only to <strong>Owners</strong> and{' '}
                  <strong>Administrators</strong>. Here, you can view all platform users and their
                  current status.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  The list displays:
                  <ul className={'pl-[36px] list-disc'}>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Status (Active, Pending, Archived)</li>
                    <li>Role</li>
                    <li>Assigned Projects</li>
                    <li>Last Login Date</li>
                  </ul>
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  User Statuses:
                  <ul className={'pl-[36px] list-disc'}>
                    <li>
                      <strong>Active</strong> – The user is working within the system.
                    </li>
                    <li>
                      <strong>Pending</strong> – The user has been invited but has not activated
                      their account.
                    </li>
                    <li>
                      <strong>Archived</strong> – The user is temporarily disabled and cannot log
                      in.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>3. Inviting a New User</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  To add a new team member:
                  <ul className={'pl-[36px] list-decimal'}>
                    <li>
                      Click <strong>“Invite Teammates”</strong>.
                    </li>
                    <li>
                      Fill in the details:
                      <ul className={'pl-[36px] list-disc'}>
                        <li>
                          <strong>Name</strong> (required)
                        </li>
                        <li>
                          <strong>Email</strong> (required, must be in a valid format)
                        </li>
                        <li>
                          <strong>Role</strong> (User, Editor, Administrator)
                        </li>
                        <li>
                          <strong>Projects</strong> (Users must be assigned at least one project;
                          Editors and Administrators have access to all projects)
                        </li>
                      </ul>
                    </li>
                    <li>
                      If you want the user to automatically get access to all future projects, check
                      <strong>“Automatically Assign to All New Projects”</strong>.
                    </li>
                    <li>
                      Click <strong>“Send Invitation”</strong>.
                    </li>
                    <li>
                      If everything is filled in correctly, a confirmation message will appear.
                    </li>
                    <li>
                      The invited user will receive an email with a link to set a password. Until
                      they activate their account, their status will remain <strong>Pending</strong>
                      .
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>4. Editing User Information</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  To make changes to a user’s profile:
                  <ul className={'pl-[36px] list-decimal'}>
                    <li>
                      Click <strong>“Edit”</strong> next to the desired user (not available for
                      Owners).
                    </li>
                    <li>
                      You can update:
                      <ul className={'pl-[36px] list-disc'}>
                        <li>Name</li>
                        <li> Role (User, Editor, Administrator)</li>
                        <li>Assigned Projects (Users must have at least one assigned project)</li>
                        <li>
                          Checkbox <strong>“Automatically assign to new projects”</strong>
                        </li>
                      </ul>
                    </li>
                    <li>Email cannot be changed.</li>
                    <li>
                      Click <strong>“Send invitation”</strong>.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>5. Disabling (Archiving) and Restoring Users</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  If you need to temporarily disable or restore a user:
                  <ul className={'pl-[36px] list-disc'}>
                    <li>
                      <strong>Archive</strong> – Sets the user status to <strong>Archived</strong>,
                      preventing login. Owners cannot archive themselves.
                    </li>
                    <li>
                      <strong>Restore</strong> – Changes the user status back to{' '}
                      <strong>Active</strong>, re-enabling access.
                    </li>
                    <li>
                      For users with a <strong>Pending</strong> status, the{' '}
                      <strong>“Resend Invitation”</strong>
                      button is available to send a new activation email.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>6. Activating an Invited User</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  For a new user to access the system:
                  <ul className={'pl-[36px] list-decimal'}>
                    <li>The user receives an email with an activation link.</li>
                    <li>
                      After clicking the link, they set a password and activate their account.
                    </li>
                    <li>
                      Their status changes to <strong>Active</strong>, allowing them to log in.
                    </li>
                    <li>
                      If the link has expired (valid for 72 hours) or is invalid, an error message
                      will appear.
                    </li>
                  </ul>
                </p>
              </div>

              <div className={'flex flex-col gap-[16px]'}>
                <h2 className={'text-[24px]'}>7. Frequently Asked Questions (FAQ)</h2>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>Can the Owner role be transferred to another user?</strong>
                  <br />
                  No, ownership can only be transferred manually through{' '}
                  <span
                    className={'text-default cursor-pointer hover:text-[#40a9ff]'}
                    onClick={() => {
                      dispatch(hideModal())
                      dispatch(setShowGuide())
                    }}
                  >
                    customer support
                  </span>{' '}
                  (if permitted by company policy).
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>What if an invitation expires?</strong>
                  <br />
                  Click “Resend Invitation”, and the system will send a new email.
                </p>
                <p className={'text-[20px] opacity-80 text-focus-500'}>
                  <strong>How can I add a user to more projects after activation?</strong>
                  <br />
                  Click “Edit”, add the projects, and save the changes.
                </p>
              </div>
            </div>
          </BpTab>
        </BpTabs>
        <div className={'bg-[#F7F9FB] py-[32px] rounded-[16px] flex justify-center gap-[16px]'}>
          <BpButton
            onClick={() => {
              document.getElementById('guide-modal')?.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
            iconRight={<IconArrowUp />}
            className={'uppercase'}
            size={'md'}
          >
            Back to top
          </BpButton>
          <BpButton
            onClick={() => dispatch(hideModal())}
            className={'uppercase'}
            theme={'outline'}
            size={'md'}
          >
            Close
          </BpButton>
        </div>
      </div>
    </div>
  )
}
