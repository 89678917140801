import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
  useExportKeywordsMutation,
  useGetKeywordsQuery,
} from '../../../../../../api/research/Research.api'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { useAppSelector } from '../../../../../../store/hooks/Redux'
import { IResearchFilter } from '../../../interfaces/ResearchFilter.interface'
import { selectResearchFilters } from '../../../store/ResearchSlice'
import { useDispatch } from 'react-redux'
import { ProgressBar } from '../../../../../../shared/components/cards/progress-bar/ProgressBar'
import { BpTable } from '../../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../../shared/components/cards/pagination/BpPagination'
import {
  selectKeywordsFilter,
  selectKeywordsPagination,
  setKeywords,
  setKeywordsPagination,
} from '../../../store/KeywordsSlice'
import { IKeywordsParams } from '../../../../../../api/research/interfaces/KeywordsParams.interface'
import { IKeywordsFilter } from '../../../interfaces/KeywordsFilter.interface'
import { IKeywordModel } from '../../../../../../api/research/models/Keyword.model'
import { BpLink } from '../../../../../../shared/components/text/link/BpLink'
import { IPagination } from '../../../../../../shared/interfaces/Pagination.interface'
import { usePreviousValue } from '../../../../../../shared/hooks/UsePreviousValue'
import { useSorter } from '../../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../../shared/components/cards/export-drop/ExportDrop'
import { BpTooltip } from '../../../../../../shared/components/cards/tooltip/BpTooltip'
import { useTranslation } from 'react-i18next'

const MAX_CHECKED_ROWS = 5

export const KeywordsReportTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const researchFilters = useAppSelector<IResearchFilter>(selectResearchFilters)
  const keywordsFilter = useAppSelector<IKeywordsFilter>(selectKeywordsFilter)
  const pagination = useAppSelector<IPagination>(selectKeywordsPagination)
  const { current, pageSize, total } = pagination
  const [countRows, setCountRows] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<IKeywordsParams>({
    page: current,
    'per-page': pageSize,
  })
  const {
    keywords,
    dateRange: { date },
  } = keywordsFilter
  const { project, country } = researchFilters
  const { data, isLoading, isFetching } = useGetKeywordsQuery(queryParams, {
    skip: !queryParams?.project_ids?.length,
  })
  const previousProject = usePreviousValue(project)
  const [exportFile, { isLoading: exportKeywordsLoading }] = useExportKeywordsMutation()

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)

    if (!keywords) {
      dispatch(setKeywords(data.data.items.slice(0, 5).map((item) => item.keyword)))
    }
  }, [data])

  useEffect(() => {
    const project_ids = project ? [project] : []

    setQueryParams({
      ...queryParams,
      project_ids,
      'country_ids[]': country,
      date,
    })
  }, [researchFilters, keywordsFilter])

  useEffect(() => {
    if (previousProject && project && previousProject !== project) {
      dispatch(setKeywords(undefined))
      dispatch(
        setKeywordsPagination({
          ...pagination,
          current: 1,
        }),
      )
    }
  }, [project])

  const columns: ColumnsType<IKeywordModel> = [
    {
      title: t('table.columns.keyword'),
      dataIndex: 'keyword',
      key: 'keyword',
      sorter: true,
    },
    {
      title: t('table.columns.advertisers'),
      dataIndex: 'sites_count',
      key: 'sites_count',
      render: (_: number, record: IKeywordModel) => {
        return (
          <BpLink to={`advertisers/${record.project_id}/${record.keyword}`}>
            {record.sites_count}
          </BpLink>
        )
      },
      sorter: true,
    },
    {
      title: t('table.columns.topAdvertisers'),
      dataIndex: 'top_advertiser',
      key: 'top_advertiser',
      render: (_: string, record: IKeywordModel) => {
        return (
          <BpLink to={`ads-domain/${record.project_id}/${record.top_advertiser}`}>
            {record.top_advertiser}
          </BpLink>
        )
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.ads')} <BpTooltip title={t('pages.advertisingResearch.adsTooltip')} />
        </span>
      ),
      dataIndex: 'ads_count',
      key: 'ads_count',
      render: (_: string, record: IKeywordModel) => {
        return (
          <BpLink to={`ads-keyword/${record.project_id}/${record.keyword}`}>
            {record.ads_count}
          </BpLink>
        )
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('table.columns.lastUpdate'),
      dataIndex: 'last_update',
      key: 'last_update',
      sorter: true,
    },
  ]

  const rowSelection: TableRowSelection<IKeywordModel> = {
    selectedRowKeys: keywords,
    hideSelectAll: true,
    onSelect: (record, selected) => {
      if (selected) {
        !keywords?.includes(record.keyword) &&
          dispatch(setKeywords(keywords ? [...keywords, record.keyword] : [record.keyword]))
      } else {
        dispatch(setKeywords(keywords?.filter((row) => row !== record.keyword)))
      }
    },
    getCheckboxProps: (record) => {
      return {
        disabled:
          keywords && !keywords?.includes(record.keyword) && keywords?.length >= MAX_CHECKED_ROWS,
      }
    },
  }

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<IKeywordModel> | SorterResult<IKeywordModel>[],
  ) => {
    dispatch(
      setKeywordsPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='font-medium'>
          {t('pages.advertisingResearch.allKeywords')}: {countRows}
        </h3>
        <ExportDrop
          onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
          loading={exportKeywordsLoading}
          columns={[
            { label: t('table.columns.keyword'), keys: ['keyword'] },
            { label: t('table.columns.advertisers'), keys: ['sites_count'] },
            { label: t('table.columns.topAdvertisers'), keys: ['top_advertiser'] },
            { label: t('table.columns.ads'), keys: ['ads_count'] },
            { label: t('table.columns.lastUpdate'), keys: ['last_update'] },
          ]}
        />
      </div>
      <BpTable
        rowSelection={rowSelection}
        dataSource={data?.data?.items}
        rowKey={(record) => record.keyword}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IKeywordModel>}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}
