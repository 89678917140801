import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { FormHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

export interface ISetUpPasswordFormData {
  password: string
  confirmPassword: string
}

export interface ISetUpPasswordFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ISetUpPasswordFormData) => void
  isFetching?: boolean
}

export const SetUpPasswordForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ISetUpPasswordFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<ISetUpPasswordFormData>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  return (
    <>
      <h2 className='mb-[24px]'>{t('pages.auth.setUpYourPassword')}</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ISetUpPasswordFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.fields.newPassword')}
          type='password'
          required
          error={errors.password?.message}
          {...register('password', { required: t('fields.validate.required') })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.fields.confirmNewPassword')}
          type='password'
          required
          error={errors.confirmPassword?.message}
          {...register('confirmPassword', {
            required: t('fields.validate.required'),
            validate: (value: string) => {
              const { password } = getValues()
              return value === password || t('pages.auth.fields.passwordsDoNotMatch')
            },
          })}
        ></BpInput>
        <div className='mt-[16px] flex flex-col'>
          <BpButton className='uppercase' type='submit' disabled={!isValid || isFetching} size='lg'>
            {t('pages.auth.buttons.confirm')}
          </BpButton>
        </div>
      </form>
    </>
  )
}
