import { CommonAuthPage } from '../utility/CommonAuthPage'
import { ILoginFormData, LoginForm } from './loginForm/LoginForm'
import { SubmitHandler } from 'react-hook-form'
import { useLazyLoginQuery } from '../../../api/site/Site.api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../store/hooks/Redux'
import { selectOauthUser } from '../../../store/reducers/AuthSlice'

export const LoginPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const oauthUser = useAppSelector(selectOauthUser)
  const [fetchLogin, { data, isFetching, isLoading }] = useLazyLoginQuery()
  const handleSubmit: SubmitHandler<ILoginFormData> = (formData: ILoginFormData) => {
    fetchLogin(formData)
  }

  useEffect(() => {
    if (data?.success) {
      navigate(data.data?.allow_onboarding ? '/onboarding' : '/')
      window.location.reload()
    }
  }, [data])

  useEffect(() => {
    if (oauthUser) {
      if (oauthUser.id && oauthUser.status === 'active') {
        navigate(oauthUser.allow_onboarding ? '/onboarding' : '/')
        window.location.reload()
      } else {
        navigate('/auth/signup')
      }
    }
  }, [oauthUser])

  return (
    <>
      <Helmet>
        <title>
          {t('pages.auth.logIn')} | {t('project.name')}
        </title>
        <meta
          name='description'
          content='Our software helps to protect your PPC campaigns against brand bidding and trademark abuse'
        />
      </Helmet>
      <CommonAuthPage
        form={<LoginForm submitHandler={handleSubmit} isFetching={isFetching || isLoading} />}
      ></CommonAuthPage>
      <svg
        className='absolute hidden lg:block -right-[52px] top-[13px]'
        width='301'
        height='843'
        viewBox='0 0 301 843'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M39.0448 178.05C38.0285 177.456 37.0969 176.947 36.0806 176.353C34.9796 175.759 33.8785 175.081 32.6928 174.657C28.4582 176.268 23.2072 175.505 19.3113 173.299C17.7021 172.366 16.2623 171.179 14.9072 169.991C14.2297 169.397 13.6368 168.888 13.1287 168.125C12.6205 167.446 12.2817 166.513 12.4511 165.665C12.6205 164.477 13.5521 163.629 14.2297 162.696C14.9072 161.763 15.4154 160.491 14.8225 159.473C14.3991 158.879 13.6368 158.624 12.9593 158.285C10.1644 157.182 8.38586 154.977 7.28485 152.178C14.9072 153.365 22.0215 155.401 28.8816 158.879C29.898 157.522 30.4061 155.91 29.8979 154.298C29.5592 153.28 28.9663 152.432 28.3735 151.499C27.8653 150.566 27.4419 149.548 27.6112 148.445C27.7806 147.427 28.6276 146.41 29.7286 146.41C30.9143 146.494 31.6765 147.682 32.1847 148.7C33.1163 150.481 34.0479 152.263 34.8948 154.044C35.7418 155.656 36.504 157.267 36.8428 159.049C37.0122 159.897 37.0122 160.83 37.5203 161.593C37.9438 162.272 38.7061 162.611 39.3836 162.951C41.2469 163.884 43.0254 164.817 44.8887 165.665C42.856 169.906 40.9081 173.978 39.0448 178.05Z'
          fill='#0E63D8'
        />
        <path
          d='M271.953 156.419C271.953 156.419 222.662 208.672 184.465 230.218C147.962 250.745 38.0302 178.05 38.0302 178.05L44.1281 164.986C44.1281 164.986 106.462 191.622 160.412 197.644C160.412 197.644 202.759 163.375 236.043 123.846L271.953 156.419Z'
          fill='#0E63D8'
        />
        <path
          d='M70.0869 827.78L50.9336 820.474L52.712 815.797L71.8653 823.103L70.0869 827.78Z'
          fill='#001C44'
        />
        <path
          d='M71.8198 823.066C71.8198 823.066 77.0708 834.263 65.6372 839.437L16.1763 820.606C16.3457 819.503 17.1079 814.329 21.7661 810.681C25.5773 807.712 29.5579 807.543 30.913 807.543C38.7894 810.597 46.7506 813.565 54.6271 816.619C54.5424 816.534 61.1485 824.762 71.8198 823.066Z'
          fill='#001C44'
        />
        <path
          d='M30.8301 807.458C23.0383 804.489 14.3996 808.391 11.4353 816.195L10.5884 818.485L18.1261 821.369C18.6342 819.588 19.5659 816.958 21.6832 814.244C24.9016 810.088 28.8821 808.221 30.8301 807.458Z'
          fill='#001C44'
        />
        <path
          d='M59.7935 808.476L56.7446 818.231L69.6179 822.811L74.1914 813.311L59.7935 808.476Z'
          fill='#001C44'
        />
        <path
          d='M284.316 303.337C284.316 303.337 284.57 370.604 273.729 394.865C262.973 419.04 196.574 527.957 196.574 527.957L74.7847 818.061L56.6603 811.19L95.5345 545.685C95.5345 545.685 164.644 327.682 190.137 284.421C215.63 241.16 284.316 303.337 284.316 303.337Z'
          fill='#A059FF'
        />
        <path
          d='M244.109 828.65L224.969 828.632L224.973 823.966L244.114 823.985L244.109 828.65Z'
          fill='#001C44'
        />
        <path
          d='M244.003 823.999C244.003 823.999 252.302 832.057 244.003 840.37L194.457 840.286C194.203 839.268 193.187 834.517 196.066 829.767C198.353 825.95 201.91 824.423 203.011 823.999C210.887 823.999 218.849 823.999 226.725 823.999C226.81 823.999 235.364 829.004 244.003 823.999Z'
          fill='#001C44'
        />
        <path
          d='M203.012 823.914C195.22 823.914 188.953 830.191 188.953 837.995V840.286H196.575C196.406 838.589 196.406 835.959 197.337 832.906C198.777 828.156 201.657 825.271 203.012 823.914Z'
          fill='#001C44'
        />
        <path
          d='M225.117 809.409L229.013 824.847L242.648 824.253L240.192 812.123L225.117 809.409Z'
          fill='#001C44'
        />
        <path
          d='M298.292 313.262C298.292 313.262 304.728 332.433 297.699 366.702C287.62 415.986 198.523 542.716 198.523 542.716L244.681 812.972L221.136 819.334L127.38 569.436C119.758 549.163 118.657 527.023 124.247 506.071C140.508 444.742 175.656 316.401 188.106 303.762L192.086 267.286L298.292 313.262Z'
          fill='#A059FF'
        />
        <path
          d='M29.4746 174.232C30.4909 174.232 31.592 173.978 32.5236 173.384C35.3185 171.688 36.1654 168.04 34.4715 165.241L10.9268 126.56C9.23292 123.761 5.5911 122.913 2.79622 124.609C0.00134277 126.306 -0.845581 129.953 0.848286 132.753L24.393 171.433C25.5787 173.215 27.5267 174.232 29.4746 174.232Z'
          fill='#3BFFBF'
        />
        <path
          d='M11.1809 143.61C13.1289 145.307 15.2462 147.343 17.3636 149.803C24.986 158.455 27.7808 166.259 30.2369 165.58C33.1165 164.817 31.2533 153.28 34.1328 152.602C36.4195 152.093 39.8073 158.709 41.0777 161.848C42.6869 165.75 44.4654 169.991 43.3644 174.402L47.4297 178.05L38.1134 187.805L31.4227 181.697C26.4258 180.425 18.9727 177.371 13.0442 170.161C6.09935 161.678 5.16772 152.093 5.42181 145.901C5.59119 143.101 8.97891 141.659 11.1809 143.61Z'
          fill='#0E63D8'
        />
        <path
          d='M218.343 50.1321C217.834 59.2085 221.815 66.758 227.32 67.0973C232.74 67.3518 237.568 60.3112 237.991 51.2349C238.5 42.1585 234.519 34.609 229.014 34.2697C223.678 33.9304 218.851 41.0558 218.343 50.1321Z'
          fill='#0E63D8'
        />
        <path
          d='M240.87 87.0314C241.802 85.8438 224.44 63.1105 224.44 63.1105L203.436 75.7495L214.446 105.608C214.53 105.608 227.658 103.233 240.87 87.0314Z'
          fill='#0E63D8'
        />
        <path
          d='M228.672 69.5572C228.333 70.5752 227.994 71.5082 227.571 72.4413C224.098 80.3301 216.9 87.2859 208.938 90.6789L203.433 75.7495L224.437 63.1105C224.437 63.1105 226.3 65.9097 228.672 69.5572Z'
          fill='#0E63D8'
        />
        <path
          d='M204.453 23.5815C203.098 24.0057 192.511 27.3139 186.583 37.4082C178.791 50.8107 180.824 73.2895 194.798 79.9908C208.18 86.4375 226.389 75.5798 232.656 61.3291C238.161 48.69 235.366 30.7917 224.271 24.4298C216.564 20.0189 207.333 22.7333 204.453 23.5815Z'
          fill='#0E63D8'
        />
        <path
          d='M240.7 86.1831C240.7 86.1831 307.946 90.6789 298.376 312.244C265.176 318.606 231.045 317.503 198.353 308.936L180.314 304.525L184.718 174.063C185.31 136.485 206.992 102.555 240.7 86.1831Z'
          fill='#001C44'
        />
        <path
          d='M219.865 59.0388C214.953 54.9672 221.644 45.6363 215.969 38.8503C209.787 31.4704 198.099 37.8323 187.597 31.2159C182.685 28.0774 177.18 21.6306 175.063 6.70124C188.529 -1.35722 196.659 -0.848269 201.572 1.18755C207.754 3.73233 208.008 8.39776 215.546 11.706C225.116 15.8624 229.859 10.6881 237.482 13.9963C246.628 17.8983 254.59 31.8097 250.355 43.9398C245.358 58.3602 225.794 64.0436 219.865 59.0388Z'
          fill='#3BFFBF'
        />
        <path
          d='M223.423 56.0699C223.846 52.5072 227.065 49.8776 230.622 50.3018C234.179 50.7259 236.804 53.9493 236.381 57.512C235.957 61.0746 232.739 63.7042 229.182 63.2801C225.625 62.9408 222.999 59.7174 223.423 56.0699Z'
          fill='#001C44'
        />
        <path d='M187.428 838.589H245.527V842.915H187.428V838.589Z' fill='#001C44' />
        <path
          d='M9.72044 816.036L67.7344 838.165L66.0464 842.604L8.03248 820.475L9.72044 816.036Z'
          fill='#001C44'
        />
        <path
          d='M242.477 95.005C251.455 92.1209 260.432 99.0766 260.178 108.492C260.094 111.716 259.924 114.939 259.67 118.162C254.673 177.541 220.288 222.922 191.661 251.424C178.449 264.572 158.123 267.541 141.777 258.465C131.614 252.781 121.281 246.843 110.779 240.397C81.8142 222.753 59.7093 203.497 36.7574 185.938C38.0278 184.157 36.7574 185.938 38.0278 184.157C40.6533 180.425 40.738 178.05 43.3634 174.402C61.3184 183.224 80.7979 194.166 100.193 202.819C121.959 212.489 144.911 218.003 165.661 226.231C177.094 199.511 185.394 179.492 189.968 168.379C189.968 168.379 209.955 108.916 237.735 96.7863C239.344 96.1077 240.953 95.5139 242.477 95.005Z'
          fill='#0E63D8'
        />
        <path
          d='M187.006 165.326C187.006 165.326 203.522 186.787 242.989 201.292L269.074 121.216L262.214 103.572L248.155 90.679L225.881 97.974C225.881 97.974 193.612 139.284 187.006 165.326Z'
          fill='#001C44'
        />
      </svg>
    </>
  )
}
