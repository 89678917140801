import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSuccess = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3618_11046)'>
        <path
          d='M14.6668 7.39014V8.00348C14.666 9.44108 14.2005 10.8399 13.3397 11.9913C12.479 13.1428 11.269 13.9851 9.89043 14.3927C8.51176 14.8003 7.03836 14.7514 5.68981 14.2532C4.34128 13.755 3.18993 12.8342 2.40747 11.6282C1.62501 10.4222 1.25335 8.99554 1.34795 7.56101C1.44253 6.12654 1.9983 4.76104 2.93235 3.66821C3.86639 2.57537 5.12869 1.81375 6.53095 1.49692C7.93323 1.1801 9.40036 1.32505 10.7135 1.91016M14.6668 2.66634L8.00016 9.33968L6.00016 7.33968'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3618_11046'>
          <rect width='16' height='16' fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}
