import React, { useEffect } from 'react'
import { Header } from './header/Header'
import { Footer } from './footer/Footer'
import { Outlet } from 'react-router-dom'

export const MainLayout = () => {
  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === 'bluepear') {
      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NKXFCZD');
    `
      document.head.appendChild(gtmScript)

      return () => {
        document.head.removeChild(gtmScript)
      }
    }
  }, [])

  return (
    <main className='relative bg-grey'>
      <div className='bg-focus-500 absolute left-0 top-0 h-[540px] md:h-[364px] lg:h-[260px] xl:h-[208px] w-full animate-appear-hand'></div>
      <div className='relative flex flex-col p-[16px] pb-0 xl:pt-0 justify-between min-h-screen relative overflow-hidden container xl:max-w-[1440px] mx-auto z-10'>
        <header className='relative z-20'>
          <Header />
        </header>
        <section className='flex flex-1 mt-[22px] xl:mt-0'>
          <Outlet />
        </section>
        <Footer />
      </div>
      <div className='bg-focus-500 absolute left-0 bottom-0 h-[360px] md:h-[256px] lg:h-[201px] w-full'></div>
    </main>
  )
}
