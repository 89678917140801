import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import {
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetProjectsQuery,
} from '../../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import {
  selectProject,
  selectResearchFilters,
  setProject,
  setResearchFilter,
} from '../../store/ResearchSlice'
import { IResearchFilter } from '../../interfaces/ResearchFilter.interface'
import {
  BpSearchInput,
  IOption,
} from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IDomainParams } from '../../../../../api/filter/interfaces/DomainParams.interface'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { Trans, useTranslation } from 'react-i18next'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { AddProjectButton } from '../../../../../shared/components/buttons/add-project-button/AddProjectButton'
import { EditCountriesButton } from '../../../../../shared/components/buttons/edit-countries-button/EditCountriesButton'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<IResearchFilter>) => {
  const { t } = useTranslation()
  const { domain, country, project } = useAppSelector<IResearchFilter>(selectResearchFilters)
  const { is_demo: isDemo } = useAppSelector(selectProject)
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IResearchFilter>({
    mode: 'onChange',
    defaultValues: {
      domain,
      country,
      project,
      ...searchParams,
    },
  })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project,
      search: domain,
      seo: false,
    } as IDomainParams,
    { skip: !project },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setResearchFilter(getValues()))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <section>
      <form className='flex flex-col lg:items-center max-w-full gap-y-[12px] lg:flex-row lg:flex-wrap lg:gap-x-[8px] xl:flex-nowrap'>
        <div className='flex-1 xl:grow'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue =
                defaultValues?.project ??
                project ??
                projects?.find((option) => !option.is_demo)?.value ??
                projects?.[0]?.value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  bottomSection={<AddProjectButton />}
                  onChange={(e) => {
                    const option = projects?.find((option) => option.value === e.target.value)

                    if (option) {
                      dispatch(setProject(option))
                    }

                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', [])
                      setValue('domain', '')
                    }
                    applyFilter()
                  }}
                />
              )
            }}
          />
        </div>
        <div className='flex-1 xl:flex-grow-[2]'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSelect
                {...fieldProps}
                options={countries}
                Icon={IconCountry}
                fieldSize='lg'
                title={t('fields.country')}
                multiple
                loading={countriesLoading || countriesFetching}
                disabled={countriesLoading || countriesFetching}
                defaultValue={defaultValues?.country as string[]}
                showSearch
                bottomSection={<EditCountriesButton project={{ value: project, isDemo }} />}
                onChange={(e) => {
                  onChange([].slice.call(e.target.selectedOptions).map((o: IOption) => o.value))
                  applyFilter()
                }}
              />
            )}
          />
        </div>
        <div className='flex-1 xl:grow'>
          <Controller
            name='domain'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSearchInput
                  {...fieldProps}
                  fieldSize='lg'
                  placeholder={t('fields.domain')}
                  options={domains}
                  loading={domainsLoading || domainsFetching}
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                />
              )
            }}
          />
        </div>
      </form>
      {isDemo && (
        <div
          className={
            'w-full items-center flex gap-[8px] bg-[#FFF6A3] rounded-[12px] px-[16px] py-[8px] mt-[20px]'
          }
        >
          <svg
            className={'min-w-fit'}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5.67678 1.76256C6.00497 1.43437 6.45009 1.25 6.91421 1.25H13.0858C13.5499 1.25 13.995 1.43437 14.3232 1.76256L18.2374 5.67678C18.5656 6.00497 18.75 6.45009 18.75 6.91421V13.0858C18.75 13.5499 18.5656 13.995 18.2374 14.3232L14.3232 18.2374C13.995 18.5656 13.5499 18.75 13.0858 18.75H6.91421C6.45009 18.75 6.00497 18.5656 5.67678 18.2374L1.76256 14.3232C1.43437 13.995 1.25 13.5499 1.25 13.0858V6.91421C1.25 6.45009 1.43437 6.00497 1.76256 5.67678L5.67678 1.76256ZM6.91421 2.75C6.84791 2.75 6.78432 2.77634 6.73744 2.82322L2.82322 6.73744C2.77634 6.78432 2.75 6.84791 2.75 6.91421V13.0858C2.75 13.1521 2.77634 13.2157 2.82322 13.2626L6.73744 17.1768C6.78432 17.2237 6.84791 17.25 6.91421 17.25H13.0858C13.1521 17.25 13.2157 17.2237 13.2626 17.1768L17.1768 13.2626C17.2237 13.2157 17.25 13.1521 17.25 13.0858V6.91421C17.25 6.84791 17.2237 6.78432 17.1768 6.73744L13.2626 2.82322C13.2157 2.77634 13.1521 2.75 13.0858 2.75H6.91421ZM10 8.25C10.4142 8.25 10.75 8.58579 10.75 9V13C10.75 13.4142 10.4142 13.75 10 13.75C9.58579 13.75 9.25 13.4142 9.25 13V9C9.25 8.58579 9.58579 8.25 10 8.25ZM10 7.5C10.4142 7.5 10.75 7.16421 10.75 6.75C10.75 6.33579 10.4142 6 10 6C9.58579 6 9.25 6.33579 9.25 6.75C9.25 7.16421 9.58579 7.5 10 7.5Z'
              fill='#00326D'
            />
          </svg>
          <p className={'text-[15px] text-focus-500'}>
            <Trans i18nKey='other.demoProjectSelected'>
              <NavLink className={'text-focus'} to='/projects' />
            </Trans>
          </p>
        </div>
      )}
    </section>
  )
}

export const ResearchToolsFilters = withSearchParams<
  IWithSearchParamsProps<IResearchFilter>,
  IResearchFilter
>(Filter, selectResearchFilters)
