import { Outlet, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from './store/hooks/Redux'
import {
  hideAlert,
  hideModal,
  selectAlerts,
  selectModal,
  setLocation,
} from './store/reducers/AppSlice'
import { BpAlert } from './shared/components/cards/alert/BpAlert'
import { BpModal } from './shared/components/cards/modal/BpModal'
import { useEffect, useState } from 'react'
import { useOutsideClick } from './shared/hooks/UseOutsideClick'
import Highcharts from 'highcharts'

function App() {
  const dispatch = useAppDispatch()
  const alerts = useAppSelector(selectAlerts)
  const modal = useAppSelector(selectModal)
  const location = useLocation()
  const body = document.querySelector('body')
  const [isShowAlerts, setIsShowAlerts] = useState<boolean>(true)
  const clickRef = useOutsideClick(() => {
    if (alerts.length) setIsShowAlerts(false)
  })

  useEffect(() => {
    if (body && modal) {
      body.style.overflow = 'hidden'
    } else if (body) {
      body.style.overflow = 'auto'
    }
  }, [modal])

  useEffect(() => {
    dispatch(setLocation(location))
  }, [location])

  Highcharts.setOptions({
    time: {
      timezoneOffset: 3 * 60,
      useUTC: true,
    },
  })

  return (
    <>
      {alerts && (
        <div
          ref={clickRef}
          className='fixed right-0 top-0 md:right-5 md:top-5 z-50 max-w-full md:max-w-[320px] flex flex-col gap-[10px]'
        >
          {alerts.map((alert, index: number) => (
            <BpAlert
              {...alert}
              key={index}
              show={isShowAlerts}
              onAfterClose={() => {
                dispatch(hideAlert(alert.message))
                setIsShowAlerts(true)
              }}
            />
          ))}
        </div>
      )}
      {modal?.children && (
        <BpModal
          id={modal.id}
          size={modal.size}
          width={modal.width}
          scroll={modal.scroll}
          onClose={() => dispatch(hideModal())}
        >
          {modal.children}
        </BpModal>
      )}
      <Outlet />
    </>
  )
}

export default App
