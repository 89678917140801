import { AdOverviewFilters } from './filters/AdOverviewFilters'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectAdOverviewActiveTab,
  selectProject,
  setAdOverviewActiveTab,
  TAdOverviewTabs,
} from '../store/AdOverviewSlice'
import { AdsReportTable } from './ads-report/AdsReportTable'
import { AffiliatesReportTable } from './affiliates-report/AffiliatesReportTable'
import { AdsControlPanel } from './ads-report/AdsControlPanel'
import { AffControlPanel } from './affiliates-report/AffControlPanel'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { selectPreviousLocation } from '../../../../store/reducers/AppSlice'
import { ProjectStub } from '../../../../shared/components/utility/project-stub/ProjectStub'

export const AdOverviewPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const previousLocation = useAppSelector(selectPreviousLocation)
  const activeTab = useAppSelector(selectAdOverviewActiveTab)
  const { has_paid_links: hasPaidLinks } = useAppSelector(selectProject)

  return (
    <>
      <AdOverviewFilters />
      {hasPaidLinks ? (
        <BpTabs
          className='mt-[19px]'
          active={activeTab}
          onToggle={(e) => dispatch(setAdOverviewActiveTab(e as TAdOverviewTabs))}
          back={() => {
            navigate(
              previousLocation
                ? previousLocation.pathname + previousLocation.search
                : '/ad-reports',
            )
          }}
          size={'md'}
        >
          <BpTab title={t('pages.adOverview.ads')} panel={<AdsControlPanel />}>
            <section className='h-full min-h-[550px] lg:min-h-[450px] grid gap-[50px] grid-rows-[1fr]'>
              <AdsReportTable />
            </section>
          </BpTab>
          <BpTab title={t('pages.adOverview.affiliates')} panel={<AffControlPanel />}>
            <section className='h-full min-h-[550px] lg:min-h-[450px] grid gap-[50px] grid-rows-[1fr]'>
              <AffiliatesReportTable />
            </section>
          </BpTab>
        </BpTabs>
      ) : (
        <div className={'pt-[50px]'}>
          <ProjectStub />
        </div>
      )}
    </>
  )
}
