import { PropsWithChildren } from 'react'
import { useAppSelector } from '../store/hooks/Redux'
import { selectSubRole } from '../store/reducers/AuthSlice'

interface ViewGuardProps extends PropsWithChildren {
  roles: ('admin' | 'editor' | 'user')[]
}

export const ViewGuard = ({ children, roles }: ViewGuardProps) => {
  const role = useAppSelector(selectSubRole)

  return roles.includes(role) ? <>{children}</> : null
}
