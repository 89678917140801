import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { useExportAdsMutation } from '../../../../../api/ad-overview/AdOverview.api'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import {
  selectAdOverviewAdsExpanded,
  selectAdOverviewAdsParams,
  selectAdOverviewFilters,
  selectAllowDecloak,
  setAdOverviewFilter,
  toggleAdsExpand,
} from '../../store/AdOverviewSlice'
import { IconMinimize } from '../../../../../shared/components/icons/IconMinimize'
import { IconMaximize } from '../../../../../shared/components/icons/IconMaximize'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { Switch } from 'antd'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { IAdOverviewFilter } from '../../interfaces/AdOverviewFilter.interface'
import { useTranslation } from 'react-i18next'

export const AdsControlPanel = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const queryParams = useAppSelector(selectAdOverviewAdsParams)
  const filters = useAppSelector<IAdOverviewFilter>(selectAdOverviewFilters)
  const isExpandAll = useAppSelector(selectAdOverviewAdsExpanded)
  const allowDecloak = useAppSelector(selectAllowDecloak)
  const [exportFile, { isLoading }] = useExportAdsMutation()

  useEffect(() => {
    dispatch(setAdOverviewFilter({ ...filters, is_decloak: false }))
  }, [filters.project])

  return (
    <span className='flex gap-[9px]'>
      {allowDecloak && (
        <div className='flex items-center gap-[8px] mr-2'>
          <Switch
            checked={filters.is_decloak}
            onClick={(e) => {
              dispatch(setAdOverviewFilter({ ...filters, is_decloak: e }))
            }}
          />
          <span className={'whitespace-nowrap'}>{t('fields.uncloakedAds')}</span>
          <BpTooltip title={t('pages.adOverview.uncloakedAdsTooltip')} />
        </div>
      )}
      <BpButton
        size='sm'
        theme='outline'
        type='button'
        onClick={() => dispatch(toggleAdsExpand())}
        iconLeft={isExpandAll ? <IconMinimize /> : <IconMaximize />}
      ></BpButton>
      <ExportDrop
        onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
        loading={isLoading}
        columns={[
          { label: t('table.columns.ad'), keys: ['title', 'text', 'finish_domain'] },
          { label: t('table.columns.keyword(s)'), keys: ['keywords'] },
          { label: t('table.columns.redirectLinks'), keys: ['redirects'] },
          { label: t('table.columns.position'), keys: ['position_global'] },
          {
            label: t('table.columns.details'),
            keys: [
              'id',
              'search_screen_url',
              'link_screen_url',
              'countries',
              'cities',
              'engines',
              'devices',
            ],
          },
          { label: t('table.columns.lastFound'), keys: ['last_found'] },
        ]}
      />
    </span>
  )
}
