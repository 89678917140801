import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { hideModal } from '../../../../store/reducers/AppSlice'
import React from 'react'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { useTranslation } from 'react-i18next'

export const UnsavedChangesModal = ({ save, leave }: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  return (
    <div className={'px-[16px] py-[40px] text-focus-500'}>
      <p className={'text-center text-[24px] font-medium'}>{t('pages.project.unsavedChanges')}</p>
      <p className={'text-center text-[15px] pt-[8px] pb-[32px]'}>
        {t('pages.project.haveUnsavedChanges')}
      </p>
      <div className={'flex gap-[12px] justify-center'}>
        <BpButton
          onClick={() => {
            dispatch(hideModal())
            save && save()
          }}
          className={'uppercase'}
        >
          {t('buttons.saveChanges')}
        </BpButton>
        <BpButton
          onClick={() => {
            dispatch(hideModal())
            leave && leave()
          }}
          className={'uppercase'}
          theme={'outline'}
        >
          {t('buttons.leaveAnyway')}
        </BpButton>
      </div>
    </div>
  )
}
