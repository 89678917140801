import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { useForm } from 'react-hook-form'
import { BpLink } from '../../../../shared/components/text/link/BpLink'
import { FormHTMLAttributes } from 'react'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { useTranslation } from 'react-i18next'
import { GoogleButton } from '../../utility/GoogleButton'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'

export interface ILoginFormData {
  email: string
  password: string
}

export interface ILoginFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ILoginFormData) => void
  isFetching?: boolean
}

export const LoginForm = ({ submitHandler, isFetching = false, ...formProps }: ILoginFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ILoginFormData>({
    mode: 'onChange',
  })

  return (
    <>
      <h2 className='mb-[24px] leading-[32px]'>{t('pages.auth.logIn')}</h2>
      {
        {
          bluepear: (
            <>
              <GoogleButton />
              <div className={'h-[1px] bg-focus/[.15] my-[16px] flex items-center justify-center'}>
                <div className={'bg-white px-[3px] text-aqua-300'}>or</div>
              </div>
            </>
          ),
          robuscan: null,
          default: null,
        }[process.env.REACT_APP_PROJECT ?? 'default']
      }
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ILoginFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.email')}
          error={errors.email?.message}
          required
          {...register('email', {
            required: t('fields.validate.emailRequired'),
            pattern: {
              value: EMAIL_REGEX,
              message: t('fields.validate.enterValidEmail'),
            },
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.password')}
          type='password'
          required
          error={errors.password?.message}
          {...register('password', { required: t('fields.validate.required') })}
        ></BpInput>
        <div className='flex justify-between gap-[16px] items-center'>
          {/* TODO refactoring <BpCheckbox {...register('remember')} label='Remember me'></BpCheckbox> */}
          <span className='flex gap-[16px] text-[14px]'>
            <BpLink to='/auth/reset-password'>{t('pages.auth.resetPassword')}</BpLink>
            {
              {
                bluepear: (
                  <BpAnchor href='mailto:sales@bluepear.co'>
                    {t('pages.auth.contactSupport')}
                  </BpAnchor>
                ),
                robuscan: null,
                default: null,
              }[process.env.REACT_APP_PROJECT ?? 'default']
            }
          </span>
        </div>
        <div className='mt-[16px] flex flex-col'>
          <BpButton
            className={'uppercase'}
            type='submit'
            disabled={!isValid || isFetching}
            size='lg'
          >
            {t('pages.auth.buttons.login')}
          </BpButton>
        </div>
        <div>
          <p>
            {t('pages.auth.dontHaveAccount')}{' '}
            <BpLink to={'/auth/signup'}>{t('pages.auth.signUp')}</BpLink>
          </p>
        </div>
      </form>
    </>
  )
}
