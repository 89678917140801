import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { ISerpPresenceFilter } from '../interfaces/SerpPresenceFilter'
import { RootState } from '../../../../store/Store'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

type SerpPresenceState = {
  filters: ISerpPresenceFilter
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
}

const dateRanges = useDateRanges()

const serpPresenceSlice = createSlice({
  name: 'serpPresence',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      no_serp_presence: false,
      is_seo: false,
    },
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
  } as SerpPresenceState,
  reducers: {
    setSerpPresenceFilter: (
      state: SerpPresenceState,
      action: PayloadAction<ISerpPresenceFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{
          ...action.payload,
          no_serp_presence: state.filters.no_serp_presence,
          is_seo: state.filters.is_seo,
        },
      }
    },
    setSerpPresence: (state: SerpPresenceState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, no_serp_presence: action.payload }
    },
    setIsSeo: (state: SerpPresenceState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, is_seo: action.payload }
    },
    setProject: (
      state: SerpPresenceState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
  },
})

export const { setSerpPresenceFilter, setSerpPresence, setIsSeo, setProject } =
  serpPresenceSlice.actions

export const selectSerpPresenceFilters = (state: RootState) => state.root.serpPresence.filters
export const selectProject = (state: RootState) => state.root.serpPresence.project

export default serpPresenceSlice.reducer
