import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import { BpLink } from '../../../../shared/components/text/link/BpLink'
import { FormHTMLAttributes } from 'react'
import { GoogleButton } from '../../utility/GoogleButton'
import { useTranslation } from 'react-i18next'

export interface ISignUpFormData {
  scenario?: string
  fullName?: string
  email?: string
  password?: string
  notifyNews?: boolean
}

export interface ISignUpFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ISignUpFormData) => void
  isFetching?: boolean
}

export const SignUpForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ISignUpFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ISignUpFormData>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      notifyNews: true,
    },
  })

  const validatePassword = (value: string | undefined) => {
    if (!value) return t('fields.validate.passwordRequired') // Обрабатываем пустое значение

    const hasLowerCase = /[a-z]/.test(value)
    const hasUpperCase = /[A-Z]/.test(value)
    const hasNumber = /\d/.test(value)
    const hasSpecialChar = /[\W_]/.test(value)

    const missingConditions = []

    if (!hasNumber) missingConditions.push(t('fields.validate.oneNumber'))
    if (!hasUpperCase) missingConditions.push(t('fields.validate.oneUpperCaseLetter'))
    if (!hasLowerCase) missingConditions.push(t('fields.validate.oneLowerCaseLetter'))
    if (!hasSpecialChar) missingConditions.push(t('fields.validate.oneSpecialCharacter'))

    if (missingConditions.length === 0) return undefined

    return `${t('fields.validate.passwordMustContain')} ${missingConditions.join(', ')}`
  }

  return (
    <>
      <h2 className='mb-[24px] leading-[32px]'>
        {t('pages.auth.createAccountAndStartYour')}{' '}
        <span className={'text-default font-medium'}>{t('pages.auth.freeTrial')}</span>
      </h2>
      {
        {
          bluepear: (
            <>
              <GoogleButton />
              <div className={'h-[1px] bg-focus/[.15] my-[16px] flex items-center justify-center'}>
                <div className={'bg-white px-[3px] text-aqua-300'}>or</div>
              </div>
            </>
          ),
          robuscan: null,
          default: null,
        }[process.env.REACT_APP_PROJECT ?? 'default']
      }
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ISignUpFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.fullName')}
          required
          error={errors.fullName?.message}
          {...register('fullName', {
            required: t('fields.validate.fullNameRequired'),
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.workEmail')}
          required
          error={errors.email?.message}
          {...register('email', {
            required: t('fields.validate.workEmailRequired'),
            pattern: {
              value: EMAIL_REGEX,
              message: t('fields.validate.enterValidEmail'),
            },
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.password')}
          type='password'
          required
          error={errors.password?.message}
          {...register('password', {
            required: t('fields.validate.passwordRequired'),
            minLength: {
              value: 8,
              message: t('fields.validate.passwordMustBe'),
            },
            validate: validatePassword,
          })}
        ></BpInput>
        <div className={'pt-[24px]'}>
          <BpCheckbox label={t('pages.auth.receiveNews')} {...register('notifyNews')} />
        </div>
        <BpButton className={'uppercase w-full'} type='submit' disabled={!isValid} size='lg'>
          {t('pages.auth.buttons.continue')}
        </BpButton>
        <div>
          <p>
            {t('pages.auth.youAgreeTo')}{' '}
            <a className={'underline'} href={'https://bluepear.net/privacy-policy'}>
              {t('pages.auth.privacyPolicy')}
            </a>
          </p>
          <p>
            {t('pages.auth.alreadyHaveAccount')}{' '}
            <BpLink to={'/auth'}>{t('pages.auth.logIn')}</BpLink>
          </p>
        </div>
      </form>
    </>
  )
}
