import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IAdvertiserReportFilter } from '../interfaces/IAdvertiserReportFilter'
import { RootState } from '../../../../store/Store'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

type AdvertiserReportState = {
  filters: IAdvertiserReportFilter
  pagination: IPagination
  allowDecloak: boolean
  tabCount?: { [key: string]: string }
  project: IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
  isExpandAll: boolean
}

const dateRanges = useDateRanges()

const advertiserReportSlice = createSlice({
  name: 'research',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      show_hidden: false,
      is_decloak: false,
      policy: '',
      status: '',
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
    project: {
      is_demo: false,
      has_paid_links: true,
      has_seo_links: true,
    },
    allowDecloak: false,
    isExpandAll: false,
  } as AdvertiserReportState,
  reducers: {
    setAdvertiserReportFilter: (
      state: AdvertiserReportState,
      action: PayloadAction<IAdvertiserReportFilter>,
    ) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setAdvertiserReportPagination: (
      state: AdvertiserReportState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pagination = action.payload
    },
    setAllowDecloak: (state: AdvertiserReportState, action: PayloadAction<boolean>) => {
      state.allowDecloak = action.payload
    },
    setTabCount: (
      state: AdvertiserReportState,
      action: PayloadAction<{ [key: string]: string }>,
    ) => {
      state.tabCount = action.payload
    },
    setProject: (
      state: AdvertiserReportState,
      action: PayloadAction<
        IOption & { is_demo: boolean; has_paid_links: boolean; has_seo_links: boolean }
      >,
    ) => {
      state.project = action.payload
    },
    toggleExpand: (state: AdvertiserReportState) => {
      state.isExpandAll = !state.isExpandAll
    },
  },
})

export const {
  setAdvertiserReportFilter,
  setAdvertiserReportPagination,
  setAllowDecloak,
  setTabCount,
  setProject,
  toggleExpand,
} = advertiserReportSlice.actions

export const selectAdvertiserReportFilters = (state: RootState) =>
  state.root.advertiserReport.filters
export const selectAdvertiserReportPagination = (state: RootState) =>
  state.root.advertiserReport.pagination
export const selectAdvertiserReportTabCount = (state: RootState) =>
  state.root.advertiserReport.tabCount
export const selectAllowDecloak = (state: RootState) => state.root.advertiserReport.allowDecloak
export const selectProject = (state: RootState) => state.root.advertiserReport.project
export const selectIsExpandAll = (state: RootState) => state.root.advertiserReport.isExpandAll

export default advertiserReportSlice.reducer
